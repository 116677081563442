import { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import { GoogleMap, InfoWindow, LoadScript, Marker, Polyline } from '@react-google-maps/api';
import wazeIcon from 'assets/images/iconWaze.png';
import { Dialog } from 'components';
import { googleService } from 'services/google';

export const DialogRoutes = ({ open, onClose, table }) => {
  if (!open) return null;

  const [coordinates, setCoordinates] = useState([]);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [markers, setMarkers] = useState([]); // Estado para armazenar marcadores
  const [distances, setDistances] = useState([]); // Estado para armazenar distâncias
  const [loading, setLoading] = useState(true);
  const origin = {
    lat: -23.457283,
    lng: -47.405887,
    address: 'Avenida da Liberdade, 4055, Eden, Sorocaba',
  };
  const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  useEffect(() => {
    if (open && table.length > 0) {
      getCoordinatesFromAddress();
    }
    // eslint-disable-next-line
  }, [open, table]);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // eslint-disable-next-line
  const getCoordinatesFromAddress = async () => {
    setLoading(true);
    const newCoordinates = [];

    for (let i = 0; i < table.length; i++) {
      const address =
        table[i].street + ' ' + table[i].number + ', ' + table[i].city + ', ' + table[i].state;
      const protocol = table[i].protocol;
      const url = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
        address,
      )}&format=json`;
      await delay(2000);

      try {
        const response = await fetch(url);
        const data = await response.json();
        if (data && data.length > 0) {
          const { lat, lon } = data[0];
          console.log('Coordenadas:', lat, lon);
          newCoordinates.push({ lat: parseFloat(lat), lng: parseFloat(lon), address, protocol });
        } else {
          console.error('Não foi possível obter as coordenadas.');
          newCoordinates.push({ lat: null, lng: null, address, protocol }); // Adiciona coordenadas nulas se não obtiver sucesso
        }
      } catch (error) {
        console.error('Erro na requisição Nominatim:', error);
        newCoordinates.push({ lat: null, lng: null, address, protocol }); // Trata erro na requisição
      }
    }
    setCoordinates(newCoordinates);
    setMarkers([origin, ...newCoordinates]); // Atualiza os marcadores
    calculateAndDrawRoute(newCoordinates);
    setLoading(false);
  };

  const getDistances = async (origin, destinations) => {
    try {
      const { data } = await googleService.getDistances({
        origin: origin,
        destinations: destinations,
        key: key,
      });

      console.log('data', data);

      if (data.status === 'OK') {
        const distances = data.rows[0].elements.map((element, index) => ({
          distance: element.distance.value,
          duration: element.duration.value,
          destination: destinations[index],
        }));

        return distances;
      } else {
        console.error('Erro na API de Distância do Google:', data.status);
        return [];
      }
    } catch (error) {
      console.error('Erro ao calcular distâncias:', error?.message);
      return [];
    }
  };

  const getRoute = async (origin, waypoints) => {
    const waypointsString = waypoints.map((wp) => `${wp.lat},${wp.lng}`).join('|');

    try {
      const { data } = await googleService.getDirectionWithWayPoints({
        origin,
        waypoints,
        waypointsString,
        key,
      });
      console.log('status', data);
      if (data.status === 'OK') {
        return data.routes[0]; // Retorna a melhor rota
      } else {
        console.error('Erro na API de Direções do Google:', data.status);
        return null;
      }
    } catch (error) {
      console.error('Erro ao traçar rota:', error);
      return null;
    }
  };

  const sortDestinationsByDistance = (distances) => {
    return distances.sort((a, b) => a.distance - b.distance);
  };
  const calculateDistance = (point1, point2) => {
    const R = 6371e3; // Raio da Terra em metros
    const lat1 = (point1.lat * Math.PI) / 180;
    const lat2 = (point2.lat * Math.PI) / 180;
    const deltaLat = ((point2.lat - point1.lat) * Math.PI) / 180;
    const deltaLng = ((point2.lng - point1.lng) * Math.PI) / 180;

    const a =
      Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Retorna a distância em metros
  };

  const calculateAndDrawRoute = async (coordinates) => {
    const distances = await getDistances(origin, coordinates);
    console.log(distances);
    setDistances(distances); // Armazena as distâncias

    // Ordena as distâncias e mantém a origem como primeiro ponto
    const sortedDestinations = sortDestinationsByDistance(distances);
    const orderedCoordinates = [origin, ...sortedDestinations.map((d) => d.destination)];
    console.log(orderedCoordinates);

    // Atualiza as coordenadas para a polilinha
    setCoordinates(orderedCoordinates);

    const route = await getRoute(origin, orderedCoordinates.slice(1)); // Exclui a origem para a rota
    console.log('route', route);

    if (route) {
      console.log('Rota válida:', route);
      setDirectionsResponse({ routes: [route] });
    } else {
      console.error('Nenhuma rota encontrada');
      setDirectionsResponse(null);
    }
  };
  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const openWazeWithWaypoints = async (markers) => {
    if (markers.length < 2) {
      console.error('É necessário ao menos um ponto de origem e um destino.');
      return;
    }

    // Verifica se a geolocalização é suportada
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          // Calcula o ponto mais próximo
          const destinations = markers.slice(1);
          const distancesFromCurrentLocation = destinations.map((marker) => {
            return {
              marker,
              distance: calculateDistance(currentLocation, marker), // Função para calcular distância
            };
          });

          // Encontra o próximo destino com base na menor distância
          const nextDestination = distancesFromCurrentLocation.reduce((prev, curr) => {
            return prev.distance < curr.distance ? prev : curr;
          }).marker;

          // Monta a URL do Waze
          const wazeUrl = `https://waze.com/ul?ll=${nextDestination.lat},${nextDestination.lng}&navigate=yes&from=
            ${currentLocation.lat},${currentLocation.lng}`;

          // Abre o Waze com a URL
          window.open(wazeUrl, '_blank');
        },
        (error) => {
          console.error('Erro ao obter localização:', error);
        },
      );
    } else {
      console.error('Geolocalização não é suportada neste navegador.');
    }
  };

  return (
    <>
      <Dialog open={open}>
        <Dialog.Title onClose={onClose}>Melhor rota do dia</Dialog.Title>
        <Dialog.Content>
          <Box display='flex' justifyContent='center' flexDirection='column'>
            {table.length === 0 && <h4>Não há rotas para exibir</h4>}

            {loading && table.length > 0 ? (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                height='400px'
                flexDirection='column'
              >
                <CircularProgress size={60} />
                <Typography variant='h6' style={{ marginTop: '16px', color: '#512da8' }}>
                  Carregando mapa e rotas, por favor aguarde...
                </Typography>
              </Box>
            ) : (
              directionsResponse && (
                <LoadScript googleMapsApiKey={key}>
                  <GoogleMap
                    center={{ lat: origin.lat, lng: origin.lng }}
                    zoom={10}
                    mapContainerStyle={{ height: '600px', width: '100%' }}
                  >
                    {/* Marcadores e polilinhas */}
                    <Marker position={origin} label='Origem' />
                    {markers.map(
                      (marker, index) =>
                        marker.lat &&
                        marker.lng &&
                        index > 0 && ( // Verifica se o índice é maior que 0
                          <Marker
                            key={index}
                            position={marker}
                            label={`Ponto ${index}`} // Rótulo para os pontos, usando index para incrementar
                          />
                        ),
                    )}
                    <Polyline
                      path={coordinates}
                      options={{
                        strokeColor: '#512da8',
                        strokeOpacity: 1.0,
                        strokeWeight: 6,
                      }}
                    />
                    {distances.map((dist, index) => {
                      if (index < markers.length - 1) {
                        const midPoint = {
                          lat: (markers[index].lat + markers[index + 1].lat) / 2,
                          lng: (markers[index].lng + markers[index + 1].lng) / 2,
                        };
                        return (
                          <InfoWindow key={index} position={midPoint}>
                            <div>
                              {`${(dist.distance / 1000).toFixed(2)} km`}
                              <br />
                              {index === 0
                                ? `Entre: ${origin.address} e ${markers[index + 1].address}`
                                : `Entre: ${markers[index].address} e ${
                                    markers[index + 1].address
                                  }`}
                            </div>
                          </InfoWindow>
                        );
                      }
                      return null;
                    })}
                  </GoogleMap>
                </LoadScript>
              )
            )}
            <Box display='flex' justifyContent='end' mt={2} alignItems='center'>
              <IconButton
                variant='contained'
                color='primary' // Altere para a cor desejada
                onClick={() => openWazeWithWaypoints(markers)}
                disabled={coordinates.length === 0}
                sx={{
                  position: 'absolute',
                }}
              >
                <img src={wazeIcon} alt='Icone do Waze' width='50%' />
              </IconButton>
            </Box>

            <Box mt={2} p={2} border={1} borderColor='grey.300' borderRadius={4} bgcolor='grey.100'>
              {distances.map((dist, index) => (
                <>
                  <Typography>
                    {index === 0
                      ? `Viagem para o protocolo ${markers[index + 1]?.protocol}`
                      : `Viagem para o protocolo ${markers[index + 1]?.protocol}`}
                  </Typography>
                  <Typography
                    key={index}
                    variant='body1'
                    gutterBottom
                    style={{
                      padding: '8px 0',
                      borderBottom: index < distances.length - 1 ? '1px solid #ccc' : 'none',
                    }}
                  >
                    <strong>{index + 1}-</strong>{' '}
                    {index === 0 ? (
                      <>
                        <strong>Origem:</strong> {origin.address}{' '}
                        <span style={{ color: '#512da8', fontWeight: 'bold' }}>{'----->'}</span>{' '}
                        <strong>Ponto {index + 1}:</strong> {markers[index + 1].address}
                      </>
                    ) : (
                      <>
                        <strong>Ponto {index}:</strong> {markers[index].address}{' '}
                        <span style={{ color: '#512da8', fontWeight: 'bold' }}>{'----->'}</span>{' '}
                        <strong>Ponto {index + 1}:</strong> {markers[index + 1].address}
                      </>
                    )}
                  </Typography>
                </>
              ))}
            </Box>
          </Box>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
