import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import { Button, Select, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useQuery } from 'hooks/useQuery';
import { useContextSelector } from 'use-context-selector';
import * as yup from 'yup';

import { registerService } from '../../../../../../services/register';

const validationSchema = yup.object().shape({
  partnumber: yup.string().required('Partnumber é obrigatório'),
  partnumber_description: yup.string().required('Descrição é obrigatória'),
  ncm: yup.string().required('NCM é obrigatório'),
  valor_ref: yup
    .number()
    .typeError('Valor Ref deve ser numérico')
    .required('Valor Ref é obrigatório'),
  parts_nivel: yup.string().required('Nivel da Peça é obrigatório'),
});

export const InitalEdit = ({ line, handleCloseDialogEdit }) => {
  const user = useContextSelector(AuthContext, (context) => context.user);

  const [isLoading, setIsLoading] = useState(false);

  const partsNivel = [
    { label: 1, value: 0 },
    { label: 2, value: 1 },
    { label: 3, value: 2 },
  ];

  const [model, , ,] = useQuery(() => registerService.findModels(), []);

  const optionModel = (model || [])?.map((a) => ({
    value: a.id,
    label: a.descricao,
    ativo: a.ativo,
  }));

  const initialNivelPart = partsNivel.find((item) => item.value === line.parts_nivel);

  const updateParts = async (values) => {
    setIsLoading(true);
    toast.info('Alterando peça...');
    try {
      const { data } = await registerService.updateParts({
        partnumber: values.partnumber,
        descricao: values.partnumber_description,
        ncm: values.ncm,
        valor_ref: values.valor_ref,
        nivel_parts: values.parts_nivel,
        user: user.id,
        id: line.id,
        model: values.model,
      });
      toast.success('Peça alterada com sucesso!');
      setIsLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };
  /* 

ACHAR UMA FORMA DE RECARREGAR A TABELA

*/
  return (
    <>
      <Formik
        initialValues={{
          partnumber: line?.partnumber,
          partnumber_description: line?.description,
          ncm: line?.ncm,
          valor_ref: line?.valor_ref,
          parts_nivel: initialNivelPart?.value,
          model: '',
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={updateParts}
      >
        {(props) => (
          <Form>
            <Box component={Paper} boxShadow={2} p={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={3} lg={4}>
                  <Field
                    size='small'
                    name='partnumber'
                    variant='outlined'
                    fullWidth
                    label='Partnumber'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={4}>
                  <Field
                    size='small'
                    name='partnumber_description'
                    variant='outlined'
                    fullWidth
                    label='Descrição Partnumber'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={4}>
                  <Field
                    size='small'
                    name='ncm'
                    variant='outlined'
                    fullWidth
                    label='NCM'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <Field
                    size='small'
                    name='valor_ref'
                    variant='outlined'
                    fullWidth
                    label='Valor Ref'
                    component={Text}
                  />
                </Grid>

                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <Field
                    size='small'
                    name='parts_nivel'
                    variant='outlined'
                    fullWidth
                    options={partsNivel}
                    label='Nivel da Peça'
                    component={Select}
                  />
                </Grid>
                {!line?.model && (
                  <>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <Field
                        size='small'
                        name='model'
                        variant='outlined'
                        fullWidth
                        options={optionModel}
                        label='Modelo'
                        component={Select}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <Button fullWidth type='submit' loading={isLoading}>
                    Atualizar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
