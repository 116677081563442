import { useCallback, useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog } from 'components';
import { formatDateDirect } from 'helpers/formateDate';
import { inHomeService } from 'services';

export const DialogViewInfo = ({ open, onClose, selectedEvent }) => {
  if (!open) return null;

  const [info, setInfo] = useState();
  const [infoAdc, setInfoAdc] = useState();

  const fetchData = useCallback(async () => {
    const { data } = await inHomeService.findServices();
    const { data: infoAdc } = await inHomeService.findInfoAdcByIdService({
      service_id: selectedEvent.id,
    });
    setInfo(data);
    setInfoAdc(infoAdc);
  }, [selectedEvent.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const infoEvent = info?.filter((item) => item.id == selectedEvent.id) || [];

  // Verificar se infoEvent[0] existe
  const eventDetails = infoEvent[0];

  console.log(infoAdc);
  console.log('selectedEvent', selectedEvent);

  return (
    <Dialog maxWidth='md' open={open}>
      <Dialog.Title onClose={onClose}>Informações Gerais</Dialog.Title>
      <Dialog.Content>
        <Box component={Paper} boxShadow={3} p={3} borderRadius={8}>
          <Grid container spacing={3}>
            {eventDetails ? (
              <>
                <Grid item xs={6}>
                  <Typography variant='h5' gutterBottom>
                    Protocolo: <strong>{eventDetails.protocol || ''}</strong>
                  </Typography>
                </Grid>
                {selectedEvent?._def?.extendedProps?.id_backup ? (
                  <>
                    <Grid item xs={6}>
                      <Typography variant='h5' gutterBottom>
                        Em processo de <strong>Backup</strong>
                      </Typography>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={6}></Grid>
                  </>
                )}
                <Grid item xs={6}>
                  <Typography variant='body1' color='textSecondary'>
                    Data de agendamento:
                  </Typography>
                  <Typography variant='h6'>
                    {formatDateDirect(eventDetails.date_Scheduling)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='body1' color='textSecondary'>
                    Período:
                  </Typography>
                  <Typography variant='h6'>
                    {selectedEvent?._def?.extendedProps?.period || 'Não definido'}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='body1' color='textSecondary'>
                    Técnico Atribuído:
                  </Typography>
                  <Typography variant='h6'>{eventDetails.tecnico || 'Não definido'}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='body1' color='textSecondary'>
                    Cliente:
                  </Typography>
                  <Typography variant='h6'>{eventDetails.cliente || 'Não definido'}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body1' color='textSecondary'>
                    Endereço:
                  </Typography>
                  <Typography variant='h6'>
                    {infoAdc?.[0]?.street +
                      ', ' +
                      infoAdc?.[0]?.number +
                      ', ' +
                      infoAdc?.[0]?.neighborhood +
                      ', ' +
                      infoAdc?.[0]?.city || 'Não definido'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body1' color='textSecondary'>
                    Descrição do serviço:
                  </Typography>
                  <Typography variant='body1'>
                    {eventDetails.comment || 'Nenhuma descrição fornecida.'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body1' color='textSecondary'>
                    Produto:
                  </Typography>
                  <Typography variant='h6'>
                    {infoAdc?.[0]?.pdv_product || 'Não definido'}
                  </Typography>
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <Typography variant='h6' color='error'>
                  Nenhuma informação encontrada para este atendimento.
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </Dialog.Content>
      <Dialog.Actions>
        <Button variant='contained' color='primary' onClick={onClose}>
          Fechar
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
