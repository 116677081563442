import { useContext } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { inHomeService } from 'services';

import { NewServiceContext } from '../..';

export const RegisterProtocol = (values) => {
  const { updateProtocolData, setActiveStep } = useContext(NewServiceContext);
  const toTrim = (e) => {
    e.target.value = ('' + e.target.value).trim();
  };

  const handleSubmit = async (values) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      const { data } = await inHomeService.validExistsProtocolo({
        protocol: values.input_protocol,
      });
      updateProtocolData({ protocol: values.input_protocol });
      setActiveStep(1);
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao cadastrar o protocolo');
    }
  };
  return (
    <>
      <Box component={Paper} boxShadow={2} p={2}>
        <PageTitle>Protocolo Multi 360</PageTitle>
        <Formik initialValues={{ input_protocol: '' }} onSubmit={handleSubmit}>
          {(props) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Field
                    size='small'
                    loading={props.isSubmitting}
                    name='input_protocol'
                    variant='outlined'
                    fullWidth
                    label='Protocolo'
                    component={Text}
                    onInput={toTrim}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Button
                    type='submit'
                    disabled={!props.isValid || !props.dirty}
                    loading={props.isSubmitting}
                    endIcon={<ArrowForwardIcon />}
                    fullWidth
                  >
                    Avançar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};
