import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Button, Select, Table } from 'components';
import { Field, Form, Formik } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { registerService } from 'services/register';

import { DialogDesatv } from '../DialogDesatv';

const columns = [{ label: 'Sub Defeitos' }, { label: 'Desativar' }];

export const VincSubDefeitos = ({ line }) => {
  const [tableSubDefects, setTableSubDefects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialogDesatv, setOpenDialogDesatv] = useState(false);
  const [rowData, setRowData] = useState([]);

  const fetchData = async () => {
    const { data } = await registerService.findVincSubDefectsByIdPart({ id: line.id });
    setTableSubDefects(data);
  };

  const [subDefects, , ,] = useQuery(() => registerService.findSubDefect(), []);

  const optionsSubDefects = (subDefects || [])?.map((a) => ({
    value: a.id,
    label: a.subDefect,
    ativo: a.ativo,
  }));

  const typeSubDefectsIds = tableSubDefects.map((item) => item.id_sub_defect);

  const filteredOptionsDefects = optionsSubDefects.filter(
    (item) => !typeSubDefectsIds.includes(item.value),
  );

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addNewVincSubDefect = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await registerService.addNewVincSubDefect({
        id_parts: line.id,
        id_sub_efect: values.sub_defect,
      });
      toast.success('Vinculado com sucesso!');
      setIsLoading(false);
      await fetchData();
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const handleOpenDialogDesatv = (item) => {
    setOpenDialogDesatv(true);
    setRowData(item);
  };

  const handleCloseDialogDesatv = () => {
    setOpenDialogDesatv(false);
    fetchData();
  };

  return (
    <>
      <DialogDesatv
        handleCloseDialogDesatv={handleCloseDialogDesatv}
        openDialogDesatv={openDialogDesatv}
        rowData={rowData}
      />
      <Formik initialValues={{ defect: [] }} onSubmit={addNewVincSubDefect}>
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Field
                size='small'
                name='sub_defect'
                variant='outlined'
                fullWidth
                options={filteredOptionsDefects.filter((item) => item.ativo)}
                label='Sub Defeito'
                component={Select}
                multiple
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Button type='submit' fullWidth>
                Adicionar
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Table name='table' size='small' headers={columns} disableNumeration>
                {tableSubDefects
                  .filter((item) => item.active)
                  .map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.subDefect}</TableCell>
                      <TableCell>
                        <IconButton color='secondary'>
                          <RemoveCircleOutlineIcon onClick={() => handleOpenDialogDesatv(item)} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </Table>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};
