import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { TextField } from '@mui/material';
import { Button, CustomAutocomplete, Dialog } from 'components';
import formatter from 'date-fns/format';
import { ErrorMessage, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useQuery } from 'hooks/useQuery';
import { inHomeService } from 'services';
import { useContextSelector } from 'use-context-selector';

import validationSchema from '../DialogAssingDriver/validationSchema';

export const DialogFinishBackup = ({ open, onClose, line }) => {
  const [isLoading, setIsloading] = useState(false);
  const user = useContextSelector(AuthContext, (context) => context.user);

  const [tecs, , ,] = useQuery(() => inHomeService.findInHomeTec(), []);

  const optionsTecs = (tecs || [])?.map((a) => ({
    value: a.user_id.id,
    label: a.user_id.name,
    ativo: a.active,
    type_tec: a.type_tec,
  }));

  const filterActiveTecs = optionsTecs.filter(
    (item) => item.ativo && item.type_tec === 'Motorista',
  );

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return null;
    }
    const date = new Date(dateString);
    const formattedDate = formatter(date, "yyyy-MM-dd'T'HH:mm");
    return `${formattedDate}`;
  };

  const handleAssignDriveReturn = async (values) => {
    setIsloading(true);
    try {
      const { data } = await inHomeService.assignDriverReturnBackup({
        driver_id: values.input_driver.value,
        driver_name: values.input_driver.label,
        service_id: line.service_id,
        previously_id: line.service_status,
        status_backup: line.status_id,
        created_by: user.id,
        date_schedule: values.input_data,
        protocol: line.protocol,
      });
      toast.success('Motorista de devolução atribuído com sucesso!');
      onClose();
      setIsloading(false);
    } catch (error) {
      console.error(error?.response?.data?.message || error?.message);
      toast.error(error?.response?.data?.message || 'Erro ao atribuir motorista');
      setIsloading(false);
    }
  };
  return (
    <>
      <Dialog maxWidth={'md'} open={open}>
        <Dialog.Title onClose={onClose}>Finalizar Backup</Dialog.Title>
        <Dialog.Content>
          <Formik
            initialValues={{
              input_tec: '',
              input_data: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleAssignDriveReturn}
          >
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography align='center' variant='h5'>
                      Atribuir motorista de devolução
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <CustomAutocomplete
                      label='Motorista'
                      name='input_driver'
                      filteredValues={filterActiveTecs}
                      textFieldProps={{
                        fullWidth: true,
                        variant: 'outlined',
                      }}
                      value={props.values.input_driver}
                      onChange={(e, value) => props.setFieldValue('input_driver', value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      size='small'
                      label='Data e Hora'
                      name='input_data'
                      type='datetime-local'
                      fullWidth
                      value={props.values.input_data || ''}
                      onChange={(e) => props.setFieldValue('input_data', e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        inputProps: {
                          min: getCurrentDateTime(),
                        },
                      }}
                    />
                    <ErrorMessage
                      name='input_data'
                      component='div'
                      style={{ color: 'red', fontSize: '12px', marginTop: '2px' }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      startIcon={<CheckCircleIcon />}
                      type='submit'
                      loading={isLoading}
                    >
                      Adicionar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
