import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SearchIcon from '@mui/icons-material/Search';
import TablePagination from '@mui/material/TablePagination';
import { Button, Select, Table, Text } from 'components';
import formatter from 'date-fns/format';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { inHomeService } from 'services';
import { useContextSelector } from 'use-context-selector';

import { DialogAssignBackupProduct } from './Dialogs/DialogAssignBackupProduct';
import { DialogAssingDriver } from './Dialogs/DialogAssingDriver/index.jsx';
import { DialogFinishBackup } from './Dialogs/DialogFinishBackup/index.jsx';

const columns = [
  { label: 'Protocolo' },
  { label: 'Produto em Reparo' },
  { label: 'Serial em Reparo' },
  { label: 'Produto de Backup' },
  { label: 'Serial de Backup' },
  { label: 'Motorista' },
  { label: 'Status' },
  { label: 'Solicitado Por' },
  { label: 'Data da Solicitação' },
  { label: 'Atribuir Produto' },
  { label: 'Atribuir Motorista' },
  { label: 'Finalizar Backup' },
];

export const ServicesInBackup = () => {
  const [table, setTable] = useState([]);
  const [openDialogAssign, setOpenDialogAssign] = useState(false);
  const [openDialogAssignDriver, setOpenDialogAssignDriver] = useState(false);
  const [openDialogFinishBackup, setOpenDialogFinishBackup] = useState(false);
  const [page, setPage] = useState(0); // Estado para a página
  const [rowsPerPage, setRowsPerPage] = useState(5); // Estado para itens por página
  const [line, setLine] = useState();
  const [status, , ,] = useQuery(() => inHomeService.findStatusBackup(), []);
  const user = useContextSelector(AuthContext, (context) => context.user);

  const optionsStatus = (status || [])?.map((a) => ({
    value: a.id,
    label: a.description,
    ativo: a.active,
  }));

  const fetchData = useCallback(
    async (props) => {
      try {
        const { data } = await inHomeService.findServicesInBackup({
          status: props?.values?.status ? props.values.status : '',
          protocol: props?.values?.protocol ? props.values.protocol : '',
        });
        console.log(data[0].status_id);
        for (let i = 0; i < data.length; i++) {
          console.log(data[i].numeroos);
          const { data: status } = await inHomeService.validateStatusOs({
            numeroos: data?.[i]?.numeroos ? data[i].numeroos : undefined,
            service_id: data[i].service_id,
            user: user.id,
            previous_status: data[i].status_id,
          });
        }
        const { data: table } = await inHomeService.findServicesInBackup({
          status: props?.values?.status ? props.values.status : '',
          protocol: props?.values?.protocol ? props.values.protocol : '',
        });
        setTable(table);
        setPage(0);
      } catch (error) {
        setTable([]);
        console.error(error?.response?.data?.message || error?.message);
        toast.error(error?.response?.data?.message || 'Erro ao buscar atendimentos');
      }
    },
    [user.id],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = formatter(date, 'dd/MM/yyyy HH:mm');
    return `${formattedDate}`;
  };

  const handleOpenDialogAssignProduct = async (item) => {
    setLine(item);
    setOpenDialogAssign(true);
  };
  const handleCloseDialogAssignProduct = () => {
    setOpenDialogAssign(false);
    fetchData();
  };

  const handleOpenDialogAssignDriver = async (item) => {
    if (!item.serial_backup_product) {
      toast.error('O produto não tem serial de backup. Atribua um antes de atribuit o motorista');
      return;
    }
    setLine(item);
    setOpenDialogAssignDriver(true);
  };
  const handleCloseDialogAssignDriver = () => {
    setOpenDialogAssignDriver(false);
    fetchData();
  };

  const handleOpenDialogFinishBackup = (item) => {
    setLine(item);
    setOpenDialogFinishBackup(true);
  };
  const handleCloseDialogFinishBackup = () => {
    fetchData();
    setOpenDialogFinishBackup(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  console.log(table);
  return (
    <>
      <DialogFinishBackup
        open={openDialogFinishBackup}
        onClose={handleCloseDialogFinishBackup}
        line={line}
      />
      <DialogAssingDriver
        open={openDialogAssignDriver}
        onClose={handleCloseDialogAssignDriver}
        line={line}
      />
      <DialogAssignBackupProduct
        open={openDialogAssign}
        onClose={() => handleCloseDialogAssignProduct()}
        line={line}
      />
      <PageTitle>Atendimento em Backup</PageTitle>
      <Formik initialValues={{ status: '', protocol: '' }}>
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Field
                  name='status'
                  label='Status'
                  size='small'
                  variant='outlined'
                  component={Select}
                  options={optionsStatus.filter((item) => item.ativo)}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name='protocol'
                  label='Protocolo'
                  size='small'
                  variant='outlined'
                  component={Text}
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  onClick={() => fetchData(props)}
                  startIcon={<SearchIcon />}
                  disabled={!props.isValid || !props.dirty}
                  fullWidth
                >
                  Filtrar
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Table name='table' headers={columns} size='small' disableNumeration>
                  {table
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) // Controle de paginação
                    .map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.protocol}</TableCell>
                        <TableCell>{item.description_repair_product}</TableCell>
                        <TableCell>{item.serial_repair_product}</TableCell>
                        <TableCell>
                          {item.description_backup_product ? (
                            <Typography style={{ color: '#34db8d' }}>
                              {item.description_backup_product}
                            </Typography>
                          ) : (
                            <Typography style={{ color: 'red' }}>Pendente</Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          {item.serial_backup_product ? (
                            <Typography style={{ color: '#34db8d' }}>
                              {item.serial_backup_product}
                            </Typography>
                          ) : (
                            <Typography style={{ color: 'red' }}>Pendente</Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          {item.driver_id ? (
                            <Typography style={{ color: '#34db8d' }}>{item.driver_name}</Typography>
                          ) : (
                            <Typography style={{ color: 'red' }}>Pendente</Typography>
                          )}
                        </TableCell>
                        <TableCell>{item.status_backup}</TableCell>
                        <TableCell>{item.tec}</TableCell>
                        <TableCell>{formatDate(item.created_at)}</TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleOpenDialogAssignProduct(item)}>
                            <AddCircleOutlineIcon style={{ color: '#512da8' }} />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleOpenDialogAssignDriver(item)}>
                            <PersonAddIcon style={{ color: '#512da8' }} />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            disabled={item.status_id !== 5}
                            onClick={() => handleOpenDialogFinishBackup(item)}
                          >
                            <CheckCircleIcon style={{ color: '#512da8' }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </Table>
                <TablePagination
                  component='div'
                  count={table.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
