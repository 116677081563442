import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { Button, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { browserHistory } from 'routes/browserHistory';
import { api } from 'services';
import { useContextSelector } from 'use-context-selector';
// import { AuthContext } from 'hooks/useAuth';
// import { useContextSelector } from 'use-context-selector';
import * as Yup from 'yup';

import { useStyles } from './styles';

const validationSchema = Yup.object().shape({
  login: Yup.string().required('Por favor, informe o nome de usuário'),
  password: Yup.string().required('Por favor, informe sua senha'),
});

export const LoginForm = () => {
  // const signIn = useContextSelector(AuthContext, (context) => context.signIn);
  const [isLoging, setLoging] = useState();
  const classes = useStyles();

  const initialValues = {
    login: '',
    password: '',
  };

  const [values, setValues] = React.useState({
    password: '',
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // const navigateTo = async ({ email, password }, { setSubmitting, resetForm }) => {
  //   try {
  //     await api.post('/password/pre-authenticate', { email, password });
  //     toast.success('Primeira etapa do login validado com sucesso.');

  //     browserHistory.push({
  //       pathname: '/acess_code',
  //       state: {
  //         email: email,
  //         password: password,
  //       },
  //     });

  //     resetForm();
  //   } catch (err) {
  //     console.error('erro:', err);
  //     toast.error(err.response.data?.message);
  //   } finally {
  //     setSubmitting(false);
  //   }
  // };

  const signIn = useContextSelector(AuthContext, (context) => context.signIn);

  const onSubmitHandler = async ({ login, password }, { setSubmitting, resetForm }) => {
    try {
      const { data } = await api.get('authentication/eyJhY3RpdmVfZGlyZWN0b3J5IjoiZ2FpYSJ9');
      setLoging(1);
      const { active } = data;

      if (active === true) {
        await signIn({
          login,
          password,
        });
      } else {
        await api.post('/password/pre-authenticate', { login, password });
        toast.success('Primeira etapa do login validado com sucesso.');

        browserHistory.push({
          pathname: '/acess_code',
          state: {
            login: login,
            password: password,
          },
        });
      }

      resetForm();
    } catch (err) {
      toast.error(err?.response.data?.message);
      console.error(err?.response.data?.message);
    } finally {
      setSubmitting(false);
      setLoging(0);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      <Form className={classes.form}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Field
              name='login'
              variant='outlined'
              size='small'
              label='Usuário'
              type='text'
              fullWidth
              className={classes.input}
              component={Text}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name='password'
              variant='outlined'
              size='small'
              label='Senha'
              inputProps={{ minLength: 3 }}
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange('password')}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              fullWidth
              component={Text}
            />
          </Grid>

          <Grid item xs={12}>
            <Button type='submit' size='large' fullWidth disabled={isLoging == 1 ? true : false}>
              Logar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.linksContainer}>
              {/* <NavLink className={ck>lasses.link} to='/create-account'>
                Criar conta
              </NavLink
              <span className={classes.linksDivider}>|</span> */}
              {/* <NavLink className={classes.link} to='/password-recover'>
                Esqueceu a senha?
              </NavLink> */}
            </div>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};
