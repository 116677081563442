import { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import { Button, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { registerService } from 'services/register';

import { DialogConfirm } from './Dialog/DialogConfirm';
import { DialogEdit } from './Dialog/DialogEdit';
import { DialogRegister } from './Dialog/DialogRegister';

const columns = [
  { label: 'Nome Cliente' },
  { label: 'CNPJ/CPF' },
  { label: 'Telefone' },
  { label: 'Alterar' },
];

export const RegisterClient = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [openDialogRegister, setOpenDialogRegister] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [table, setTable] = useState([]);
  const [line, setLine] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleOpenDialogRegister = () => {
    setOpenDialogRegister(true);
  };

  const handleCloseDialogRegister = () => {
    setOpenDialogRegister(false);
    fetchData();
  };

  const handleOpenDialogEdit = (item) => {
    setLine(item);
    setOpenDialogEdit(true);
  };

  const handleCloseDialogEdit = () => {
    setOpenDialogEdit(false);
    fetchData();
  };

  const handleOpenDialogConfirm = () => {
    setOpenDialogConfirm(true);
  };

  const handleCloseDialogConfirm = () => {
    setOpenDialogConfirm(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = async () => {
    try {
      const { data } = await registerService.findClients();
      setTable(data);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filterTable = (search) => {
    if (!search) return table;
    return table.filter((item) => {
      const searchString = search.toLowerCase();
      return (
        item.name.toLowerCase().includes(searchString) ||
        item.cgc.toLowerCase().includes(searchString) ||
        item.residential_phone_number.toLowerCase().includes(searchString)
      );
    });
  };

  return (
    <>
      <DialogRegister
        openDialogRegister={openDialogRegister}
        handleCloseDialogRegister={handleCloseDialogRegister}
      />
      <DialogEdit
        openDialogEdit={openDialogEdit}
        handleCloseDialogEdit={handleCloseDialogEdit}
        line={line}
      />
      <DialogConfirm
        handleCloseDialogConfirm={handleCloseDialogConfirm}
        openDialogConfirm={openDialogConfirm}
      />
      <PageTitle>Cadastro de Cliente</PageTitle>
      <Formik initialValues={{ search: '' }}>
        {(props) => (
          <Form>
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs={3} md={3} lg={3}>
                <Button fullWidth loading={isLoading} onClick={handleOpenDialogRegister}>
                  Cadastrar
                </Button>
              </Grid>
              <Grid item xs={12} style={{ marginLeft: 'auto' }}>
                <Field
                  variant='outlined'
                  name='search'
                  label='Pesquisar'
                  size='small'
                  component={Text}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Box mt={2}>
              <Table name='Table' headers={columns} maxHeight disableNumeration>
                {filterTable(props.values.search)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.cgc}</TableCell>
                      <TableCell>{item.residential_phone_number}</TableCell>
                      <TableCell>
                        <EditIcon
                          style={{ color: '#512da8', cursor: 'pointer' }}
                          onClick={() => handleOpenDialogEdit(item)}
                        >
                          Editar
                        </EditIcon>
                      </TableCell>
                    </TableRow>
                  ))}
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                count={filterTable(props.values.search).length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
