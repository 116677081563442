import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Dialog, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { newConferenceService } from 'services/newConference';
import { useContextSelector } from 'use-context-selector';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  nf: Yup.string().required('O campo NF é obrigatório'),
  serie: Yup.string().required('O campo Série é obrigatório'),
  chave: Yup.string()
    .required('O campo Chave é obrigatório')
    .max(44)
    .matches(/^.{44}$/, 'A chave deve ter exatamente 44 caracteres'),
  input_data: Yup.date().required('A Data de Emissão é obrigatória'),
});

export const DialogEditNfCabec = ({
  openModalEditNfCabec,
  handleCloseModalEditNfCabec,
  nfCabec,
}) => {
  if (!openModalEditNfCabec) return null;
  const user = useContextSelector(AuthContext, (context) => context.user);

  console.log(nfCabec);

  const handleEditNF = async (values) => {
    try {
      const { data } = await newConferenceService.editNF({
        id: nfCabec.id,
        nf: values.nf,
        serie: values.serie,
        chave: values.chave,
        dataEmissao: values.input_data,
      });
      toast.success('Nota Fiscal editada com sucesso.');
      handleCloseModalEditNfCabec();
    } catch (error) {
      console.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Dialog maxWidth={'md'} open={openModalEditNfCabec}>
        <Dialog.Title onClose={handleCloseModalEditNfCabec}>Editar Nota Fiscal</Dialog.Title>
        <Dialog.Content>
          <Formik
            initialValues={{
              nf: nfCabec.num_invoice,
              serie: nfCabec.num_serie,
              chave: nfCabec.num_key,
              input_data: nfCabec.dtEmission,
            }}
            validationSchema={validationSchema}
            onSubmit={handleEditNF}
          >
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={3}>
                    <Field
                      size='small'
                      variant='outlined'
                      label='NF'
                      type='text'
                      fullWidth
                      name='nf'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Field
                      size='small'
                      variant='outlined'
                      label='Serie'
                      type='text'
                      fullWidth
                      name='serie'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Field
                      size='small'
                      variant='outlined'
                      label='Chave NFE'
                      type='text'
                      fullWidth
                      name='chave'
                      component={Text}
                      onChange={(e) => {
                        let inputValue = e.target.value;
                        if (inputValue.length > 44) {
                          inputValue = inputValue.slice(0, 44);
                        }
                        props.setFieldValue('chave', inputValue);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Field
                      size='small'
                      variant='inline'
                      fullWidth
                      inputVariant='outlined'
                      label='Data Emissão'
                      name='input_data'
                      component={DatePicker}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}></Grid>
                  <Grid item xs={12} lg={4}>
                    <Button type='submit' fullWidth>
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
