export const downloadcsv = async (namefile, data) => {
  const bom = '\uFEFF';
  const csvContent = [
    Object.keys(data[0]).join(';'),
    ...data.map((item) => Object.values(item).join(';')),
  ].join('\n');

  const csvData = new Blob([bom + csvContent], { type: 'text/csv;charset=utf-8;' });
  const csvUrl = window.URL.createObjectURL(csvData);
  const link = document.createElement('a');
  link.href = csvUrl;
  link.setAttribute('download', `${namefile}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
