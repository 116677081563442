import { api } from 'services/api';

export class RegisterService {
  // GET requests
  async getCepInfo(cep) {
    try {
      const response = await api.get(`https://viacep.com.br/ws/${cep}/json/`);
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar CEP:', error);
      throw error;
    }
  }

  async findBrands(data) {
    return api.get('/register/findBrands', data);
  }

  async findModels() {
    return api.get('/register/findModels');
  }

  async findClients() {
    return api.get('/register/findClients');
  }

  async findTypeProduct() {
    return api.get('/register/findTypeProduct');
  }

  async findCarrier() {
    return api.get('/register/findCarrier');
  }

  async findProducts() {
    return api.get('/register/findProducts');
  }
  async findSupplier() {
    return api.get('/register/findSupplier');
  }

  async findTableDefects() {
    return api.get('/register/findTableDefects');
  }
  async findSubDefect() {
    return api.get('/register/findSubDefect');
  }
  async findParts() {
    return api.get('/register/findParts');
  }

  // POST requests
  async createBrand(data) {
    return api.post('/register/createBrand', data);
  }

  async createModel(data) {
    return api.post('/register/createModel', data);
  }

  async createClient(data) {
    return api.post('/register/createClient', data);
  }

  async createTypeProduct(data) {
    return api.post('/register/createTypeProduct', data);
  }

  async createCarrier(data) {
    return api.post('/register/createCarrier', data);
  }
  async createSupplier(data) {
    return api.post('/register/createSupplier', data);
  }

  async findCliendAddresById(data) {
    return api.post('/register/findCliendAddresById', data);
  }

  async registerProduct(data) {
    return api.post('/register/createProduct', data);
  }
  async createDefect(data) {
    return api.post('/register/createDefect', data);
  }
  async createSubDefect(data) {
    return api.post('/register/createSubDefect', data);
  }
  async createParts(data) {
    return api.post('/register/createParts', data);
  }

  async findVincTypeProductsById(data) {
    return api.post('/register/findVincTypeProductsById', data);
  }
  async findTypesProductsByIdModel(data) {
    return api.post('/register/findTypesProductsByIdModel', data);
  }
  async findDefectsById(data) {
    return api.post('/register/findDefectsById', data);
  }
  async findVincSubDefectsByIdPart(data) {
    return api.post('/register/findVincSubDefectsByIdPart', data);
  }
  async addNewVincProd(data) {
    return api.post('/register/addNewVincProd', data);
  }
  async addNewVincDefect(data) {
    return api.post('/register/addNewVincDefect', data);
  }
  async addNewVincSubDefect(data) {
    return api.post('/register/addNewVincSubDefect', data);
  }

  // PUT requests
  async updateActive(data) {
    return api.put('/register/updateActive', data);
  }

  async updateBrand(data) {
    return api.put('/register/updateBrand', data);
  }

  async updateModel(data) {
    return api.put('/register/updateModel', data);
  }

  async updateModelsActive(data) {
    return api.put('/register/updateModelsActive', data);
  }

  async updateTypeProduct(data) {
    return api.put('/register/updateTypeProduct', data);
  }

  async updateTypeProductActive(data) {
    return api.put('/register/updateTypeProductActive', data);
  }

  async updateCarrier(data) {
    return api.put('/register/updateCarrier', data);
  }

  async updateClients(data) {
    return api.put('/register/updateClients', data);
  }

  async updateProduct(data) {
    return api.put('/register/updateProduct', data);
  }
  async updateSupplier(data) {
    return api.put('/register/updateSupplier', data);
  }
  async updateSupplieActive(data) {
    return api.put('/register/updateSupplieActive', data);
  }
  async updateProductsActive(data) {
    return api.put('/register/updateProductsActive', data);
  }
  async updateCarrirerActive(data) {
    return api.put('/register/updateCarrirerActive', data);
  }
  async updateDefect(data) {
    return api.put('/register/updateDefect', data);
  }
  async updateDefectActive(data) {
    return api.put('/register/updateDefectActive', data);
  }
  async updateSubDefect(data) {
    return api.put('/register/updateSubDefect', data);
  }
  async updateSubDefectActive(data) {
    return api.put('/register/updateSubDefectActive', data);
  }
  async updatePartsActive(data) {
    return api.put('/register/updatePartsActive', data);
  }
  async updateParts(data) {
    return api.put('/register/updateParts', data);
  }
  async desatvVinc(data) {
    return api.put('/register/desatvVinc', data);
  }
}

export const registerService = new RegisterService();
