import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { Button, CustomAutocomplete, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useQuery } from 'hooks/useQuery';
import { newConferenceService } from 'services/newConference';
import { useContextSelector } from 'use-context-selector';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  numero_nf: Yup.string().required('Campo NF é obrigatório'),
  product_nf: Yup.object().nullable().required('Campo Produto NF é obrigatório'),
  intern_product: Yup.string().required('Campo Produto Interno é obrigatório'),
  unit_value: Yup.number().required('Campo Valor Unitário é obrigatório'),
  qtd: Yup.number().required('Campo Qtd é obrigatório').positive('A quantidade deve ser positiva'),
  // ncm: Yup.string().length(8, 'O NCM deve ter exatamente 8 dígitos'),
  // cst: Yup.string().required('Campo CST é obrigatório'),
  // ipi_value: Yup.number().required('Campo Valor IPI é obrigatório'),
  // aliq_icms: Yup.number().required('Campo Aliq ICMS é obrigatório'),
  // aliq_ipi: Yup.number().required('Campo Aliq IPI é obrigatório'),
  // bc_icms: Yup.number().required('Campo BC ICMS é obrigatório'),
  // icms_value: Yup.number().required('Campo Valor ICMS é obrigatório'),
  // cmsst_value: Yup.number().required('Campo Valor CMSST é obrigatório'),
  // cmsst_qtd: Yup.number().required('Campo Qtd CMSST é obrigatório'),
});

export const RegisterNewItem = ({ handleBackToNf, line }) => {
  const [internProduct, setInternProduct] = useState('');
  const user = useContextSelector(AuthContext, (context) => context.user);
  const [infoItem, setInfoItem] = useState([]);
  console.log('line new item', line);

  const processItemNF = async (props) => {
    if (line.typeAction === 1) {
      try {
        const { data } = await newConferenceService.insertItensNF({
          idNotaFiscal: line.id,
          produto: props.values.product_nf.value,
          valorUnitario: props.values.unit_value,
          qtd: props.values.qtd,
          total_item: props.values.total_item,
          ncm: props.values.ncm,
          cst: props.values.cst,
          ipi: props.values.ipi_value,
          aliq_icms: props.values.aliq_icms,
          aliq_ipi: props.values.aliq_ipi,
          bc_icms: props.values.bc_icms,
          icms: props.values.icms_value,
          cmsst: props.values.cmsst_value,
          cmsst_qtd: props.values.cmsst_qtd,
          user: user.id,
        });
        toast.success('Item registrado com sucesso!');
        props.resetForm();
      } catch (error) {
        console.error(error?.response?.data?.message);
        toast.error('Erro ao inserir item!');
      }
    } else {
      try {
        const { data } = await newConferenceService.editItemNF({
          idItem: line.id,
          idNotaFiscal: line.id,
          produto: props.values.product_nf.value,
          valorUnitario: props.values.unit_value,
          qtd: props.values.qtd,
          total_item: props.values.total_item,
          ncm: props.values.ncm,
          cst: props.values.cst,
          ipi: props.values.ipi_value,
          aliq_icms: props.values.aliq_icms,
          aliq_ipi: props.values.aliq_ipi,
          bc_icms: props.values.bc_icms,
          icms: props.values.icms_value,
          cmsst: props.values.cmsst_value,
          cmsst_qtd: props.values.cmsst_qtd,
          user: user.id,
        });
        toast.success('Item editado com sucesso!');
        handleBackToNf();
      } catch (error) {
        toast.error('Erro ao editar item!');
        console.error(error?.response?.data?.message);
      }
    }
  };

  const [typeProduct, , ,] = useQuery(() => newConferenceService.listProduct(), []);

  const optiontypeProduct = (typeProduct || [])?.map((a) => ({
    value: a.id,
    label: a.produto,
  }));

  const handleProdInterno = async (values, setFieldValue) => {
    try {
      const { data } = await newConferenceService.getProdIterno({
        prodIterno: values,
      });
      const internalProductValue = data[0]?.produtoInterno || '';
      setInternProduct(internalProductValue);
      setFieldValue('intern_product', internalProductValue);
    } catch (error) {
      console.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    }
  };

  const calcValueTotal = (props) => {
    if (props.values.unit_value > 0 && props.values.qtd > 0) {
      const total = props.values.unit_value * props.values.qtd;
      return props.setFieldValue('total_item', total);
    }
  };

  const handleItensNF = useCallback(async () => {
    try {
      const { data } = await newConferenceService.getItensNF({ idNota: line.idInvoiceCabec });
      setInfoItem(data);
      console.log('consulta nova', data);
    } catch (error) {
      console.error(error?.response?.data?.message);
    }
  }, [line.idInvoiceCabec]);

  useEffect(() => {
    handleItensNF();
  }, [handleItensNF]);

  return (
    <>
      <Box mt={2} component={Paper} boxShadow={3} p={2}>
        <Formik
          initialValues={{
            product_nf:
              line.typeAction === 2
                ? { value: infoItem[0]?.products?.id, label: infoItem[0]?.products?.descricao }
                : '',
            intern_product: line.typeAction === 2 ? infoItem[0]?.products?.produtoInterno : '',
            unit_value: line.typeAction === 2 ? infoItem[0]?.value_unit : '',
            qtd: line.typeAction === 2 ? infoItem[0]?.qtd_itens : '',
            total_item: line.typeAction === 2 ? infoItem[0]?.amount_total : '',
            ncm: line.typeAction === 2 ? infoItem[0]?.num_ncm : '',
            cst: line.typeAction === 2 ? infoItem[0]?.num_cst : '',
            ipi_value: line.typeAction === 2 ? infoItem[0]?.num_ipi : '',
            aliq_icms: line.typeAction === 2 ? infoItem[0]?.value_icms : '',
            aliq_ipi: line.typeAction === 2 ? infoItem[0]?.value_ipi : '',
            bc_icms: line.typeAction === 2 ? infoItem[0]?.num_bcicms : '',
            icms_value: line.typeAction === 2 ? infoItem[0]?.num_icms : '',
            cmsst_value: line.typeAction === 2 ? infoItem[0]?.value_cmsst : '',
            cmsst_qtd: line.typeAction === 2 ? infoItem[0]?.qtd_cmsst : '',
          }}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={processItemNF}
        >
          {(props) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                  <CustomAutocomplete
                    label='Produto NF'
                    value={props.values.product_nf || ''}
                    filteredValues={optiontypeProduct || []}
                    name='product_nf'
                    textFieldProps={{
                      fullWidth: true,
                      variant: 'outlined',
                    }}
                    onChange={(_, newValue) => {
                      if (newValue) {
                        props.setFieldValue('product_nf', newValue);
                        handleProdInterno(newValue.value, props.setFieldValue);
                      } else {
                        props.setFieldValue('product_nf', null);
                        props.setFieldValue('intern_product', '');
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='Produto Interno'
                    type='text'
                    fullWidth
                    name='intern_product'
                    component={Text}
                    value={internProduct}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='Valou Unitário'
                    type='text'
                    fullWidth
                    name='unit_value'
                    value={props?.values?.unit_value ? props?.values?.unit_value : ''}
                    component={Text}
                    onBlur={() => calcValueTotal(props)}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='Qtd'
                    type='text'
                    fullWidth
                    value={props?.values?.qtd ? props?.values?.qtd : ''}
                    name='qtd'
                    onBlur={() => calcValueTotal(props)}
                    component={Text}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={12}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='Total do Item'
                    type='text'
                    fullWidth
                    value={props?.values?.total_item ? props?.values?.total_item : ''}
                    name='total_item'
                    component={Text}
                    required
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='NCM'
                    type='text'
                    fullWidth
                    name='ncm'
                    value={props?.values?.ncm ? props?.values?.ncm : ''}
                    component={Text}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='CST'
                    type='text'
                    fullWidth
                    value={props?.values?.cst ? props?.values?.cst : ''}
                    name='cst'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='Valor IPI'
                    type='text'
                    fullWidth
                    value={props?.values?.ipi_value ? props?.values?.ipi_value : ''}
                    name='ipi_value'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='Aliq ICMS'
                    type='text'
                    fullWidth
                    value={props?.values?.aliq_icms ? props?.values?.aliq_icms : ''}
                    name='aliq_icms'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='Aliq IPI'
                    type='text'
                    fullWidth
                    value={props?.values?.aliq_ipi ? props?.values?.aliq_ipi : ''}
                    name='aliq_ipi'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='BC ICMS'
                    type='text'
                    fullWidth
                    value={props?.values?.bc_icms ? props?.values?.bc_icms : ''}
                    name='bc_icms'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='Valor ICMS'
                    type='text'
                    fullWidth
                    value={props?.values?.icms_value ? props?.values?.icms_value : ''}
                    name='icms_value'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='Valor CMSST'
                    type='text'
                    fullWidth
                    value={props?.values?.cmsst_value ? props?.values?.cmsst_value : ''}
                    name='cmsst_value'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='Qtd CMSST'
                    type='text'
                    fullWidth
                    value={props?.values?.cmsst_qtd ? props?.values?.cmsst_qtd : ''}
                    name='cmsst_qtd'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Button onClick={() => handleBackToNf()} color='secondary' fullWidth>
                    Voltar
                  </Button>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Button onClick={() => processItemNF(props)} fullWidth>
                    Salvar Item
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};
