import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import formatter from 'date-fns/format';

import SearchIcon from '@mui/icons-material/Search';
import { Button, CustomAutocomplete, Dialog } from 'components';
import { Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useQuery } from 'hooks/useQuery';
import { inHomeService } from 'services';
import { useContextSelector } from 'use-context-selector';

export const DialogAssignTec = ({ open, onClose, selectedEvent, onSave }) => {
  if (!open) return null;

  const user = useContextSelector(AuthContext, (context) => context.user);

  const [infoAdc, setInfoAdc] = useState([]);
  const [closestTech, setClosestTech] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [infoAdcService, setInfoAdcService] = useState([]);
  const [vincProd, setVincProd] = useState([]);
  const [tecs, , ,] = useQuery(() => inHomeService.findInHomeTec(), []);

  const optionsTecs = (tecs || [])?.map((a) => ({
    value: a.user_id.id,
    label: a.user_id.name,
    ativo: a.active,
  }));

  const filterActiveTecs = optionsTecs.filter((item) => item.ativo);

  const fetchData = useCallback(async () => {
    if (!selectedEvent?.id) return; // Verifica se selectedEvent está disponível
    setIsLoading(true);
    try {
      // Primeiro busca a informação adicional do serviço
      const { data } = await inHomeService.findInfoAdcByIdService({ service_id: selectedEvent.id });

      if (!data || data.length === 0) {
        setIsLoading(false);
        return;
      }

      // Em seguida, busca a vinculação dos técnicos
      const { data: vincs } = await inHomeService.findVincsProdTecByIdProd({
        prod: data[0].group_prod,
      });

      const address = `${data[0]?.street} ${data[0]?.number}, ${data[0]?.city}, ${data[0]?.state}`;

      const newCoords = await getCoordinates(address);
      let closestDistance = Infinity; // Inicializa a menor distância como Infinity
      let closest = null;

      // Para cada técnico vinculado, busque os serviços
      for (const tech of vincs) {
        const { data: services } = await inHomeService.findServicesByTec({ id_tec: tech?.tec?.id });

        for (const service of services) {
          const serviceAddress = `${service.street} ${service.number}, ${service.city}, ${service.state}`;
          const serviceCoords = await getCoordinates(serviceAddress);

          if (serviceCoords) {
            const distance = haversineDistance(newCoords, serviceCoords);

            // Verifica se a distância atual é menor que a menor distância encontrada
            if (distance < closestDistance) {
              closestDistance = distance;
              closest = { tech, distance, service, services };
            }
          }
        }
      }

      let countServicesByTec = 0;
      if (closest) {
        const serviceDate = new Date(closest.service.date_Scheduling);

        // Conta quantos serviços o técnico mais próximo tem no mesmo dia
        countServicesByTec = closest.services.filter((svc) => {
          const currentServiceDate = new Date(svc.date_Scheduling);
          return (
            serviceDate.getFullYear() === currentServiceDate.getFullYear() &&
            serviceDate.getMonth() === currentServiceDate.getMonth() &&
            serviceDate.getDate() === currentServiceDate.getDate()
          );
        }).length;
      }

      setClosestTech({ closest, countServicesByTec });
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    } finally {
      setIsLoading(false);
    }
  }, [selectedEvent?.id]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleGetInfoAdcTec = async (props) => {
    const infoAdc = tecs.filter((item) => item.user_id.id === props.values.input_tec.value);
    const { data } = await inHomeService.findVincsProdTecById({ id: props.values.input_tec.value });
    setInfoAdc(infoAdc);
    setVincProd(data);
  };
  const handleAssignTec = async (values) => {
    try {
      const { data } = await inHomeService.assignTec({
        id_service: selectedEvent.id,
        id_tec: values.input_tec.value,
        name_tec: values.input_tec.label,
        protocol: selectedEvent.title,
        previously_id: selectedEvent.extendedProps.status_id,
        user_assign: user.id,
        date_schedule: selectedEvent.start,
      });
      toast.success('Técnico atribuído com sucesso.');
      onClose();
      onSave();
    } catch (error) {
      console.error(error?.response?.data?.message || error.message);
      toast.error(error?.response?.data?.message || 'Erro atribuir técnico');
    }
  };

  const specialitys = vincProd.map((item) => item.product).join(', ');

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = formatter(date, 'dd/MM/yyyy HH:mm');
    return `${formattedDate}`;
  };

  return (
    <>
      <Dialog maxWidth={'md'} open={open}>
        <Dialog.Title onClose={onClose}>Atribuir Técnico - {selectedEvent.title}</Dialog.Title>
        <Dialog.Content>
          <Box border={1} borderRadius={4} p={2} textAlign='center' color='#512da8' mb={2}>
            {isLoading ? (
              <Typography variant='body1'>Carregando sugestão de técnico...</Typography>
            ) : closestTech && closestTech.closest ? (
              <>
                <Typography variant='h6'>
                  Técnico com atendimento mais próximo: {closestTech.closest.tech?.tec.name}
                </Typography>
                <Typography variant='body1'>
                  Endereço:
                  {`${closestTech.closest.service?.street} ${closestTech.closest.service?.number}, 
                  ${closestTech.closest.service?.city}, ${closestTech.closest.service?.state}`}
                </Typography>
                <Typography variant='body1'>
                  Distância: {closestTech.closest.distance?.toFixed(2)} km
                </Typography>
                <Typography variant='body1'>
                  Data do atendimento: {formatDate(closestTech.closest.service?.date_Scheduling)}
                </Typography>
                <Typography>Atendimentos no mesmo dia: {closestTech.countServicesByTec}</Typography>
              </>
            ) : (
              <Typography variant='body1'>Nenhuma sugestão encontrada.</Typography>
            )}
          </Box>
          <Formik initialValues={{ input_tec: '' }} onSubmit={handleAssignTec}>
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={11}>
                    <CustomAutocomplete
                      label='Técnico'
                      name='input_tec'
                      filteredValues={filterActiveTecs.filter((item) => item.label) || []}
                      textFieldProps={{
                        fullWidth: true,
                        variant: 'outlined',
                      }}
                      value={props.values.input_tec ? props.values.input_tec.label : ''}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton>
                      <SearchIcon
                        onClick={() => handleGetInfoAdcTec(props)}
                        style={{ color: '#512da8' }}
                      />
                    </IconButton>
                  </Grid>
                  {infoAdc.length > 0 && (
                    <>
                      <Grid item xs={12}>
                        <Card>
                          <CardHeader title='Informações do Técnico' />
                          <Divider />
                          <CardContent>
                            <Box display={'flex'} flexDirection={'row'}>
                              <Grid container spacing={2}>
                                <Grid item xs={2}>
                                  <Typography variant='body1'>
                                    <strong>Jornada: {infoAdc[0].journey}</strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography variant='body1'>
                                    <strong>Pode fazer extra? {infoAdc[0].extra}</strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography variant='body1'>
                                    <strong>Especialidades do técnico:</strong>{' '}
                                    {specialitys ? specialitys : ''}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={6}>
                    <Button onClick={onClose} color='secondary' fullWidth>
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button disabled={infoAdc.length === 0} fullWidth type='submit'>
                      Confirmar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};

const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
// Funções auxiliares
const getCoordinates = async (address) => {
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address,
    )}&key=${key}`,
  );
  const data = await response.json();
  if (data.results.length > 0) {
    const location = data.results[0].geometry.location;
    return { lat: location.lat, lng: location.lng };
  }
  return null;
};

const haversineDistance = (coords1, coords2) => {
  const R = 6371; // Raio da Terra em km
  const dLat = (coords2.lat - coords1.lat) * (Math.PI / 180);
  const dLng = (coords2.lng - coords1.lng) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(coords1.lat * (Math.PI / 180)) *
      Math.cos(coords2.lat * (Math.PI / 180)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distância em km
};
