import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog } from 'components';

export const DialogDeleteItem = ({
  openDialogDeleteItem,
  handleCloseDialogDeleteItem,
  line,
  onDeleteItem,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleDeleteItem = async () => {
    setIsLoading(true);
    try {
      await onDeleteItem(line);
      toast.success('Item excluído com sucesso!');
    } catch (error) {
      toast.error('Erro ao excluir item');
      console.error(error);
    } finally {
      setIsLoading(false);
      handleCloseDialogDeleteItem();
    }
  };
  return (
    <>
      <Dialog open={openDialogDeleteItem}>
        <Dialog.Title onClose={handleCloseDialogDeleteItem}>Excluir item</Dialog.Title>
        <Dialog.Content>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                align='center'
                variant='h2'
                style={{ fontSize: '24px', fontWeight: 'bold', paddingBottom: '1rem' }}
              >
                Deseja realmente excluir o item?
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Button color='secondary' fullWidth loading={isLoading}>
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Button fullWidth type='submit' loading={isLoading} onClick={handleDeleteItem}>
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
