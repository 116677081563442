import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog } from 'components';
import { AuthContext } from 'hooks/useAuth';
import { stockService } from 'services';
import { useContextSelector } from 'use-context-selector';

export const DialogDelete = ({ openDialogDelete, handleCloseDialogDelete, line }) => {
  const [isLoading, setIsLoading] = useState(false);
  const user = useContextSelector(AuthContext, (context) => context.user);
  console.log(line);

  const handleDstvNF = async () => {
    setIsLoading(true);
    try {
      const { data } = await stockService.desatvNF({ id: line.id, user: user });
      toast.success('NF excluida com sucesso');
      handleCloseDialogDelete();
    } catch (error) {
      console.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Dialog open={openDialogDelete}>
        <Dialog.Title onClose={handleCloseDialogDelete}>Cancelar</Dialog.Title>
        <Dialog.Content>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                align='center'
                variant='h2'
                style={{ fontSize: '24px', fontWeight: 'bold', paddingBottom: '1rem' }}
              >
                Deseja realmente excluir?
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Button
                color='secondary'
                fullWidth
                onClick={handleCloseDialogDelete}
                loading={isLoading}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Button fullWidth onClick={handleDstvNF} loading={isLoading}>
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
