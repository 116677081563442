import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { Button, Dialog, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { stockService } from 'services';

import { DialogDivergencia } from '../Dialogs/DialogDivergencia';

const columns = [
  { label: 'PN' },
  { label: 'Descrição' },
  { label: 'Local' },
  { label: 'Qtd Total' },
  { label: 'Conferir' },
  { label: 'Divergência' },
];

export const DialogConference = ({ openDialogConference, handleCloseDialogConference, line }) => {
  const [openDialogDivergencia, setOpenDialogDivergencia] = useState(false);
  const [dataItem, setDataItem] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [confirmedItems, setConfirmedItems] = useState([]);
  const [itemNF, setItemNF] = useState([]);
  const [divergentItems, setDivergentItems] = useState([]);
  console.log(line);

  const handleOpenDialogDivergencia = (item) => {
    setOpenDialogDivergencia(true);
    setItemNF(item);
  };

  const handleCloseDialogDivergencia = () => {
    setOpenDialogDivergencia(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleConfirm = (item) => {
    console.log('Dados da linha confirmada:', item);
    setConfirmedItems((prev) => [...prev, item]);
  };

  const handleDivergence = (item) => {
    handleOpenDialogDivergencia(item);
    console.log('Divergência na linha:', item);
    setDivergentItems((prev) => [...prev, item]);
  };

  const isItemConfirmedOrDivergent = (item) => {
    return confirmedItems.includes(item) || divergentItems.includes(item);
  };

  const processConference = async (values) => {
    toast.success('Conferência realizada com sucesso!');
    handleCloseDialogConference();
  };

  const handleTableItens = useCallback(async () => {
    try {
      const { data } = await stockService.getItensNF({ idCabec: line.id });
      console.log(data);
      setDataItem(data);
    } catch (error) {
      console.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    }
  }, [line.id]);

  useEffect(() => {
    if (openDialogConference) {
      handleTableItens();
    }
  }, [openDialogConference, handleTableItens]);

  return (
    <>
      <DialogDivergencia
        handleCloseDialogDivergencia={handleCloseDialogDivergencia}
        openDialogDivergencia={openDialogDivergencia}
        itemNF={itemNF}
      />
      <Dialog maxWidth={'lg'} open={openDialogConference}>
        <Dialog.Title onClose={handleCloseDialogConference}>Conferencia de Peças</Dialog.Title>
        <Dialog.Content>
          <Formik initialValues={{}} onSubmit={processConference}>
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Field
                      size='small'
                      name='search'
                      variant='outlined'
                      fullWidth
                      label='Pesquisar'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Table name='Table' headers={columns} disableNumeration>
                      {dataItem
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{item.parnumber}</TableCell>
                            <TableCell>{item.description}</TableCell>
                            <TableCell>Local</TableCell>
                            <TableCell>{item.qtd}</TableCell>
                            <TableCell>
                              <Button
                                fullWidth
                                disabled={isItemConfirmedOrDivergent(item)}
                                onClick={() => handleConfirm(item)}
                              >
                                {confirmedItems.includes(item) ? 'Confirmado' : 'Confirmar'}
                              </Button>
                            </TableCell>
                            <TableCell>
                              <Button
                                color='secondary'
                                fullWidth
                                disabled={isItemConfirmedOrDivergent(item)}
                                onClick={() => handleDivergence(item)}
                              >
                                {divergentItems.includes(item) ? 'Divergência' : 'Divergência'}
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </Table>
                    <TablePagination
                      component='div'
                      count={dataItem.length}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelRowsPerPage='Itens por página'
                    />
                    <Grid container justifyContent='center' alignItems='center'>
                      <Grid item xs={3} lg={3} md={3}>
                        <Button type='submit' color='primary' fullWidth>
                          Salvar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
