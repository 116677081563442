import { useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { Button, Dialog, Select, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useQuery } from 'hooks/useQuery';
import { registerService } from 'services/register';
import { useContextSelector } from 'use-context-selector';
import * as Yup from 'yup';

// Validação dos campos
const validationSchema = Yup.object({
  input_name: Yup.string().required('Nome do produto é obrigatório'),
  input_description: Yup.string().required('Descrição do produto é obrigatória'),
  input_type_product: Yup.string().required('Tipo de produto é obrigatório'),
  input_marca: Yup.string().required('Marca é obrigatória'),
  input_model: Yup.string().required('Modelo é obrigatório'),
  input_color: Yup.string().required('Cor é obrigatória'),
});

export const DialogRegister = ({ openDialogRegister, handleCloseDialogRegister }) => {
  if (!openDialogRegister) return null;
  const user = useContextSelector(AuthContext, (context) => context.user);

  const [isLoading, setIsLoading] = useState(false);

  // Get Data
  const [typeProduct, , ,] = useQuery(() => registerService.findTypeProduct(), []);

  const optiontypeProduct = (typeProduct || [])?.map((a) => ({
    value: a.id,
    label: a.descricao,
    ativo: a.ativo,
  }));

  const [brand, , ,] = useQuery(() => registerService.findBrands(), []);

  const optionBrand = (brand || [])?.map((a) => ({
    value: a.id,
    label: a.descricao,
    ativo: a.ativo,
  }));

  const [model, , ,] = useQuery(() => registerService.findModels(), []);

  const optionModel = (model || [])?.map((a) => ({
    value: a.id,
    label: a.descricao,
    ativo: a.ativo,
  }));

  // Register Product
  const handleRegisterProduct = async (values) => {
    toast.info('Registrando produto...');
    setIsLoading(true);
    try {
      const { data } = await registerService.registerProduct({
        name: values.input_name,
        description: values.input_description,
        type_product: values.input_type_product,
        marca: values.input_marca,
        model: values.input_model,
        color: values.input_color,
        user: user.id,
      });
      toast.success('Produto cadastrado com sucesso!');
      handleCloseDialogRegister();
      setIsLoading(false);
    } catch (error) {
      console.error(error?.response?.data?.message || error.message);
      toast.error(error?.response?.data?.message || error.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Dialog maxWidth='lg' open={openDialogRegister}>
        <Dialog.Title onClose={handleCloseDialogRegister}>Cadastro</Dialog.Title>
        <Dialog.Content>
          <Formik
            initialValues={{
              input_name: '',
              input_description: '',
              input_type_product: '',
              input_marca: '',
              input_model: '',
              input_color: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleRegisterProduct}
          >
            <Box mt={2} component={Paper} boxShadow={3} p={2}>
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={6}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_name'
                      variant='outlined'
                      fullWidth
                      label='Produto de Origem'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_description'
                      variant='outlined'
                      fullWidth
                      label='Descrição Produto'
                      component={Text}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={3}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_type_product'
                      variant='outlined'
                      fullWidth
                      label='Tipo Produto'
                      component={Select}
                      options={optiontypeProduct.filter((a) => a.ativo)}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_marca'
                      variant='outlined'
                      fullWidth
                      label='Marca'
                      component={Select}
                      options={optionBrand.filter((a) => a.ativo)}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_model'
                      variant='outlined'
                      fullWidth
                      label='Modelo'
                      component={Select}
                      options={optionModel.filter((a) => a.ativo)}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_color'
                      variant='outlined'
                      fullWidth
                      label='Cor'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={3} md={3} lg={3}>
                    <Button type='submit' fullWidth loading={isLoading}>
                      Cadastrar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Box>
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
