import { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { Button, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { inHomeService } from 'services';

import { DialogPartsForService } from './Dialogs/DialogPartsForService';

const columns = [
  { label: 'Protocolo', align: 'center' },
  { label: 'Técnico Responsável', align: 'center' },
  { label: 'Conferir', align: 'center' },
];

export const ConferenceParts = () => {
  const [table, setTable] = useState([]);
  const [filteredTable, setFilteredTable] = useState([]);
  const [openConferenceParts, setOpenConferenceParts] = useState(false);
  const [line, setLine] = useState();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const fetchData = async () => {
    const { data } = await inHomeService.findFinishServices();
    setTable(data);
    setFilteredTable(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleOpenConferenceParts = (item) => {
    setLine(item);
    setOpenConferenceParts(true);
  };

  const handleCloseConferenceParts = () => {
    setOpenConferenceParts(false);
    fetchData();
  };

  // Funções para controle de paginação
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilter = (value) => {
    const filteredData = table.filter((item) =>
      item.protocol.toLowerCase().includes(value.toLowerCase()),
    );
    setFilteredTable(filteredData);
    setPage(0); // Resetar a página para o início ao filtrar
  };

  return (
    <>
      <DialogPartsForService
        open={openConferenceParts}
        onClose={handleCloseConferenceParts}
        line={line}
      />
      <PageTitle>Conferência de Peças</PageTitle>

      <Formik
        initialValues={{ protocol: '' }}
        onChange={({ target: { value } }) => handleFilter(value)}
      >
        {(props) => (
          <Form>
            <Box mt={2} boxShadow={2} p={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    name='protocol'
                    label='Protocolo'
                    variant='outlined'
                    size='small'
                    component={Text}
                    onInput={(e) => handleFilter(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mt={2} boxShadow={2} p={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Table
                    size='small'
                    emptyMessage='Não há atendimentos'
                    disableNumeration
                    headers={columns}
                  >
                    {filteredTable
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((item, index) => (
                        <TableRow key={index}>
                          <TableCell align='center'>{item.protocol}</TableCell>
                          <TableCell align='center'>{item.tec}</TableCell>
                          <TableCell align='center'>
                            <Button onClick={() => handleOpenConferenceParts(item)} size='small'>
                              Conferir
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </Table>

                  {/* Componente de Paginação */}
                  <TablePagination
                    component='div'
                    count={filteredTable.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage='Linhas por página'
                  />
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
