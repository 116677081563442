import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import UploadIcon from '@mui/icons-material/Upload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button, DatePicker, Dialog, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { parseXmlToJson } from 'helpers/readXml';
import { AuthContext } from 'hooks/useAuth';
import { newConferenceService } from 'services/newConference';
import { useContextSelector } from 'use-context-selector';

import { DialogConfirmDelete } from './DialogConfirmDelete';
import { DialogEditNfCabec } from './DialogEditNfCabec';
import { RegisterNewItem } from './DialogNewItem';
import { ViewItens } from './ViewItens';

const columns = [
  { label: 'Nota Fiscal' },
  { label: 'Serie' },
  { label: 'Chave' },
  { label: 'Data de Emissão' },
  { label: 'Editar Nota' },
  { label: 'Excluir Nota' },
  { label: 'Add Itens' },
  { label: 'Visualizar Itens' },
];

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const DialogRegisterNf = ({ openModalNF, handleCloseModalNF, linhaInfos }) => {
  if (!openModalNF) return null;

  const [tabIndex, setTabIndex] = useState(0);
  const [openModalEditNfCabec, setOpenModalEditNfCabec] = useState(false);
  const [openModalConfirmDelete, setOpenModalConfirmDelete] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [nfCabec, setNfCabec] = useState();
  const [searchQuery, setSearchQuery] = useState('');
  const user = useContextSelector(AuthContext, (context) => context.user);
  const [table, setDataTable] = useState([]);
  const [line, setLine] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeToRegisterItens = (item, typeAction) => {
    setLine({ ...item, typeAction });
    setTabIndex(1);
  };

  const handleChangeToViewItens = (item) => {
    setLine(item);
    setTabIndex(2);
  };

  const handleBackToNf = () => {
    setTabIndex(0);
  };

  const handleOpenModalEditNfCabec = async (item) => {
    setNfCabec(item);
    setOpenModalEditNfCabec(true);
  };
  const handleCloseModalEditNfCabec = () => {
    setOpenModalEditNfCabec(false);
    handleNFs();
  };
  const handleOpenModalConfirmDelete = (item) => {
    setNfCabec(item);
    setOpenModalConfirmDelete(true);
  };
  const handleCloseModalConfirmDelete = () => {
    setOpenModalConfirmDelete(false);
    handleNFs();
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  const filteredData = table.filter((item) => {
    return (
      item.num_invoice.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.num_serie.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.num_key.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.dtEmission.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const handleRegisterNf = async (props) => {
    try {
      const { data } = await newConferenceService.registerNF({
        idLote: linhaInfos.lot_id,
        nf: props.values.nf_view,
        serie: props.values.serie,
        chave: props.values.key,
        dataEmissao: props.values.input_data,
        user: user.id,
      });
      toast.success('NF registrada com sucesso!');
      handleNFs();
      props.resetForm();
    } catch (error) {
      console.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleNFs = useCallback(async () => {
    try {
      const { data } = await newConferenceService.getNFs({ idLote: linhaInfos.lot_id });
      setDataTable(data);
      console.log(data);
    } catch (error) {
      console.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    }
  }, [linhaInfos.lot_id]);

  useEffect(() => {
    if (openModalNF) {
      handleNFs();
    }
  }, [openModalNF, handleNFs]);

  const handleXmlUpload = async (event, props) => {
    const file = event.target.files[0];
    if (!file) {
      toast.error('Nenhum arquivo selecionado.');
      return;
    }
    const reader = new FileReader();

    reader.onload = async (e) => {
      const xmlString = e.target.result;
      const json = parseXmlToJson(xmlString);
      setIsLoading(true);

      try {
        const { data } = await newConferenceService.registerXML({
          json: json,
          idLote: linhaInfos.lot_id,
          user: user.id,
        });
        toast.success('XML registrado com sucesso!');
        await handleNFs();
      } catch (error) {
        toast.error('Erro ao enviar XML');
        console.error(error?.response?.data?.message);
      } finally {
        setIsLoading(false);
      }
    };

    reader.readAsText(file);
  };

  return (
    <>
      <DialogConfirmDelete
        openModalConfirmDelete={openModalConfirmDelete}
        handleCloseModalConfirmDelete={handleCloseModalConfirmDelete}
        nfCabec={nfCabec}
      />
      <DialogEditNfCabec
        openModalEditNfCabec={openModalEditNfCabec}
        handleCloseModalEditNfCabec={handleCloseModalEditNfCabec}
        nfCabec={nfCabec}
      />
      <Dialog maxWidth={'lg'} open={openModalNF}>
        <Dialog.Title onClose={handleCloseModalNF}>Cadastro de Notas Fiscais</Dialog.Title>
        <Dialog.Content>
          <Box component={Paper} boxShadow={3} p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2} md={2} lg={1} BO>
                <Typography variant='h1' style={{ fontSize: '20px', fontWeight: 'bold' }}>
                  Lote: {linhaInfos.lot_id}
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={4}>
                <Typography variant='h1' style={{ fontSize: '20px', fontWeight: 'bold' }}>
                  Cliente: {linhaInfos.client_name}
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={3}>
                <Typography variant='h1' style={{ fontSize: '20px', fontWeight: 'bold' }}>
                  Status: {linhaInfos.lot_statusLote}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          {tabIndex === 0 && (
            <Box mt={2} component={Paper} boxShadow={3} p={2}>
              <Formik
                initialValues={{
                  nf_view: '',
                  serie: '',
                  key: '',
                  input_data: new Date(),
                  search: '',
                }}
              >
                {(props) => (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={2} lg={2}>
                        <Field
                          size='small'
                          variant='outlined'
                          label='NF'
                          type='text'
                          fullWidth
                          name='nf_view'
                          component={Text}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2} lg={2}>
                        <Field
                          size='small'
                          variant='outlined'
                          label='Serie'
                          type='text'
                          fullWidth
                          name='serie'
                          component={Text}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2} lg={3}>
                        <Field
                          size='small'
                          variant='outlined'
                          label='Chave'
                          type='text'
                          fullWidth
                          name='key'
                          component={Text}
                          onChange={(e) => {
                            let inputValue = e.target.value;
                            if (inputValue.length > 44) {
                              inputValue = inputValue.slice(0, 44);
                            }
                            props.setFieldValue('key', inputValue);
                          }}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2} lg={2}>
                        <Field
                          size='small'
                          variant='inline'
                          fullWidth
                          inputVariant='outlined'
                          label='Data Emissão'
                          name='input_data'
                          component={DatePicker}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2} lg={2}>
                        <Button
                          onClick={() => {
                            if (
                              props.values.nf_view === '' ||
                              props.values.serie === '' ||
                              props.values.key.length !== 44 ||
                              props.values.input_data === ''
                            ) {
                              toast.error('Por favor, preencha todos os campos corretamente');
                            } else {
                              handleRegisterNf(props);
                            }
                          }}
                          fullWidth
                        >
                          Registrar
                        </Button>
                      </Grid>
                      <Grid item xs={12} md={2} lg={1}>
                        <label htmlFor='upload-xml'>
                          <Button component='span' fullWidth startIcon={<UploadIcon />}>
                            XML
                          </Button>
                        </label>
                        <input
                          id='upload-xml'
                          type='file'
                          accept='.xml'
                          onChange={(event) => handleXmlUpload(event, props)}
                          style={{ display: 'none' }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field
                          size='small'
                          name='search'
                          label='Pesquisar'
                          component={Text}
                          variant='outlined'
                          type='text'
                          fullWidth
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <Table name='Table' size='small' headers={columns} disableNumeration>
                          {filteredData
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((item, index) => (
                              <TableRow key={index}>
                                <TableCell>{item.num_invoice}</TableCell>
                                <TableCell>{item.num_serie}</TableCell>
                                <TableCell>{item.num_key}</TableCell>
                                <TableCell>{formatDate(item.dtEmission)}</TableCell>
                                <TableCell>
                                  <IconButton
                                    onClick={() => handleOpenModalEditNfCabec(item)}
                                    color='primary'
                                  >
                                    <ModeEditIcon />
                                  </IconButton>
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    onClick={() => handleOpenModalConfirmDelete(item)}
                                    color='secondary'
                                  >
                                    <HighlightOffIcon />
                                  </IconButton>
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    onClick={() => handleChangeToRegisterItens(item, 1)}
                                    color='primary'
                                  >
                                    <AddCircleIcon />
                                  </IconButton>
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    onClick={() => handleChangeToViewItens(item)}
                                    color='primary'
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                        </Table>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component='div'
                          count={filteredData.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Box>
          )}

          {tabIndex === 1 && <RegisterNewItem line={line} handleBackToNf={handleBackToNf} />}
          {tabIndex === 2 && (
            <ViewItens
              handleBackToNf={handleBackToNf}
              handleChangeToRegisterItens={handleChangeToRegisterItens}
              line={line}
            />
          )}
        </Dialog.Content>
      </Dialog>
    </>
  );
};
