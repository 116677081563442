import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { Button, Dialog, Table } from 'components';
import { newConferenceService } from 'services/newConference';

const columns = [
  { label: 'Nota' },
  { label: 'Série' },
  { label: 'Qtd Total' },
  { label: 'Valor Total' },
];

export const DialogViewNfLote = ({ openDialogViewNfs, handleCloseDialogViewNfs, linhaInfos }) => {
  if (!openDialogViewNfs) return null;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [table, setTable] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedData = table.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleNFsLot = useCallback(async () => {
    try {
      const { data } = await newConferenceService.getNFsLot({ idLot: linhaInfos?.lot_id });
      console.log(data);
      setTable(data);
      if (data.length <= 0) {
        toast.error('Não há itens nessa nota!');
      }
    } catch (error) {
      console.error(error?.response?.data?.message);
    }
  }, [linhaInfos.lot_id]);

  useEffect(() => {
    handleNFsLot();
  }, [handleNFsLot]);

  return (
    <>
      <Dialog open={openDialogViewNfs}>
        <Dialog.Title onClose={handleCloseDialogViewNfs}>
          Notas Fiscais do Lote {linhaInfos.Lote}
        </Dialog.Title>
        <Dialog.Content>
          <Table name='Table' headers={columns} disableNumeration>
            {paginatedData.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.numInvoice}</TableCell>
                <TableCell>{item.numSerie}</TableCell>
                <TableCell>{item.itens}</TableCell>
                <TableCell>{item.total}</TableCell>
              </TableRow>
            ))}
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={table.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage='Itens por página'
          />
        </Dialog.Content>
        <Dialog.Actions>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Button fullWidth onClick={handleCloseDialogViewNfs}>
                Fechar
              </Button>
            </Grid>
          </Grid>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};
