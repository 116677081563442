import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import polyline from '@mapbox/polyline';
import { GoogleMap, LoadScript, Marker, Polyline } from '@react-google-maps/api';
import { googleService } from 'services/google';

export const MapRoutes = ({ origin, destination }) => {
  const [routes, setRoutes] = useState(null);
  const [decodedPath, setDecodedPath] = useState([]);
  const containerStyle = {
    width: '100%',
    height: '400px',
  };
  const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  useEffect(() => {
    if (!origin || !destination) {
      return toast.error('Não há local de origem ou de destino');
    }

    const fetchDirections = async () => {
      try {
        const { data } = await googleService.getDirections({
          origin: origin,
          destination: destination,
          key: key,
        });

        if (data?.routes?.length > 0) {
          setRoutes(data);
          const encodedPath = data?.routes?.[0]?.overview_polyline?.points;
          const path = polyline.decode(encodedPath).map(([lat, lng]) => ({ lat, lng }));
          setDecodedPath(path);
        } else {
          console.error('Erro ao buscar rotas');
          // toast.error('Nenhuma rota encontrada');
        }
      } catch (error) {
        console.error(error?.response?.data?.message || 'Erro ao buscar rotas');
      }
    };

    fetchDirections();
  }, [origin, destination, key]);

  if (!routes) {
    return <p>Carregando rota... </p>;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3>
            <strong>Distância:</strong> {routes?.routes?.[0]?.legs?.[0]?.distance?.text}
          </h3>
        </Grid>
        <Grid item xs={12}>
          <h3>
            <strong>Duração:</strong> {routes?.routes?.[0]?.legs?.[0]?.duration?.text}
          </h3>
        </Grid>

        <LoadScript googleMapsApiKey={key}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            zoom={15}
            center={{
              lat: routes?.routes?.[0]?.legs?.[0]?.start_location?.lat,
              lng: routes?.routes?.[0]?.legs?.[0]?.start_location?.lng,
            }}
          >
            {/* Marcadores para origem e destino */}
            <Marker
              position={{
                lat: routes?.routes?.[0]?.legs?.[0]?.start_location?.lat,
                lng: routes?.routes?.[0]?.legs?.[0]?.start_location?.lng,
              }}
              label='Origem'
            />
            <Marker
              position={{
                lat: routes?.routes?.[0]?.legs?.[0]?.end_location?.lat,
                lng: routes?.routes?.[0]?.legs?.[0]?.end_location?.lng,
              }}
              label='Destino'
            />

            {/* Rota decodificada */}
            <Polyline
              path={decodedPath}
              options={{
                strokeColor: '#512da8',
                strokeWeight: 10,
              }}
            />
          </GoogleMap>
        </LoadScript>
      </Grid>
    </>
  );
};
