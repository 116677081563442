import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import Box from '@mui/material/Box';
import { Button, Dialog, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useContextSelector } from 'use-context-selector';
import * as Yup from 'yup';

import { registerService } from '../../../../../services/register';

const validationSchema = Yup.object().shape({
  input_name: Yup.string().required('Nome é obrigatório'),
  input_cpf_cnpj: Yup.string()
    .required('CPF/CNPJ é obrigatório')
    .matches(/^\d{11}|\d{14}$/, 'CPF deve ter 11 dígitos ou CNPJ 14 dígitos'),
  input_email: Yup.string().email('Email inválido').required('Email é obrigatório'),
  input_IE: Yup.string()
    .matches(/^\d{0,14}$/, 'IE deve ter no máximo 14 dígitos')
    .required('Inscrição Estadual é obrigatória'),
  input_celular: Yup.string()
    .matches(/^\d{10,11}$/, 'Celular deve ter 10 ou 11 dígitos')
    .required('Celular é obrigatório'),
  input_telefone: Yup.string()
    .matches(/^\d{10,11}$/, 'Telefone deve ter 10 ou 11 dígitos')
    .required('Telefone é obrigatório'),
  input_CEP: Yup.string()
    .matches(/^\d{8}$/, 'CEP deve ter 8 dígitos')
    .required('CEP é obrigatório'),
  input_rua: Yup.string().required('Rua é obrigatória'),
  input_numero: Yup.string().required('Número é obrigatório'),
  input_bairro: Yup.string().required('Bairro é obrigatório'),
  input_cidade: Yup.string().required('Cidade é obrigatória'),
  input_estado: Yup.string().required('Estado é obrigatório'),
});

export const DialogEdit = ({ openDialogEdit, handleCloseDialogEdit, line }) => {
  const [isLoading, setIsLoading] = useState(false);
  const user = useContextSelector(AuthContext, (context) => context.user);

  const updateCarrier = async (values) => {
    setIsLoading(true);
    toast.info('Alterando transportadora...');
    try {
      const { data } = await registerService.updateCarrier({
        nome: values.input_name,
        cpf_cnpj: values.input_cpf_cnpj,
        email: values.input_email,
        ie: values.input_IE,
        celular: values.input_celular,
        telefone: values.input_telefone,
        cep: values.input_CEP,
        rua: values.input_rua,
        numero: values.input_numero,
        bairro: values.input_bairro,
        cidade: values.input_cidade,
        estado: values.input_estado,
        complemento: values.input_complemento,
        user: user.id,
        id: line.id,
      });

      toast.success('Transportadora alterada com sucesso!');
      setIsLoading(false);
      await handleCloseDialogEdit();
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };
  return (
    <>
      <Dialog maxWidth='lg' open={openDialogEdit}>
        <Dialog.Title onClose={handleCloseDialogEdit}>Atualizar</Dialog.Title>
        <Dialog.Content>
          <Formik
            initialValues={{
              input_name: line.name,
              input_cpf_cnpj: line.cgc,
              input_email: line.email,
              input_IE: line.ie,
              input_celular: line.cellphone,
              input_telefone: line.phone,
              input_CEP: line.cep,
              input_rua: line.address,
              input_numero: line.address_number,
              input_bairro: line.neighborhood,
              input_cidade: line.city,
              input_estado: line.state,
              input_complemento: line.complement,
            }}
            onSubmit={updateCarrier}
            validationSchema={validationSchema}
          >
            {(props) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={8}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_name'
                      variant='outlined'
                      fullWidth
                      label='Nome'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_cpf_cnpj'
                      variant='outlined'
                      fullWidth
                      label='CNPJ'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_email'
                      variant='outlined'
                      fullWidth
                      label='Email'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_IE'
                      variant='outlined'
                      fullWidth
                      label='IE'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_celular'
                      variant='outlined'
                      fullWidth
                      label='Celular'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_telefone'
                      variant='outlined'
                      fullWidth
                      label='Telefone'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_CEP'
                      variant='outlined'
                      fullWidth
                      label='CEP'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_rua'
                      variant='outlined'
                      fullWidth
                      label='Rua'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_numero'
                      variant='outlined'
                      fullWidth
                      label='Número'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_bairro'
                      variant='outlined'
                      fullWidth
                      label='Bairro'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_cidade'
                      variant='outlined'
                      fullWidth
                      label='Cidade'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_estado'
                      variant='outlined'
                      fullWidth
                      label='Estado'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_complemento'
                      variant='outlined'
                      fullWidth
                      label='Complemento'
                      component={Text}
                    />
                  </Grid>
                </Grid>
                <Box mt={2}>
                  <Grid item xs={3} md={3} lg={3}>
                    <Button type='submit' fullWidth loading={isLoading}>
                      Alterar
                    </Button>
                  </Grid>
                </Box>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
