import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog } from 'components';
import { inHomeService } from 'services';

export const DialogConfirmDesactive = ({
  openDialogConfirmDesactive,
  handleCloseDialogConfirmDesactive,
  line,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDesactive = async () => {
    setIsLoading(true);
    try {
      const { data } = await inHomeService.desactiveTechnician({ id: line.id });
      toast.success('Técnico desativado com sucesso.');
      handleCloseDialogConfirmDesactive();
      setIsLoading(false);
    } catch (error) {
      console.error(error?.response?.data?.message || error.message);
      toast.error(error?.response?.data?.message || 'Erro ao desativar técnico');
      setIsLoading(false);
    }
  };
  return (
    <>
      <Dialog open={openDialogConfirmDesactive}>
        <Dialog.Title onClose={handleCloseDialogConfirmDesactive}>Desativar Técnico</Dialog.Title>
        <Dialog.Content>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                align='center'
                variant='h1'
                style={{ fontSize: '22px', fontWeight: 'bold' }}
              >
                Deseja desativar o técnico {line?.user_id?.name} ?
              </Typography>
            </Grid>
          </Grid>
        </Dialog.Content>
        <Dialog.Actions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                fullWidth
                onClick={handleCloseDialogConfirmDesactive}
                color='secondary'
                loading={isLoading}
              >
                Fechar
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button onClick={handleDesactive} fullWidth loading={isLoading}>
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};
