import { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';

import Box from '@mui/material/Box';
import { Button, DatePicker, Select, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { stockService } from 'services';
import * as Yup from 'yup';

import { parseXmlToJson } from '../../../../../../helpers/readXml';
import { DialogDeleteItem } from './Dialog/DialogDeleteItem';
import { DialogFinished } from './Dialog/DialogFinished';

const columns = [
  { label: 'Item' },
  { label: 'Produto' },
  { label: 'Descrição' },
  { label: 'Produto XML' },
  { label: 'Cadastrada' },
  { label: 'Valor' },
  { label: 'QTD' },
  { label: 'Total' },
  { label: 'Excluir' },
];

const options = [
  { label: 'Supply', value: 'Supply' },
  { label: 'Recebimento de Peça', value: 'Recebimento de Peça' },
];

const validationSchema = Yup.object().shape({
  processo_r: Yup.string().required('Processo é obrigatório'),
});

const validationItem = Yup.object().shape({
  partnumber: Yup.string().required('Partnumber é obrigatório'),
  partnumber_xml: Yup.string().required('Partnumber XML é obrigatório'),
  descricao: Yup.string().required('Descricao é obrigatório'),
  valor: Yup.string().required('Valor é obrigatório'),
  quantidade: Yup.number().required('Quantidade é obrigatório'),
  valor_total: Yup.number().required('Valor total é obrigatório'),
});

export const RegisterNfEntryPart = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [infoItem, setInfoItem] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isXmlImported, setIsXmlImported] = useState(false);
  const [openDialogFinished, setOpenDialogFinished] = useState(false);
  const [openDialogDeleteItem, setOpenDialogDeleteItem] = useState(false);
  const [line, setLine] = useState([]);
  const [newItensNF, setNewItensNF] = useState([]);
  const [newDataNF, setNewDatNF] = useState([]);
  const processRef = useRef(null);
  const [isProcessR, setIsProcessR] = useState(false);

  const handleXmlUpload = async (event, props) => {
    const file = event.target.files[0];
    if (!file) {
      toast.error('Nenhum arquivo selecionado.');
      return;
    }
    const reader = new FileReader();

    reader.onload = async (e) => {
      const xmlString = e.target.result;
      const json = parseXmlToJson(xmlString);
      setIsLoading(true);

      try {
        const { data } = await stockService.entryXML({ json });
        setInfoItem(data);
        const newDateEmissao = new Date(data[0]?.dataEmissao);
        const newDateRecebimento = new Date(data[0]?.dtRecebimento);
        const formatDateEmissao = newDateEmissao;
        const formateDateRecebimento = newDateRecebimento;
        props.setFieldValue('fornecedor', data[0]?.fornecedor || '');
        props.setFieldValue('nota', data[0]?.nota || '');
        props.setFieldValue('serie', data[0]?.serie || '');
        props.setFieldValue('chave', data[0]?.chave || '');
        props.setFieldValue('data_emissao', formatDateEmissao || '');
        props.setFieldValue('data_recebimento', formateDateRecebimento || '');

        toast.success('XML enviado com sucesso!');
        setIsXmlImported(true);
      } catch (error) {
        toast.error('Erro ao enviar XML');
        console.error(error?.response?.data?.message);
        setIsXmlImported(false);
      } finally {
        setIsLoading(false);
      }
    };

    reader.readAsText(file);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const insertItem = async (props) => {
    setIsLoading(true);
    try {
      const { data } = await stockService.checkPartnumber({ partnumber: props.values.partnumber });
      const lastItem = infoItem.length > 0 ? Math.max(...infoItem.map((item) => item.item)) : 0;
      const newItemNumber = lastItem + 1;
      const existParts = data;
      const newItem = {
        item: newItemNumber,
        codProd: props.values.partnumber,
        partnumber_xml: props.values.partnumber_xml,
        prodDescription: props.values.descricao,
        valorUnitario: props.values.valor,
        quantidade: props.values.quantidade,
        valorProd: props.values.valor_total,
        existParts: existParts,
      };
      setInfoItem([...infoItem, newItem]);
      setNewItensNF(newItem);

      props.resetForm({
        values: {
          partnumber: '',
          partnumber_xml: '',
          descricao: '',
          valor: '',
          quantidade: '',
          valor_total: '',
        },
      });
    } catch (error) {
      toast.error('Erro ao inserir item ');
      console.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const handleOpenDialogFinished = () => {
    setOpenDialogFinished(true);
    setNewDatNF(processRef.current.values.processo_r);
  };

  const handleCloseDialogFinished = () => {
    setOpenDialogFinished(false);
  };

  const handleOpenDialogDeleteItem = (item) => {
    setOpenDialogDeleteItem(true);
    setLine(item);
  };

  const handleCloseDialogDeleteItem = () => {
    setOpenDialogDeleteItem(false);
  };

  const onDeleteItem = async (item) => {
    const newInfoItem = infoItem.filter((i) => i.item !== item.item);
    setInfoItem(newInfoItem);
  };

  return (
    <>
      <DialogFinished
        handleCloseDialogFinished={handleCloseDialogFinished}
        openDialogFinished={openDialogFinished}
        newItensNF={newItensNF}
        infoItem={infoItem}
        newDataNF={newDataNF}
      />
      <DialogDeleteItem
        handleCloseDialogDeleteItem={handleCloseDialogDeleteItem}
        openDialogDeleteItem={openDialogDeleteItem}
        line={line}
        onDeleteItem={onDeleteItem}
      />
      <Formik
        initialValues={{
          fornecedor: '',
          nota: '',
          serie: '',
          chave: '',
          processo_r: '',
          data_emissao: new Date(),
          data_recebimento: new Date(),
        }}
        validationSchema={validationSchema}
        innerRef={processRef}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={4} lg={4}>
                <Field
                  size='small'
                  name='fornecedor'
                  variant='outlined'
                  fullWidth
                  label='Fornecedor'
                  component={Text}
                  disabled
                />
              </Grid>
              <Grid item xs={2} lg={2}>
                <Field
                  size='small'
                  name='nota'
                  variant='outlined'
                  fullWidth
                  label='Nota'
                  component={Text}
                  disabled
                />
              </Grid>
              <Grid item xs={2} lg={2}>
                <Field
                  size='small'
                  name='serie'
                  variant='outlined'
                  fullWidth
                  label='Serie'
                  component={Text}
                  disabled
                />
              </Grid>
              <Grid item xs={3} lg={3}>
                <Field
                  size='small'
                  name='chave'
                  variant='outlined'
                  fullWidth
                  label='Chave'
                  component={Text}
                  disabled
                />
              </Grid>
              <Grid item xs={2} lg={2}>
                <Field
                  size='small'
                  name='processo_r'
                  variant='outlined'
                  fullWidth
                  label='Processo R'
                  component={Select}
                  options={options}
                  onChange={(value) => setIsProcessR(value)}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  name='data_emissao'
                  variant='inline'
                  inputVariant='outlined'
                  label='Data Emissão'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  name='data_recebimento'
                  variant='inline'
                  inputVariant='outlined'
                  label='Data Recebimento'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={2} md={2} lg={2}>
                <label htmlFor='upload-xml'>
                  <Button fullWidth component='span'>
                    XML
                  </Button>
                </label>
                <input
                  id='upload-xml'
                  type='file'
                  accept='.xml'
                  onChange={(event) => handleXmlUpload(event, props)}
                  style={{ display: 'none' }}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      <Formik
        initialValues={{
          partnumber: '',
          partnumber_xml: '',
          descricao: '',
          valor: '',
          quantidade: '',
          valor_total: '',
        }}
        validationSchema={validationItem}
      >
        {(props) => (
          <Form>
            <Box mt={4}>
              <PageTitle>Inserir Item</PageTitle>
              <Grid container spacing={2}>
                <Grid item xs={2} lg={2}>
                  <Field
                    size='small'
                    name='partnumber'
                    variant='outlined'
                    fullWidth
                    label='PartNumber'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={2} lg={2}>
                  <Field
                    size='small'
                    name='partnumber_xml'
                    variant='outlined'
                    fullWidth
                    label='PartNumber XML'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={4} lg={4}>
                  <Field
                    size='small'
                    name='descricao'
                    variant='outlined'
                    fullWidth
                    label='Descrição'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={1} lg={1}>
                  <Field
                    size='small'
                    name='valor'
                    variant='outlined'
                    fullWidth
                    label='Valor'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={1} lg={1}>
                  <Field
                    size='small'
                    name='quantidade'
                    variant='outlined'
                    fullWidth
                    label='QTD'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={2} lg={2}>
                  <Field
                    size='small'
                    name='valor_total'
                    variant='outlined'
                    fullWidth
                    label='Valor Total'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={2} md={2} lg={2}>
                  <Button
                    onClick={() => insertItem(props)}
                    fullWidth
                    disabled={!props.dirty || !props.isValid || !isXmlImported}
                  >
                    Inserir Item
                  </Button>
                </Grid>
                <Grid item xs={2} md={2} lg={2}>
                  <Button
                    color='secondary'
                    fullWidth
                    disabled={!isXmlImported || !isProcessR}
                    onClick={() => handleOpenDialogFinished(props)}
                  >
                    Finalizar Nota
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box mt={2}>
              <Grid container>
                <Grid xs={12} md={12} lg={12}>
                  <Table name='Table' headers={columns} maxHeight disableNumeration>
                    {infoItem
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.item}</TableCell>
                          <TableCell>{item.codProd}</TableCell>
                          <TableCell>{item.prodDescription}</TableCell>
                          <TableCell>{item.codProd}</TableCell>
                          <TableCell
                            align='start'
                            style={{
                              color: item.existParts === 'Não' ? 'red' : '#07BC0C',
                            }}
                          >
                            {item.existParts}
                          </TableCell>
                          <TableCell>R$ {item.valorUnitario}</TableCell>
                          <TableCell>{item.quantidade}</TableCell>
                          <TableCell>R$ {item.valorProd}</TableCell>
                          <TableCell align='start'>
                            <DeleteIcon
                              style={{ color: '#f50057', cursor: 'pointer' }}
                              onClick={() => handleOpenDialogDeleteItem(item)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </Table>
                  <TablePagination
                    component='div'
                    count={infoItem.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage='Itens por página'
                  />
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
