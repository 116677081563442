import { useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog, Mask, Text } from 'components';
import { Field, Formik } from 'formik';

import { DialogSignaturesReturn } from '../DialogSignaturesReturn';

export const DialogConfirmReturn = ({ open, onClose, line }) => {
  if (!open) return null;

  const [observacao, setObservacao] = useState('');
  const [openDialogSignaturesReturn, setOpenDialogSignaturesReturn] = useState(false);

  const handleFinishedService = async () => {
    try {
      if (observacao === '') {
        return toast.error('Por favor, adicione a observação do atendimento.');
      }
      handleOpenDialogSignaturesReturn();
    } catch (error) {
      console.error(error?.response?.data?.message || error?.message);
      toast.error('Erro ao finalizar entrega');
    }
  };

  const handleOpenDialogSignaturesReturn = (item) => {
    setOpenDialogSignaturesReturn(true);
  };
  const handleCloseDialogSignaturesReturn = () => {
    setOpenDialogSignaturesReturn(false);
    onClose();
  };
  return (
    <>
      <DialogSignaturesReturn
        open={openDialogSignaturesReturn}
        onClose={handleCloseDialogSignaturesReturn}
        line={line}
        observacao={observacao}
      />
      <Dialog maxWidth={'md'} open={open}>
        <Dialog.Title onClose={onClose}>Confirmar devolução</Dialog.Title>
        <Dialog.Content>
          <Box boxShadow={2} p={3} mb={2} borderRadius={4}>
            <Typography variant='h6' align='center' gutterBottom>
              <strong>Informações do Cliente</strong>
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>CPF/CNPJ: </strong>
                  <Mask type='document'>{line?.client_cgc}</Mask>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>Nome: </strong> {line.client}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>Email: </strong> {line.email}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>Número: </strong>
                  <Mask type='phone'>{line.client_cellphone}</Mask>
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box boxShadow={2} p={3} mb={2} borderRadius={4}>
            <Typography variant='h6' align='center' gutterBottom>
              <strong>Endereço</strong>
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>CEP: </strong> {line.zipcode}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>Rua: </strong> {line.street}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>Número: </strong> {line.number}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>Cidade: </strong> {line.city}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box boxShadow={2} p={3} mb={2} align='center' borderRadius={4}>
            <Typography variant='h6' gutterBottom>
              <strong>Produto</strong>
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>Descrição: </strong> {line.description_repair_product}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box boxShadow={2} p={3} mb={2} align='center' borderRadius={4}>
            <Typography variant='h6' gutterBottom>
              <strong>Observação</strong>
            </Typography>
            <Formik initialValues={{}}>
              {(props) => (
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Field
                      name='relato'
                      label='Observação'
                      component={Text}
                      variant='outlined'
                      size='small'
                      multiline
                      minRows={5}
                      onBlur={() => setObservacao(props.values.relato)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Button onClick={() => handleFinishedService()} fullWidth>
                      Finalizar
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Formik>
          </Box>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
