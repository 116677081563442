import { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CircleIcon from '@mui/icons-material/Circle';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { PageTitle } from 'pages/Layout/PageTitle';
import { inHomeService } from 'services';
import { useContextSelector } from 'use-context-selector';

import { DialogAddParts } from './DialogAddParts';

const columns = [
  { label: 'Protocol', align: 'center' },
  { label: 'Cliente', align: 'center' },
  { label: 'Técnico', align: 'center' },
  { label: 'Tem peça', align: 'center' },
  { label: 'Adicionar', align: 'center' },
];

export const StockInHome = () => {
  const user = useContextSelector(AuthContext, (context) => context.user);
  const [table, setTable] = useState([]);
  const [parts, setParts] = useState([]);
  const [line, setLine] = useState();
  const [openDialogParts, setOpenDialogParts] = useState(false);

  // Estados para paginação e busca
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchValue, setSearchValue] = useState(''); // Novo estado para o valor de busca

  const fetchData = async () => {
    const { data } = await inHomeService.findServices();
    const partsArray = [];

    for (let i = 0; i < data.length; i++) {
      const { data: partsData } = await inHomeService.findPartByService({ service_id: data[i].id });
      partsArray.push({ id: data[i].id, parts: partsData });
    }

    setParts(partsArray);
    setTable(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Funções para tratar paginação
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDialogAddPart = (line) => {
    setLine(line);
    setOpenDialogParts(true);
  };

  const handleCloneDialogAddPart = () => {
    setOpenDialogParts(false);
    fetchData();
  };

  // Função que verifica se existe peça para o serviço
  const existPart = (service_id) => {
    const servicePart = parts.find((part) => part.id === service_id);
    return servicePart && servicePart.parts.length > 0;
  };

  // Função para filtrar os dados da tabela com base na busca
  const filteredTable = table.filter((item) => {
    const searchText = searchValue.toLowerCase();
    return (
      item.protocol.toLowerCase().includes(searchText) ||
      item.cliente.toLowerCase().includes(searchText) ||
      item.tecnico.toLowerCase().includes(searchText)
    );
  });

  const handleGenerateDocument = async () => {
    try {
      const { data } = await inHomeService.getPartsOfDay();
      console.log(data);
      const blob = new Blob([data], { type: 'application/pdf' });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `minuta-pecas.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Erro ao gerar o documento:', error);
    }
  };

  return (
    <>
      <DialogAddParts open={openDialogParts} onClose={handleCloneDialogAddPart} line={line} />
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <PageTitle>Estoque In Home</PageTitle>
        </Grid>
        <Grid item xs={2}>
          <Button onClick={handleGenerateDocument} fullWidth startIcon={<DownloadForOfflineIcon />}>
            Minuta do Dia
          </Button>
        </Grid>
      </Grid>

      <Formik initialValues={{ search: '' }} onSubmit={(values) => setSearchValue(values.search)}>
        {(props) => (
          <Form>
            <Box mt={2} boxShadow={2} p={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    name='search'
                    label='Pesquisar'
                    size='small'
                    fullWidth
                    component={Text}
                    variant='outlined'
                    onChange={(e) => setSearchValue(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mt={2} boxShadow={2} p={2}>
              <Grid item xs={12}>
                <Table name='table' size='small' headers={columns} disableNumeration>
                  {filteredTable
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                      <TableRow key={index}>
                        <TableCell align='center'>{item.protocol}</TableCell>
                        <TableCell align='center'>{item.cliente}</TableCell>
                        <TableCell align='center'>{item.tecnico}</TableCell>
                        <TableCell align='center'>
                          {existPart(item.id) ? (
                            <CircleIcon style={{ color: 'green' }} />
                          ) : (
                            <CircleIcon style={{ color: 'red' }} />
                          )}
                        </TableCell>
                        <TableCell align='center'>
                          <IconButton>
                            <AddCircleOutlineIcon
                              onClick={() => handleOpenDialogAddPart(item)}
                              style={{ color: '#512da8' }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </Table>
                <TablePagination
                  component='div'
                  count={filteredTable.length} // Atualizado para contar os resultados filtrados
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
