import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, Dialog, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { inHomeService } from 'services';
import { useContextSelector } from 'use-context-selector';

export const DialogAssignBackupProduct = ({ open, onClose, line }) => {
  const user = useContextSelector(AuthContext, (context) => context.user);

  const handleAssignBackupProduct = async (values) => {
    try {
      const { data } = await inHomeService.assignBackupProduct({
        idBackup: line.id,
        protocol: line.protocol,
        description: values.description,
        serial: values.serial,
        user: user.id,
      });
      toast.success('Produto de backup atribuido com sucesso!');
      onClose();
    } catch (error) {
      console.error(error?.response?.data?.message || error?.message);
      toast.error(error?.response?.data?.message || 'Erro ao atribuir produto');
    }
  };
  return (
    <>
      <Dialog maxWidth={'md'} open={open}>
        <Dialog.Title onClose={onClose}>Atribuir Produto de Bakcup</Dialog.Title>
        <Dialog.Content>
          <Formik
            initialValues={{ description: '', serial: '' }}
            onSubmit={handleAssignBackupProduct}
          >
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={5} md={5} lg={5}>
                    <Field
                      name='description'
                      label='Produto'
                      size='small'
                      component={Text}
                      variant='outlined'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4} md={4} lg={4}>
                    <Field
                      name='serial'
                      label='Serial'
                      size='small'
                      component={Text}
                      variant='outlined'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3} md={3} lg={3}>
                    <Button type='submit' fullWidth>
                      Atribuir
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
