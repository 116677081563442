import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Dialog, SignaturePad } from 'components';
import { AuthContext } from 'hooks/useAuth';
import { inHomeService } from 'services';
import { useContextSelector } from 'use-context-selector';

export const DialogSignatures = ({
  open,
  onClose,
  photos,
  line,
  realRelato,
  backup,
  repair,
  withDefect,
}) => {
  if (!open) return null;
  const canvasRef = useRef(null);
  const [signatureType, setSignatureType] = useState('client');
  const [clientSignature, setClientSignature] = useState(null);
  const [technicianSignature, setTechnicianSignature] = useState(null);
  const user = useContextSelector(AuthContext, (context) => context.user);
  const url = '/satisfaction-search';
  const fullUrl = `${window.location.origin}${url}`;

  useEffect(() => {
    if (technicianSignature) {
      const downloadPdf = async () => {
        const convertPhoto1 = await convertBlobToBase64(photos.photo1);
        const convertPhoto2 = await convertBlobToBase64(photos.photo2);
        const convertPhoto3 = await convertBlobToBase64(photos.photo3);
        const convertPhoto4 = await convertBlobToBase64(photos.photo4);
        try {
          const { data } = await inHomeService.finishedService({
            infoService: line,
            clientSignature: clientSignature,
            technicianSignature: technicianSignature,
            photo1: convertPhoto1,
            photo2: convertPhoto2,
            photo3: convertPhoto3,
            photo4: convertPhoto4,
            realRelato: realRelato,
            tec: user.name,
            clientUrl: fullUrl,
            backup,
          });
          const blob = new Blob([data], { type: 'application/pdf' });

          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Laudo-${line.protocol}.pdf`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url);

          // Enviar o PDF para o backend (AWS)
          await uploadPdfToAws(blob);
          await handleCheckOut();

          //Finaliza o antendimento
          await inHomeService.finishedStatusService({
            service_id: line.service_id,
            previously_status_id: line.status_id,
            protocol: line.protocol,
            user: user.id,
            realRelato: realRelato,
            backup,
            product_repair: line.pdv_product,
            serial_repair: line.serial,
            repair,
            withDefect,
          });
          toast.success('Atendimento finalizado com sucesso!');
          onClose();
        } catch (error) {
          console.error(
            'Erro ao chamar finalizar atendimento',
            error?.response || error?.message || error,
          );
          toast.error('Erro ao chamar finalizar atendimento!');
        }
      };

      const uploadPdfToAws = async (pdfBlob) => {
        const base64Pdf = await convertBlobToBase64(pdfBlob);

        // Preparar o objeto com a string base64
        const payload = {
          pdfLaudo: base64Pdf.split(',')[1],
          fileName: `Laudo-${line.protocol}.pdf`,
          infoService: line,
        };
        try {
          const response = await inHomeService.uploadImagesAws(payload);
        } catch (error) {
          console.error('Error uploading PDF:', error);
          toast.error('Erro ao subir laudo na nuvem!');
        }
      };
      downloadPdf();
    }
    // eslint-disable-next-line
  }, [
    technicianSignature,
    clientSignature,
    fullUrl,
    onClose,
    photos.photo1,
    photos.photo2,
    photos.photo3,
    photos.photo4,
    realRelato,
    withDefect,
    repair,
    backup,
    user.id,
    user.name,
    line,
  ]);

  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      // Verificar se blob é um objeto do tipo Blob
      if (!(blob instanceof Blob)) {
        resolve(null); // Retorna null se não for um Blob
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const handleSaveImage = async (blob) => {
    if (signatureType === 'client') {
      const base64ClientSignature = await convertBlobToBase64(blob);
      setClientSignature(base64ClientSignature);
      setSignatureType('technician');
    } else {
      const base64TechnicianSignature = await convertBlobToBase64(blob);
      setTechnicianSignature(base64TechnicianSignature);
    }
  };

  const handleCheckOut = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          const address = await getAddressFromCoordinates(latitude, longitude);
          try {
            const { data } = await inHomeService.checkOut({
              service_id: line.service_id,
              address: address,
              user: user.id,
            });
            toast.success('Check Out Realizado!');
          } catch (error) {
            toast.error(error?.response?.data?.message || 'Erro ao realizar Check Out');
            console.error(error?.response?.data?.message || error.message);
          }
        },
        (error) => {
          console.error('Erro ao buscar geolocalização:', error.message);
        },
      );
    } else {
      console.error('Geolocalização não suportada pelo navegador.');
    }
  };

  const getAddressFromCoordinates = async (latitude, longitude) => {
    const url = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data && data.address) {
        return `${data.address.suburb}, ${data.address.city}, ${data.address.country}, ${data.address.postcode}`;
      } else {
        console.error('Não foi possível obter o endereço.');
        return null;
      }
    } catch (error) {
      console.error('Erro na requisição Nominatim:', error);
      return null;
    }
  };

  return (
    <Dialog fullScreen open={open}>
      <Dialog.Title onClose={onClose}>Assinaturas</Dialog.Title>
      <Dialog.Content>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h6' gutterBottom>
              {signatureType === 'client' ? 'Assinatura do Cliente' : 'Assinatura do Técnico'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SignaturePad ref={canvasRef} onSaveImage={handleSaveImage} />
          </Grid>
        </Grid>
      </Dialog.Content>
    </Dialog>
  );
};
