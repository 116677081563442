import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Button, Dialog, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { formatDate } from 'helpers/formateDate';
import { AuthContext } from 'hooks/useAuth';
import { inHomeService } from 'services';
import { useContextSelector } from 'use-context-selector';

const columns = [
  { label: 'Part Number', align: 'center' },
  { label: 'Descrição', align: 'center' },
  { label: 'Quantidade', align: 'center' },
  { label: 'Utilizado', align: 'center' },
  { label: 'Remover', align: 'center' },
];
export const DialogStock = ({ open, onClose, selectedEvent }) => {
  if (!open) return null;

  const user = useContextSelector(AuthContext, (context) => context.user);

  const [parts, setParts] = useState([]);
  const [isToday, setIsToday] = useState(false);

  const fetchData = useCallback(async () => {
    const { data } = await inHomeService.findPartByService({ service_id: selectedEvent.id });
    setParts(data);
  }, [selectedEvent.id]);

  const checkDates = useCallback(async () => {
    const todayFormatted = await formatDate(new Date());
    const dateScheduleFormatted = await formatDate(
      new Date(selectedEvent._def.extendedProps.dateSchedule),
    );
    setIsToday(todayFormatted === dateScheduleFormatted);
  }, [selectedEvent._def.extendedProps.dateSchedule]);

  useEffect(() => {
    fetchData();
    checkDates();
  }, [fetchData, checkDates]);

  useEffect(() => {
    checkDates();
  }, []);

  const toTrim = (e) => {
    e.target.value = ('' + e.target.value).trim();
  };

  const handleRegisterPart = async (values) => {
    try {
      const { data } = await inHomeService.registerPartInHomeService({
        partnumber: values.partnumber,
        description: values.description,
        amount: values.amount,
        user: user.id,
        service_id: selectedEvent.id,
        previously_id: selectedEvent._def.extendedProps.status_id,
      });
      toast.success('Peça adicionada com sucesso.');
      fetchData();
    } catch (error) {
      console.error(error?.response?.data?.message || error.message);
      toast.error(error?.response?.data?.message || 'Erro ao adicionar peça');
    }
  };
  const handleDeletePart = async (item) => {
    try {
      const { data } = await inHomeService.deletePartInHomeService({ idPart: item.id });
      toast.success('Peça removida com sucesso.');
      fetchData();
    } catch (error) {
      console.error(error?.response?.data?.message || error.message);
      toast.error(error?.response?.data?.message || 'Erro ao remover peça');
    }
  };

  console.log(selectedEvent);
  return (
    <>
      <Dialog open={open} maxWidth={'lg'}>
        <Dialog.Title onClose={onClose}>Adicionar peças</Dialog.Title>
        <Dialog.Content>
          <Formik
            initialValues={{ partnumber: '', description: '', amount: '' }}
            onSubmit={handleRegisterPart}
          >
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {/* Exibe Typography se a data do agendamento for hoje */}
                    {isToday && (
                      <Typography style={{ color: '#Ff0000' }}>
                        Hoje é a data desse atendimento. As peças não poderão ser removidas!
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name='partnumber'
                      label='Partnumber'
                      component={Text}
                      variant='outlined'
                      size='small'
                      required
                      onInput={toTrim}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      name='description'
                      label='Descrição'
                      component={Text}
                      variant='outlined'
                      size='small'
                      required
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Field
                      name='amount'
                      label='Qtd'
                      component={Text}
                      type='number'
                      variant='outlined'
                      size='small'
                      required
                      onInput={toTrim}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Button type='submit' fullWidth>
                      Adicionar
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Table
                      emptyMessage='Nenhuma peça adicionada'
                      headers={columns}
                      disableNumeration
                    >
                      {parts.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell align='center'>{item.part_number}</TableCell>
                          <TableCell align='center'>{item.description}</TableCell>
                          <TableCell align='center'>{item.amount}</TableCell>
                          <TableCell align='center'>
                            {!item?.amount_used ? 0 : item.amount_used}
                          </TableCell>
                          <TableCell align='center'>
                            <IconButton disabled={isToday}>
                              <RemoveCircleOutlineIcon
                                onClick={() => handleDeletePart(item)}
                                style={{ color: '#c51162' }}
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </Table>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
