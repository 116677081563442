import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog } from 'components';
import { AuthContext } from 'hooks/useAuth';
import { newConferenceService } from 'services/newConference';
import { useContextSelector } from 'use-context-selector';

export const DialogConfirmDeleteItem = ({
  openModalConfirmDeleteItem,
  handleCloseModalConfirmDeleteItem,
  lineItem,
}) => {
  if (!openModalConfirmDeleteItem) return null;
  const user = useContextSelector(AuthContext, (context) => context.user);

  const handleDeleteItemNF = async () => {
    try {
      const { data } = await newConferenceService.deleteItemNF({ id: lineItem.id, user: user.id });
      toast.success('Item excluida com sucesso!');
      handleCloseModalConfirmDeleteItem();
    } catch (error) {
      console.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Dialog open={openModalConfirmDeleteItem}>
        <Dialog.Title onClose={handleCloseModalConfirmDeleteItem}>Confirmar exclusão</Dialog.Title>
        <Dialog.Content>
          <Typography variant='h1' style={{ fontSize: '20px', fontWeight: 'bold' }} align='center'>
            Realmente deseja excuir esse item?
          </Typography>
        </Dialog.Content>
        <Dialog.Actions>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Button
                onClick={() => handleCloseModalConfirmDeleteItem()}
                color='secondary'
                fullWidth
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Button fullWidth onClick={handleDeleteItemNF}>
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};
