import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog } from 'components';
import { AuthContext } from 'hooks/useAuth';
import { newConferenceService } from 'services/newConference';
import { useContextSelector } from 'use-context-selector';

export const DialogConfirmDelete = ({
  openModalConfirmDelete,
  handleCloseModalConfirmDelete,
  nfCabec,
}) => {
  if (!openModalConfirmDelete) return null;
  const user = useContextSelector(AuthContext, (context) => context.user);

  const handleDeleteNF = async () => {
    try {
      const { data } = await newConferenceService.deleteNF({ id: nfCabec.id, user: user.id });
      toast.success('NF excluida com sucesso!');
      handleCloseModalConfirmDelete();
    } catch (error) {
      console.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Dialog open={openModalConfirmDelete}>
        <Dialog.Title onClose={handleCloseModalConfirmDelete}>Confirmar exclusão</Dialog.Title>
        <Dialog.Content>
          <Typography variant='h1' style={{ fontSize: '20px', fontWeight: 'bold' }} align='center'>
            Realmente deseja excuir a NF: {nfCabec.num_invoice ?? ''} ?
          </Typography>
        </Dialog.Content>
        <Dialog.Actions>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Button onClick={() => handleCloseModalConfirmDelete()} color='secondary' fullWidth>
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Button onClick={handleDeleteNF} fullWidth>
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};
