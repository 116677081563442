/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import { Button, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { PageTitle } from 'pages/Layout/PageTitle';
import { registerService } from 'services/register';
import { useContextSelector } from 'use-context-selector';
import * as Yup from 'yup';

import { DialogActiveOrDesactive } from './Dialog/DialogActiveOrDesactive';
import { DialogEdit } from './Dialog/DialogEdit';

const validationSchema = Yup.object().shape({
  input_name: Yup.string().required('O campo Sub Defeito é obrigatório'),
});

const columns = [{ label: 'SubDefeito' }, { label: 'Alterar' }, { label: 'Ativar/Desativar' }];

export const RegisterSubPartsCategory = () => {
  const user = useContextSelector(AuthContext, (context) => context.user);

  const [isLoading, setIsLoading] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [openDialogDesactiveActive, setOpenDialogDesactiveActive] = useState(false);
  const [line, setLine] = useState();
  const [table, setTable] = useState([]);

  // get data

  const fetchData = async () => {
    const { data } = await registerService.findSubDefect();
    setTable(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // register sub  defect
  const handleSubRegisterDefect = async (values, { resetForm }) => {
    setIsLoading(true);
    toast.info('Registrando sub defeito...');

    const subDefect = values.input_name.trim();

    try {
      const { data } = await registerService.createSubDefect({
        subDefect: subDefect,
        user: user.id,
      });
      toast.success('Sub Defeito registrado com sucesso!');
      setIsLoading(false);
      fetchData();
      resetForm();
    } catch (error) {
      console.error(error?.response?.data?.message || error?.message);
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  // Open Dialogs

  const handleOpenDialogEdit = (item) => {
    setLine(item);
    setOpenDialogEdit(true);
  };
  const handleCloseDialogEdit = () => {
    fetchData();
    setOpenDialogEdit(false);
  };

  const handleOpenDialogDesactiveActive = (item) => {
    setLine(item);
    setOpenDialogDesactiveActive(true);
  };
  const handleCloseDialogDesactiveActive = () => {
    fetchData();
    setOpenDialogDesactiveActive(false);
  };

  return (
    <>
      <DialogActiveOrDesactive
        openDialogDesactiveActive={openDialogDesactiveActive}
        handleCloseDialogDesactiveActive={handleCloseDialogDesactiveActive}
        line={line}
      />
      <DialogEdit
        openDialogEdit={openDialogEdit}
        handleCloseDialogEdit={handleCloseDialogEdit}
        line={line}
      />
      <PageTitle>Cadastro de Sub Defeito</PageTitle>

      <Formik
        initialValues={{ input_name: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubRegisterDefect}
      >
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={5}>
              <Field
                size='small'
                loading={isLoading}
                name='input_name'
                variant='outlined'
                fullWidth
                label='SubCategoria'
                component={Text}
              />
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
              <Button type='submit' fullWidth loading={isLoading}>
                Cadastrar
              </Button>
            </Grid>
          </Grid>
          <Box mt={2}>
            <Table name='Table' headers={columns} maxHeight disableNumeration>
              {table &&
                table.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.subDefect}</TableCell>
                    <TableCell>
                      <EditIcon
                        onClick={() => handleOpenDialogEdit(item)}
                        style={{ color: '#512da8', cursor: 'pointer' }}
                      />
                    </TableCell>
                    <TableCell>
                      <Grid container>
                        <Grid item xs={12} md={3}>
                          {(item.ativo === true && (
                            <Button
                              onClick={() => handleOpenDialogDesactiveActive(item)}
                              fullWidth
                              color='secondary'
                            >
                              Desativar
                            </Button>
                          )) || (
                              <Button onClick={() => handleOpenDialogDesactiveActive(item)} fullWidth>
                                Ativar
                              </Button>
                            )}
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
            </Table>
          </Box>
        </Form>
      </Formik>
    </>
  );
};
