import * as yup from 'yup';

const validationSchema = yup.object().shape({
  input_tec: yup.object().nullable().required('Selecione um técnico.'),
  cellphone: yup.string().required('O campo celular é obrigatório.'),
  jornada: yup.string().required('A jornada é obrigatória.'),
  hours_extra: yup.string().required('Selecione se o técnico tem horas extras.'),
  type_tec: yup.string().required('O Tipo de Técnico é obrigatório'),
  type_products: yup.array().when('type_tec', {
    is: 'Motorista', // Se type_tec for 'Motorista'
    then: yup.array().notRequired(), // type_products não é obrigatório
    otherwise: yup
      .array()
      .min(1, 'Selecione pelo menos um tipo de produto que o técnico repara.')
      .required('Selecione o tipo de produto que o técnico repara.'), // Caso contrário, é obrigatório
  }),
});

export default validationSchema;
