import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog } from 'components';
import { AuthContext } from 'hooks/useAuth';
import { stockService } from 'services';
import { useContextSelector } from 'use-context-selector';

export const DialogFinished = ({
  openDialogFinished,
  handleCloseDialogFinished,
  newItensNF,
  infoItem,
  newDataNF,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const user = useContextSelector(AuthContext, (context) => context.user);
  console.log('newItensNF', newItensNF);
  console.log('infoItem', infoItem);
  console.log('newDataNF', newDataNF);

  const processFineshedNote = async () => {
    setIsLoading(true);
    try {
      const { data } = await stockService.processFinishedNote({
        nota: infoItem,
        newDataNF: newDataNF,
        user: user.id,
      });
      console.log('data', data);
      if (data.status === 200) {
        toast.success('Nota finalizada com sucesso!');
        setIsLoading(false);
        await handleCloseDialogFinished();
        window.location.reload();
      } else {
        toast.error('Erro ao finalizar nota');
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Dialog open={openDialogFinished}>
        <Dialog.Title onClose={handleCloseDialogFinished}>Finalização da nota</Dialog.Title>
        <Dialog.Content>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                align='center'
                variant='h2'
                style={{ fontSize: '24px', fontWeight: 'bold', paddingBottom: '1rem' }}
              >
                Deseja realmente finalizar a nota?
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Button
                color='secondary'
                fullWidth
                loading={isLoading}
                onClick={handleCloseDialogFinished}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Button fullWidth type='submit' loading={isLoading} onClick={processFineshedNote}>
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
