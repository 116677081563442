import { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import EditIcon from '@mui/icons-material/Edit';
import { Button, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { registerService } from 'services/register';

import { DialogConfirm } from './Dialog/DialogConfirm';
import { DialogEditParts } from './Dialog/DialogEditParts';
import { DialogRegisterParts } from './Dialog/DialogRegisterParts';

const columns = [
  { label: 'Produto' },
  { label: 'Tipo Produto' },
  { label: 'Modelo' },
  { label: 'Editar' },
  { label: 'Ativar/Desativar' },
];

export const RegisterParts = () => {
  const [openDialogRegister, setOpenDialogRegister] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [line, setLine] = useState('');
  const [table, setTable] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchValue, setSearchValue] = useState('');

  const handleOpenDialogRegister = () => {
    setOpenDialogRegister(true);
  };

  const handleCloseDialogRegister = () => {
    setOpenDialogRegister(false);
    fetchData();
  };

  const handleOpenDialogEdit = (item) => {
    setOpenDialogEdit(true);
    setLine(item);
  };

  const handleCloseDialogEdit = () => {
    setOpenDialogEdit(false);
    fetchData();
  };

  const handleOpenDialogConfirm = (item) => {
    setOpenDialogConfirm(true);
    setLine(item);
  };

  const handleCloseDialogConfirm = () => {
    setOpenDialogConfirm(false);
    fetchData();
  };

  const fetchData = async () => {
    const { data } = await registerService.findParts();
    setTable(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [searchValue]);

  return (
    <>
      <DialogConfirm
        handleCloseDialogConfirm={handleCloseDialogConfirm}
        openDialogConfirm={openDialogConfirm}
        line={line}
      />
      <DialogRegisterParts
        openDialogRegister={openDialogRegister}
        handleCloseDialogRegister={handleCloseDialogRegister}
      />

      <DialogEditParts
        openDialogEdit={openDialogEdit}
        handleCloseDialogEdit={handleCloseDialogEdit}
        line={line}
      />
      <PageTitle>Cadastro de Peças</PageTitle>

      <Formik initialValues={{ search: '' }}>
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={3} lg={3}>
                <Button onClick={handleOpenDialogRegister} fullWidth>
                  Cadastrar
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Field
                  size='small'
                  name='search'
                  variant='outlined'
                  fullWidth
                  label='Pesquisar'
                  component={Text}
                  onChange={(event) => setSearchValue(event.target.value.toLowerCase())}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Table name='Table' size='small' headers={columns} disableNumeration>
                  {table &&
                    table
                      .toReversed()
                      .filter(
                        (item) =>
                          item.partnumber.toLowerCase().includes(searchValue) ||
                          item.description.toLowerCase().includes(searchValue) ||
                          item.model.toLowerCase().includes(searchValue),
                      )
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.partnumber}</TableCell>
                          <TableCell>{item.description}</TableCell>
                          <TableCell>{item.model ? item.model : 'Não Resgitrado'}</TableCell>
                          <TableCell>
                            <IconButton
                              disabled={!item.active}
                              onClick={() => handleOpenDialogEdit(item)}
                            >
                              <EditIcon style={{ color: '#512da8', cursor: 'pointer' }} />
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            {(item.active === true && (
                              <Grid container>
                                <Grid item xs={12} md={4}>
                                  <Button
                                    color='secondary'
                                    fullWidth
                                    onClick={() => handleOpenDialogConfirm(item)}
                                  >
                                    Desativar
                                  </Button>
                                </Grid>
                              </Grid>
                            )) ||
                              (item.active === false && (
                                <Grid container>
                                  <Grid item xs={12} md={4}>
                                    <Button fullWidth onClick={() => handleOpenDialogConfirm(item)}>
                                      Ativar
                                    </Button>
                                  </Grid>
                                </Grid>
                              ))}
                          </TableCell>
                        </TableRow>
                      ))}
                </Table>
                <TablePagination
                  component='div'
                  count={table.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
