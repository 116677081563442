import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Button, DatePicker, Mask } from 'components';
import formatter from 'date-fns/format';
import { Field, Form, Formik } from 'formik';
import { inHomeService } from 'services';

import { DialogAddres } from '../../TechincianCalendar/TechnicianScreen/DialogAddress/index';
import { DialogConfirmReturn } from './Dialogs/DialogConfirmReturn';
import { DialogSchedulling } from './Dialogs/DialogSchedulling';

export const DriverScreen = ({ user }) => {
  const [collapsed, setCollapsed] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openAddress, setOpenAddress] = useState(false);
  const [openDialogScheduling, setOpenDialogScheduling] = useState(false);
  const [openDialogConfirmReturn, setOpenDialogConfirmReturn] = useState(false);
  const [dateChoosed, setDateChoosed] = useState();
  const [line, setLine] = useState();
  const [service, setService] = useState();

  const fetchData = useCallback(
    async (date) => {
      try {
        const { data } = await inHomeService.findServicesInBackup({
          status: '',
          protocol: '',
          date_scheduling_backup: date ? date : new Date(),
        });

        const optionsServices = (data || [])?.map((a) => ({
          value: a.id,
          service_id: a.service_id,
          ativo: a.active,
          driver_id: a.status_id === 6 ? a.driver_return_backup : a.driver_id,
          date: a.status_id === 6 ? a.date_return_backup : a.date_scheduling_backup,
          protocol: a.protocol,
          client: a.name,
          client_cellphone: a.client_cellphone,
          check_in_driver: a.status_id === 6 ? 'entrega' : a.check_in_driver,
          street: a.street,
          number: a.number,
          neighborhood: a.neighborhood,
          city: a.city,
          state: a.state,
          email: a.email,
          client_cgc: a.cgc,
          zipcode: a.zipcode,
          comment: a.comment,
          date_Scheduling: a.date_scheduling_backup,
          group_prod: a.group_prod,
          serial: a.serial,
          status_id: a.status_id,
          description_repair_product: a.description_repair_product,
          description_backup_product: a.description_backup_product,
          service_status: a.service_status,
        }));

        const servicesForDriver = optionsServices.filter(
          (item) => item.driver_id === user && item.status_id !== 4,
        );
        console.log('optionsServices', optionsServices);
        setService(servicesForDriver);
      } catch (error) {
        console.error('Error fetching services:', error?.response?.data?.message || error.message);
        toast.error('Não há atendimentos nessa data');
        setService([]);
      }
    },
    [user],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const toggleCollapse = (index) => {
    setCollapsed((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = formatter(date, 'dd/MM/yyyy HH:mm');
    return `${formattedDate}`;
  };

  const handleCheckIn = async (item) => {
    setIsLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          const address = await getAddressFromCoordinates(latitude, longitude);
          try {
            const { data } = await inHomeService.checkInDriver({
              service_id: item.service_id,
              address: address,
              user: user,
            });
            toast.success('Check In Realizado!');
            setIsLoading(false);
            fetchData(dateChoosed || new Date());
          } catch (error) {
            toast.error(error?.response?.data?.message || 'Erro ao realizar Check In');
            console.error(error?.response?.data?.message || error.message);
            setIsLoading(false);
          }
        },
        (error) => {
          console.error('Erro ao buscar geolocalização:', error.message);
          setIsLoading(false);
        },
      );
    } else {
      console.error('Geolocalização não suportada pelo navegador.');
      setIsLoading(false);
    }
  };

  const getAddressFromCoordinates = async (latitude, longitude) => {
    const url = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data && data.address) {
        return `${data.address.suburb}, ${data.address.city}, ${data.address.country}, ${data.address.postcode}`;
      } else {
        console.error('Não foi possível obter o endereço.');
        return null;
      }
    } catch (error) {
      console.error('Erro na requisição Nominatim:', error);
      return null;
    }
  };

  const handleOpenAddress = async (line) => {
    setLine(line);
    setOpenAddress(true);
  };

  const HandleOpenDialogSchedulling = (line) => {
    setLine(line);
    setOpenDialogScheduling(true);
  };
  const HandleCloseDialogSchedulling = () => {
    fetchData(dateChoosed);
    setOpenDialogScheduling(false);
  };

  const HandleOpenDialogConfirmReturn = (line) => {
    setLine(line);
    setOpenDialogConfirmReturn(true);
  };
  const HandleCloseDialogConfirmReturn = () => {
    fetchData(dateChoosed);
    setOpenDialogConfirmReturn(false);
  };
  return (
    <>
      <DialogConfirmReturn
        open={openDialogConfirmReturn}
        onClose={HandleCloseDialogConfirmReturn}
        line={line}
      />
      <DialogSchedulling
        open={openDialogScheduling}
        onClose={HandleCloseDialogSchedulling}
        line={line}
      />
      <DialogAddres open={openAddress} onClose={() => setOpenAddress(false)} line={line} />
      <Formik
        initialValues={{ date_service: new Date() }}
        onSubmit={(values) => {
          fetchData(values.date_service);
        }}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <Field
              name='date_service'
              label='Data do atendimento'
              component={DatePicker}
              size='small'
              variant='inline'
              fullWidth
              inputVariant='outlined'
              value={values.date_service}
              onChange={(date) => {
                setFieldValue('date_service', date);
                setDateChoosed(date);
                fetchData(date);
              }}
            />
          </Form>
        )}
      </Formik>
      <br />
      <Grid container spacing={2}>
        {service &&
          service.map((item, index) => (
            <>
              <Grid item xs={12}>
                <React.Fragment key={index}>
                  <Grid item xs={12}>
                    <Button fullWidth>
                      <Grid container spacing={1} direction='row'>
                        <Grid item xs={5} sm={3} style={{ alignSelf: 'center' }}>
                          {`${formatDate(item?.date)}`}
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          sm={2}
                          style={{ alignSelf: 'center', marginRight: '12%' }}
                        >
                          {`${item?.protocol}`}
                        </Grid>
                        <Grid item xs={2} sm={2} style={{ alignSelf: 'center' }}>
                          {item.status_id === 6 ? 'DEVOLUÇÃO' : 'RETIRADA'}
                        </Grid>
                      </Grid>

                      <IconButton
                        style={{ color: '#fff' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleCollapse(index);
                        }}
                      >
                        {collapsed[index] ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                      </IconButton>
                    </Button>
                    <Grid item xs={12}>
                      <Collapse in={collapsed[index]}>
                        <Box p={2} border={1} borderColor='grey.400' borderRadius={4}>
                          <Grid container spacing={2}>
                            {/* Protocolo */}
                            <Grid item xs={6}>
                              <Typography>
                                <strong>Protocolo:</strong>
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography>{item.protocol}</Typography>
                            </Grid>

                            {/* Cliente */}
                            <Grid item xs={6}>
                              <Typography>
                                <strong>Cliente:</strong>
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography>{item.client}</Typography>
                            </Grid>

                            {/* Celular */}
                            <Grid item xs={6}>
                              <Typography>
                                <strong>Celular:</strong>
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography>
                                <Mask type='phone'>{item.client_cellphone}</Mask>
                              </Typography>
                            </Grid>

                            {/* Endereço */}
                            <Grid item xs={6}>
                              <Typography>
                                <strong>Endereço:</strong>
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <LocationOnIcon
                                style={{ color: '#512da8' }}
                                onClick={() => handleOpenAddress(item)}
                              />
                            </Grid>
                            {item.status_id !== 6 ? (
                              <>
                                <Grid item xs={6}>
                                  <Typography>
                                    <strong>
                                      Produto a ser <br />
                                      retirado:
                                    </strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  {item.description_repair_product}
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    <strong>
                                      Produto a ser <br />
                                      levado:
                                    </strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  {item.description_backup_product}
                                </Grid>
                              </>
                            ) : (
                              <>
                                <Grid item xs={6}>
                                  <Typography>
                                    <strong>
                                      Produto a ser <br />
                                      levado:
                                    </strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  {item.description_repair_product}
                                </Grid>
                              </>
                            )}

                            {item.status_id !== 6 && (
                              <>
                                {/* Detalhes */}
                                <Grid item xs={6}>
                                  <Typography>
                                    <strong>Detalhes:</strong>
                                  </Typography>
                                </Grid>

                                {item.check_in_driver !== null ? (
                                  <>
                                    {item.status_id === 4 ? (
                                      <>
                                        <Grid item xs={6}>
                                          <Typography>
                                            Enviado para pendência de abertura de OS
                                          </Typography>
                                        </Grid>
                                      </>
                                    ) : (
                                      <>
                                        <Grid item xs={6}>
                                          <AddCircleOutlineIcon
                                            style={{ color: '#512da8' }}
                                            onClick={() => HandleOpenDialogSchedulling(item)}
                                          />
                                        </Grid>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <Grid item xs={6}>
                                      <Typography>
                                        <strong>Realize o Check In</strong>
                                      </Typography>
                                    </Grid>
                                  </>
                                )}
                              </>
                            )}
                            {/* Check In */}
                            {item.check_in_driver == null ? (
                              <>
                                <Grid item xs={6}>
                                  <Typography>
                                    <strong>Check In:</strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Button
                                    fullWidth
                                    loading={isLoading}
                                    onClick={() => handleCheckIn(item)}
                                    color='secondary'
                                  >
                                    Check In
                                  </Button>
                                </Grid>
                              </>
                            ) : item.check_in_driver === 'entrega' ? (
                              <>
                                {/* Renderizar algo específico para 'entrega' */}
                                <Grid item xs={6}>
                                  <Typography>
                                    <strong>Devolução do produto</strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Button
                                    onClick={() => HandleOpenDialogConfirmReturn(item)}
                                    color='secondary'
                                  >
                                    Concluir
                                  </Button>
                                </Grid>
                              </>
                            ) : (
                              <>
                                <Grid item xs={6}>
                                  <Typography>
                                    <strong>Check In:</strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>Check In Realizado</Typography>
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </Box>
                      </Collapse>
                    </Grid>
                  </Grid>
                </React.Fragment>
              </Grid>
            </>
          ))}
      </Grid>
    </>
  );
};
