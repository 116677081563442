import { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Table, Text } from 'components';
import formatter from 'date-fns/format';
import { Field, Form, Formik } from 'formik';
import { inHomeService } from 'services';

import { DialogDesactive } from './Dialogs/DialogDesactive';

const columns = [
  { label: 'Protocolo', align: 'center' },
  { label: 'Data de Agendamento', align: 'center' },
  { label: 'Período', align: 'center' },
  { label: 'Cliente', align: 'center' },
  { label: 'Técnico', align: 'center' },
  { label: 'Atendente', align: 'center' },
  { label: 'Cancelar', align: 'center' },
];

export function ServiceTable() {
  const [table, setTable] = useState([]);
  const [openDesactiveService, setOpenDesactiveService] = useState(false);
  const [line, setLine] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');
  const fetchData = async () => {
    const { data } = await inHomeService.findServices();
    setTable(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = formatter(date, 'dd/MM/yyyy HH:mm');
    return `${formattedDate}`;
  };

  const handleOpenDesactiveService = async (item) => {
    setLine(item);
    setOpenDesactiveService(true);
  };

  const handleCloseDesactiveService = async () => {
    fetchData();
    setOpenDesactiveService(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
    setPage(0);
  };

  const filterTable = table.filter((item) => {
    return (
      item.protocol.toLowerCase().includes(searchTerm) ||
      (item.date_Scheduling && formatDate(item.date_Scheduling).includes(searchTerm)) ||
      item.cliente.toLowerCase().includes(searchTerm) ||
      item.tecnico.toLowerCase().includes(searchTerm) ||
      item.atendente.toLowerCase().includes(searchTerm)
    );
  });

  const currentTableData = filterTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <DialogDesactive
        openDesactiveService={openDesactiveService}
        handleCloseDesactiveService={handleCloseDesactiveService}
        line={line}
      />
      <h2>Lista de atendimentos</h2>
      <Box mt={1}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Formik initialValues={{ search: '' }}>
              {(props) => (
                <Form>
                  <Field
                    component={Text}
                    name='search'
                    label='Pesquisar'
                    variant='outlined'
                    size='small'
                    fullWidth
                    onChange={handleSearch}
                  ></Field>
                </Form>
              )}
            </Formik>
          </Grid>
          <Grid item xs={12}>
            <Table
              disableNumeration
              headers={columns}
              data={[]}
              size='small'
              emptyMessage='Nenhum atendimento foi encontrado'
            >
              {currentTableData.map((item, index) => (
                <TableRow key={index}>
                  <TableCell align='center'>{item.protocol}</TableCell>
                  <TableCell align='center'>
                    {item.date_Scheduling ? formatDate(item.date_Scheduling) : 'A definir'}
                  </TableCell>
                  <TableCell align='center'>{item.period_schedule}</TableCell>
                  <TableCell align='center'>{item.cliente}</TableCell>
                  <TableCell align='center'>
                    {item.tecnico === 'Técnico não atribuído' ? (
                      <>Técnico não atribuído</>
                    ) : (
                      item.tecnico
                    )}
                  </TableCell>
                  <TableCell align='center'>{item.atendente}</TableCell>
                  <TableCell align='center'>
                    <IconButton
                      onClick={() => handleOpenDesactiveService(item)}
                      style={{ color: '#f50057' }}
                    >
                      <RemoveCircleIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
            <TablePagination
              component='div'
              count={filterTable.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage='Linhas por página'
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
