import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import DeleteIcon from '@mui/icons-material/Delete';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { Button, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { stockService } from 'services';

import { DialogConference } from './Dialogs/DIalogConferece';
import { DialogDelete } from './Dialogs/DialogDelete';
import { DialogEdit } from './Dialogs/DialogEdit';

const columns = [
  { label: 'Fornecedor' },
  { label: 'Nota' },
  { label: 'Serie' },
  { label: 'Data Recebimento' },
  { label: 'Alterar' },
  { label: 'Conferência' },
  { label: 'Excluir' },
];

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const ConsultNfEntryPart = () => {
  const [openDialogConference, setOpenDialogConference] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [dataNF, setDataNF] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [line, setLine] = useState([]);
  const [filteredDataNF, setFilteredDataNF] = useState([]);

  const handleOpenDialogConference = (item) => {
    setOpenDialogConference(true);
    setLine(item);
  };
  const handleCloseDialogConference = () => {
    setOpenDialogConference(false);
  };

  const handleOpenDialogDelete = (item) => {
    setOpenDialogDelete(true);
    setLine(item);
  };

  const handleCloseDialogDelete = () => {
    setOpenDialogDelete(false);
    handleTableNotes();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDialogEdit = (item) => {
    setOpenDialogEdit(true);
    setLine(item);
  };

  const handleCloseDialogEdit = () => {
    setOpenDialogEdit(false);
  };

  const handleTableNotes = async () => {
    try {
      const { data } = await stockService.getNotas();
      console.log(data);
      setDataNF(data);
      setFilteredDataNF(data);
    } catch (error) {
      console.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleSearch = (searchTerm) => {
    if (searchTerm) {
      const filteredData = dataNF.filter(
        (item) =>
          item.idSupplier.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.note.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.serie.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setFilteredDataNF(filteredData);
    } else {
      setFilteredDataNF(dataNF);
    }
  };

  useEffect(() => {
    handleTableNotes();
  }, []);

  return (
    <>
      <DialogEdit
        handleCloseDialogEdit={handleCloseDialogEdit}
        openDialogEdit={openDialogEdit}
        line={line}
      />
      <DialogConference
        openDialogConference={openDialogConference}
        handleCloseDialogConference={handleCloseDialogConference}
        line={line}
      />
      <DialogDelete
        handleCloseDialogDelete={handleCloseDialogDelete}
        openDialogDelete={openDialogDelete}
        line={line}
      />
      <Formik initialValues={{ search: '' }}>
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  size='small'
                  name='search'
                  variant='outlined'
                  fullWidth
                  label='Pesquisar'
                  component={Text}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Table name='Table' headers={columns} disableNumeration>
                  {filteredDataNF
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.idSupplier}</TableCell>
                        <TableCell>{item.note}</TableCell>
                        <TableCell>{item.serie}</TableCell>
                        <TableCell>{formatDate(item.dtEmission)}</TableCell>
                        <TableCell>
                          <Button onClick={() => handleOpenDialogEdit(item)}>
                            <SyncAltIcon />
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button onClick={() => handleOpenDialogConference(item)}>
                            <PlaylistAddCheckIcon />
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button color='secondary' onClick={() => handleOpenDialogDelete(item)}>
                            <DeleteIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </Table>
                <TablePagination
                  component='div'
                  count={dataNF.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage='Itens por página'
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
