import { useState } from 'react';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Dialog, Text } from 'components';
import { Field, Form, Formik } from 'formik';

export const DialogEdit = ({ openDialogEdit, handleCloseDialogEdit, line }) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <Dialog maxWidth='lg' open={openDialogEdit}>
        <Dialog.Title onClose={handleCloseDialogEdit}>Atualizar NF</Dialog.Title>
        <Dialog.Content>
          <Formik
            initialValues={{
              number_nf: line.note,
              fornecedor: line.idSupplier,
              serie: line.serie,
              processo: line.idTypeProcess,
              qtd: line.qtd_itens,
              data_emissao: line.dtEmission,
              data_recebimento: line.dtReceipt,
            }}
          >
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={2} lg={2} md={2}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='number_nf'
                    variant='outlined'
                    fullWidth
                    label='NF'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={8} lg={8} md={8}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='fornecedor'
                    variant='outlined'
                    fullWidth
                    label='Fornecedor'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={2} lg={2} md={2}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='serie'
                    variant='outlined'
                    fullWidth
                    label='Serie'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={4} lg={4} md={4}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='processo'
                    variant='outlined'
                    fullWidth
                    label='Processo'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={2} lg={2} md={2}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='qtd'
                    variant='outlined'
                    fullWidth
                    label='QTD Itens'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={3} lg={3} md={3}>
                  <Field
                    size='small'
                    name='data_emissao'
                    variant='inline'
                    inputVariant='outlined'
                    label='Data Emissão'
                    component={DatePicker}
                  />
                </Grid>
                <Grid item xs={3} lg={3} md={3}>
                  <Field
                    size='small'
                    name='data_recebimento'
                    variant='inline'
                    inputVariant='outlined'
                    label='Data Recebimento'
                    component={DatePicker}
                  />
                </Grid>
                <Grid item xs={3} lg={3} md={3}>
                  <Button type='submit' color='primary' fullWidth>
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
