import { useCallback } from 'react';
import { Route as PublicRoute, Redirect, Switch } from 'react-router-dom';

import Private from 'pages/Private';
import { NotFound } from 'pages/Private/NotFound';
import { AcessCodeCheck } from 'pages/Public/AcessCode';
import { Changelog } from 'pages/Public/Changelog';
import { CreateAccount } from 'pages/Public/CreateAccount';
import Login from 'pages/Public/Login';
import { PasswordRecover } from 'pages/Public/PasswordRecover';
import { RedefinePassword } from 'pages/Public/RedefinePassword';
import { SatisfactionSearch } from 'pages/Public/SatisfactionSearch';

import { Route } from './Route';

const Routes = () => {
  const renderRedirect = useCallback(() => <Redirect to='/' />, []);

  return (
    <Switch>
      <Route path='/' exact component={Login} />
      <Route path='/acess_code' exact component={AcessCodeCheck} />
      <Route path='/password-recover' component={PasswordRecover} />
      <Route path='/create-account' component={CreateAccount} />
      <Route path='/error-not-found' component={NotFound} />
      <Route path='/satisfaction-search' component={SatisfactionSearch} />
      <PublicRoute path='/changelog' component={Changelog} />
      <PublicRoute path='/password/reset/:refresh_token' component={RedefinePassword} />

      <Private />

      <Route render={renderRedirect} />
    </Switch>
  );
};

export default Routes;
