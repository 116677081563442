import { useCallback, useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import TablePagination from '@mui/material/TablePagination';
import { Dialog, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { newConferenceService } from 'services/newConference';

import { OpenOs } from './DialogOpenOsLote';

const columns = [
  { label: 'Produto Origem' },
  { label: 'Tipo Produto' },
  { label: 'Marca' },
  { label: 'Modelo' },
  { label: 'Qtd NF' },
  { label: 'Qtd OS Abertas' },
  { label: 'Qtd Faltante' },
  { label: 'Nota Fiscal' },
  { label: 'Abrir OS' },
];

export const DialogOpenServiceOrder = ({
  openModalServiceOrder,
  handleCloseDialogOrderService,
  linhaInfos,
}) => {
  if (!openModalServiceOrder) return null;

  const [tabIndex, setTabIndex] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');
  const [table, setTable] = useState([]);
  const [line, setLine] = useState([]);

  console.log('linhaInfos', linhaInfos);

  const handleBack = () => {
    setTabIndex(0);
  };

  const handleChangeToOpenOs = (item) => {
    setLine(item);
    setTabIndex(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  const filteredData = table.filter((item) => {
    return (
      item.Produto?.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
      '' ||
      item.TipoProduto?.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
      '' ||
      item.Marca?.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
      '' ||
      item.Modelo?.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
      '' ||
      item.qtdNf?.toString().includes(searchQuery) ||
      '' ||
      item.qtdOsOpen?.toString().includes(searchQuery) ||
      '' ||
      item.qtdFalt?.toString().includes(searchQuery) ||
      '' ||
      item.NumNF?.toString().includes(searchQuery) ||
      ''
    );
  });

  const handleTable = useCallback(async () => {
    try {
      const { data } = await newConferenceService.getProductsLot({ idLote: linhaInfos.lot_id });
      console.log(data);
      setTable(data);
    } catch (error) {
      console.error(error?.response?.data?.message);
    }
  }, [linhaInfos.lot_id]);

  useEffect(() => {
    handleTable();
  }, [handleTable]);

  return (
    <>
      <Dialog maxWidth={'lg'} open={openModalServiceOrder}>
        <Dialog.Title onClose={handleCloseDialogOrderService}>Abertura de OS</Dialog.Title>
        <Dialog.Content>
          {tabIndex === 0 && (
            <>
              <Box mt={2} component={Paper} boxShadow={3} p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <Typography variant='h1' style={{ fontSize: '20px', fontWeight: 'bold' }}>
                      Lote: {linhaInfos.lot_id}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='h1' style={{ fontSize: '20px', fontWeight: 'bold' }}>
                      Cliente: {linhaInfos.client_name}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={2} component={Paper} boxShadow={3} p={2}>
                <Formik initialValues={{ search: '' }}>
                  {(props) => (
                    <Form>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Field
                            size='small'
                            name='search'
                            label='Pesquisar'
                            component={Text}
                            variant='outlined'
                            type='text'
                            fullWidth
                            value={searchQuery}
                            onChange={handleSearchChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Table name='Table' size='small' headers={columns} disableNumeration>
                            {filteredData
                              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((item, index) => (
                                <TableRow key={index}>
                                  <TableCell>{item.Produto}</TableCell>
                                  <TableCell>{item.TipoProduto}</TableCell>
                                  <TableCell>{item.Marca}</TableCell>
                                  <TableCell>{item.Modelo}</TableCell>
                                  <TableCell>{item.qtd_itens}</TableCell>
                                  <TableCell>{item.qtdOsOpen}</TableCell>
                                  <TableCell>{item.qtdFalt}</TableCell>
                                  <TableCell>{item.NumNF}</TableCell>
                                  <TableCell>
                                    <IconButton color='primary'>
                                      <AddCircleIcon onClick={() => handleChangeToOpenOs(item)} />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </Table>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component='div'
                            count={filteredData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage='Itens por página'
                          />
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Box>
            </>
          )}
          {tabIndex === 1 && (
            <>
              <Box mt={2} component={Paper} boxShadow={3} p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Typography variant='h1' style={{ fontSize: '20px', fontWeight: 'bold' }}>
                      Lote: {linhaInfos.lot_id}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography variant='h1' style={{ fontSize: '20px', fontWeight: 'bold' }}>
                      Cliente: {linhaInfos.client_name}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <OpenOs line={line} handleBack={handleBack} />
            </>
          )}
        </Dialog.Content>
      </Dialog>
    </>
  );
};
