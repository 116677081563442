import { useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { Button, Dialog, Mask, Text } from 'components';
import { Field, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useContextSelector } from 'use-context-selector';

import { DialogSignatures } from '../DialogSignatures';

export const DialogSchedulling = ({ open, onClose, line }) => {
  if (!open) return null;
  const user = useContextSelector(AuthContext, (context) => context.user);

  const [openDialogSignatures, setOpenDialogSignatures] = useState(false);
  const [realRelato, setRealRelato] = useState('');
  const [photos, setPhotos] = useState({
    photo1: null,
    photo2: null,
    photo3: null,
    photo4: null,
  });

  const [previewPhotos, setPreviewPhotos] = useState({
    photo1: null,
    photo2: null,
    photo3: null,
    photo4: null,
  });

  const handleFileChange = (event, photoKey) => {
    const file = event.target.files[0];

    // Verifica se o arquivo é uma imagem
    if (file && !file.type.startsWith('image/')) {
      toast.error('Por favor, selecione apenas arquivos de imagem.');
      return;
    }

    if (file) {
      // Atualizar pré-visualização e o arquivo real
      setPreviewPhotos((prevPhotos) => ({
        ...prevPhotos,
        [photoKey]: URL.createObjectURL(file), // Para pré-visualização
      }));

      setPhotos((prevFiles) => ({
        ...prevFiles,
        [photoKey]: file, // Para upload
      }));
    }
  };

  const handleOpenDialogSingnatures = () => {
    setOpenDialogSignatures(true);
  };

  const handleCloseDialogSignatures = () => {
    setOpenDialogSignatures(false);
    onClose();
  };
  const handleFinishedService = async () => {
    try {
      if (realRelato === '') {
        return toast.error('Por favor, adicione o relato real do atendimento.');
      }
      if (!photos.photo1 || !photos.photo2) {
        return toast.error('Por favor, adicione fotos do atendimento.');
      }

      handleOpenDialogSingnatures();
    } catch (error) {
      console.error(error?.response?.data?.message || error?.message);
      toast.error('Erro');
    }
  };

  return (
    <>
      <DialogSignatures
        open={openDialogSignatures}
        onClose={handleCloseDialogSignatures}
        photos={photos}
        line={line}
        realRelato={realRelato}
      />

      <Dialog maxWidth={'md'} open={open}>
        <Dialog.Title onClose={onClose}>Atendimento {line?.protocol}</Dialog.Title>
        <Dialog.Content>
          <Box boxShadow={2} p={3} mb={2} borderRadius={4} display='flex' flexDirection='column'>
            <Typography variant='h6' gutterBottom>
              <strong>Informações do Cliente</strong>
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>CPF/CNPJ: </strong>
                  <Mask type='document'>{line?.client_cgc}</Mask>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>Nome: </strong> {line.client}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>Email: </strong> {line.email}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>Número: </strong>
                  <Mask type='phone'>{line.client_cellphone}</Mask>
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box boxShadow={2} p={3} mb={2} borderRadius={4} display='flex' flexDirection='column'>
            <Typography variant='h6' gutterBottom>
              <strong>Endereço</strong>
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>CEP: </strong> {line.zipcode}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>Rua: </strong> {line.street}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>Número: </strong> {line.number}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>Cidade: </strong> {line.city}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {/* Seção de Relato */}
          <Box boxShadow={2} p={3} mb={2} borderRadius={4} display='flex' flexDirection='column'>
            <Typography variant='h6' gutterBottom>
              <strong>Relato do cliente</strong>
            </Typography>
            <Box bgcolor='#f5f5f5' borderRadius={4} p={2}>
              <Typography variant='body1'>{line.comment}</Typography>
            </Box>
          </Box>

          {/* Seção de Relato Real */}
          <Box boxShadow={2} p={3} mb={2} borderRadius={4} display='flex' flexDirection='column'>
            <Typography variant='h6' gutterBottom>
              <strong>Relato constatado</strong>
            </Typography>
            <Formik initialValues={{}}>
              {(props) => (
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Field
                      name='relato'
                      label='Relato'
                      component={Text}
                      variant='outlined'
                      size='small'
                      multiline
                      minRows={5}
                      onBlur={() => setRealRelato(props.values.relato)}
                    />
                  </Grid>
                </Grid>
              )}
            </Formik>
          </Box>
          <Box boxShadow={2} p={3} mb={2} align='center' borderRadius={4}>
            <Typography variant='h6' gutterBottom>
              <strong>Produto</strong>
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>Retirado: </strong> {line.description_repair_product}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>Entregue: </strong> {line.description_backup_product}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {/* Seção de Upload de Fotos */}
          <Box boxShadow={2} p={3} borderRadius={4} display='flex' flexDirection='column'>
            <Typography variant='h6' gutterBottom>
              <strong>Fotos</strong>
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Box
                  border='1px dashed grey'
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  p={2}
                  height={200}
                  position='relative'
                >
                  {previewPhotos.photo1 ? (
                    <img
                      src={previewPhotos.photo1}
                      alt='Foto 1'
                      style={{ maxWidth: '100%', maxHeight: '100%' }}
                    />
                  ) : (
                    <Typography variant='body1' align='center'>
                      <CameraAltIcon />
                    </Typography>
                  )}
                  <Input
                    type='file'
                    accept='image/*'
                    onChange={(e) => handleFileChange(e, 'photo1')}
                    style={{ display: 'none' }}
                    id='upload-photo1'
                    capture='environment'
                  />
                  <label htmlFor='upload-photo1'>
                    <Button variant='contained' component='span'>
                      Escolher Foto
                    </Button>
                  </label>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box
                  border='1px dashed grey'
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  p={2}
                  height={200}
                  position='relative'
                >
                  {previewPhotos.photo2 ? (
                    <img
                      src={previewPhotos.photo2}
                      alt='Foto 2'
                      style={{ maxWidth: '100%', maxHeight: '100%' }}
                    />
                  ) : (
                    <CameraAltIcon />
                  )}
                  <Input
                    type='file'
                    accept='image/*'
                    capture='environment'
                    onChange={(e) => handleFileChange(e, 'photo2')}
                    style={{ display: 'none' }}
                    id='upload-photo2'
                  />
                  <label htmlFor='upload-photo2'>
                    <Button variant='contained' component='span'>
                      Escolher Foto
                    </Button>
                  </label>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box
                  border='1px dashed grey'
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  p={2}
                  height={200}
                  position='relative'
                >
                  {previewPhotos.photo3 ? (
                    <img
                      src={previewPhotos.photo3}
                      alt='Foto 3'
                      style={{ maxWidth: '100%', maxHeight: '100%' }}
                    />
                  ) : (
                    <CameraAltIcon />
                  )}
                  <Input
                    type='file'
                    accept='image/*'
                    onChange={(e) => handleFileChange(e, 'photo3')}
                    style={{ display: 'none' }}
                    id='upload-photo3'
                    capture='environment'
                  />
                  <label htmlFor='upload-photo3'>
                    <Button variant='contained' component='span'>
                      Escolher Foto
                    </Button>
                  </label>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box
                  border='1px dashed grey'
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  p={2}
                  height={200}
                  position='relative'
                >
                  {previewPhotos.photo4 ? (
                    <img
                      src={previewPhotos.photo4}
                      alt='Foto 4'
                      style={{ maxWidth: '100%', maxHeight: '100%' }}
                    />
                  ) : (
                    <CameraAltIcon />
                  )}
                  <Input
                    type='file'
                    accept='image/*'
                    onChange={(e) => handleFileChange(e, 'photo4')}
                    style={{ display: 'none' }}
                    id='upload-photo4'
                    capture='environment'
                  />
                  <label htmlFor='upload-photo4'>
                    <Button variant='contained' component='span'>
                      Escolher Foto
                    </Button>
                  </label>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button onClick={() => handleFinishedService()} fullWidth>
                  Finalizar
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
