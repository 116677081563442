import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { inHomeService } from 'services';
import { useContextSelector } from 'use-context-selector';

export const DialogDesactive = ({ openDesactiveService, handleCloseDesactiveService, line }) => {
  const user = useContextSelector(AuthContext, (context) => context.user);

  const handleDesactive = async (reason_cancel) => {
    try {
      const { data } = await inHomeService.desactiveService({
        id: line.id,
        user: user.id,
        previously_id: line.status_id,
        protocol: line.protocol,
        reason_cancel: reason_cancel,
      });
      toast.success('Atendimento cancelado com sucesso!');
      handleCloseDesactiveService();
    } catch (error) {
      console.log(err?.response?.data?.message || error.message);
      toast.error(err?.response?.data?.message || 'Erro ao salvar dados da nota');
    }
  };
  return (
    <>
      <Dialog open={openDesactiveService}>
        <Dialog.Title onClose={handleCloseDesactiveService}>Cancelar Atendimento</Dialog.Title>
        <Dialog.Content>
          <Formik initialValues={{ reason_cancel: '' }}>
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant='h2'
                      align='center'
                      style={{ fontSize: '25px', fontWeight: 'bold' }}
                    >
                      Realmente deseja cancelar esse atendimento?
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      size='small'
                      // loading={props.isSubmitting || isLoading}
                      name='reason_cancel'
                      variant='outlined'
                      fullWidth
                      label='Motivo Cancelamento'
                      component={Text}
                      multiline
                      minRows={5}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button fullWidth color='secondary' onClick={handleCloseDesactiveService}>
                      Não
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      onClick={() => handleDesactive(props.values.reason_cancel)}
                      disabled={(!props.isValid, !props.dirty)}
                    >
                      Sim
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
