import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { Select, Table } from 'components';
import formatter from 'date-fns/format';
import { Field, Form, Formik } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { inHomeService } from 'services';

const columns = [
  { label: 'Protocolo' },
  { label: 'Cliente' },
  { label: 'Técnico' },
  { label: 'Data do atendimento' },
  { label: 'Status' },
];

export const LeaderScreen = () => {
  const [servicesTec, setServicesTec] = useState([]);
  const [tecs, , ,] = useQuery(() => inHomeService.findInHomeTec(), []);

  const optionsTecs = (tecs || [])?.map((a) => ({
    value: a.user_id.id,
    label: a.user_id.name,
    ativo: a.active,
  }));

  const handleGetServiceByTec = async (tec) => {
    const { data } = await inHomeService.findServicesByTec({ id_tec: tec });
    if (data.length === 0) {
      setServicesTec([]);
      return toast.error('Nenhum serviço encontrado para o técnico selecionado.');
    }
    setServicesTec(data);
    try {
    } catch (error) {
      console.error(error?.response?.data?.message || error?.message);
      toast.error(error?.response?.data?.message || 'Erro ao buscar atendimentos do técnico');
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = formatter(date, 'dd/MM/yyyy HH:mm');
    return `${formattedDate}`;
  };

  return (
    <>
      <Formik initialValues={{ technnician: '' }}>
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Field
                  name='technnician'
                  label='Técnico'
                  size='small'
                  variant='outlined'
                  component={Select}
                  options={optionsTecs.filter((item) => item.ativo)}
                  onChange={(e) => handleGetServiceByTec(e)}
                />
              </Grid>
              <Grid item xs={12}>
                <Table name='table' headers={columns} disableNumeration>
                  {servicesTec.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.protocol}</TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.tec}</TableCell>
                      <TableCell>{formatDate(item.date_Scheduling)}</TableCell>
                      <TableCell>{item.status}</TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
