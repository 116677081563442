import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, Dialog, Select, Text, Time } from 'components';
import { Field, Form, Formik } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { inHomeService } from 'services';

import validationSchema from './validationSchema';

export const DialogEditTechnician = ({
  openDialogEditTechnician,
  handleCloseDialogEditTechnician,
  line,
}) => {
  if (!openDialogEditTechnician) return null;
  const optionsExtra = [
    { label: 'Sim', value: 'Sim' },
    { label: 'Não', value: 'Não' },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [typeProducts, setTypeProducts] = useState([]);

  const handleUpdateTechnician = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await inHomeService.updateTechnician({
        id: line.id,
        jornada: values.jornada,
        extra: values.hours_extra,
        cellphone: values.cellphone,
        typeProducts: values.type_products,
        tec: line.user_id.id,
      });
      toast.success('Técnico atualizado com sucesso.');
      handleCloseDialogEditTechnician();
      setIsLoading(false);
    } catch (error) {
      console.error(error?.response?.data?.message || error?.message);
      toast.error(error?.response?.data?.message || 'Erro ao atualizar técnico');
      setIsLoading(false);
    }
  };

  const [vincProdTec, , ,] = useQuery(
    () => inHomeService.findVincsProdTecById({ id: line.user_id.id }),
    [],
  );

  const optionsVincProdTec = (vincProdTec || [])?.map((a) => ({
    value: a.id,
    label: a.product,
    ativo: a.active,
  }));
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await inHomeService.findTypeProductsPdv();
      setTypeProducts(data);
    };
    fetchData();
  }, []);

  const initialTypeProduct = optionsVincProdTec.filter((item) =>
    typeProducts.some((product) => product.label === item.label),
  );

  return (
    <>
      <Dialog maxWidth={'md'} open={openDialogEditTechnician}>
        <Dialog.Title onClose={handleCloseDialogEditTechnician}>Editar Técnico</Dialog.Title>
        <Dialog.Content>
          <Formik
            initialValues={{
              technician: line?.user_id?.name,
              hours_extra: line.extra,
              cellphone: line?.cellphone,
              jornada: line?.journey,
              type_products: initialTypeProduct.map((item) => item.label),
            }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleUpdateTechnician}
          >
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Field
                      size='small'
                      name='technician'
                      label='Técnico'
                      component={Text}
                      variant='outlined'
                      type='text'
                      fullWidth
                      loading={isLoading}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      size='small'
                      name='cellphone'
                      label='Celular'
                      component={Text}
                      variant='outlined'
                      type='text'
                      fullWidth
                      mask='phone'
                      loading={isLoading}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Field
                      size='small'
                      label='Tipo de produto'
                      name='type_products'
                      component={Select}
                      variant='outlined'
                      options={typeProducts}
                      type='text'
                      fullWidth
                      loading={isLoading}
                      multiple
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Field
                      size='small'
                      name='jornada'
                      variant='outlined'
                      fullWidth
                      inputVariant='outlined'
                      label='Jornada'
                      component={Time}
                      defaultValue='00:00'
                      InputLabelProps={{ shrink: true }}
                      loading={isLoading}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      size='small'
                      name='hours_extra'
                      label='Extra'
                      component={Select}
                      variant='outlined'
                      options={optionsExtra}
                      type='text'
                      fullWidth
                      loading={isLoading}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      type='submit'
                      endIcon={<CheckCircleIcon />}
                      fullWidth
                      disabled={(!props.isValid, !props.dirty)}
                      loading={isLoading}
                    >
                      Alterar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
