import { useState } from 'react';

import Grid from '@material-ui/core/Grid';

import { Box, Tab, Tabs } from '@mui/material';
import { Button, Dialog } from 'components';

import { InitalEdit } from './StepsEdit/DescriptionAndPn';
import { VincDefects } from './StepsEdit/VincDefects';
import { VincSubDefeitos } from './StepsEdit/VincSubDefeitos';
import { VincTypeProducts } from './StepsEdit/VincTypeProducts';

export const DialogEditParts = ({ openDialogEdit, handleCloseDialogEdit, line }) => {
  if (!openDialogEdit) return null;
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Dialog maxWidth={'md'} open={openDialogEdit}>
      <Dialog.Title onClose={handleCloseDialogEdit}>Editar Peça</Dialog.Title>
      <Dialog.Content>
        <Box sx={{ width: '100%' }}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label='dialog tabs'
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: '#512da8',
              },
              '& .MuiTab-root': {
                color: '#000',
              },
              '& .Mui-selected': {
                color: '#512da8',
              },
            }}
          >
            <Tab label='Informações Gerais' />
            <Tab label='Vinc Tipos de Produto' />
            <Tab label='Vinc Defeitos' />
            <Tab label='Vinc Sub Defeitos' />
          </Tabs>
          <Box sx={{ p: 3 }}>
            {tabIndex === 0 && (
              <InitalEdit line={line} handleCloseDialogEdit={handleCloseDialogEdit} />
            )}
            {tabIndex === 1 && <VincTypeProducts line={line} />}
            {tabIndex === 2 && <VincDefects line={line} />}
            {tabIndex === 3 && <VincSubDefeitos line={line} />}
          </Box>
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Button fullWidth color='secondary' onClick={handleCloseDialogEdit}>
                  Fechar
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Dialog.Content>
    </Dialog>
  );
};
