/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */

import Grid from '@material-ui/core/Grid';

import { Button, Select, Spacer, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { UseDebounce } from 'hooks/useDebounce';
import { PageTitle } from 'pages/Layout/PageTitle';
import { receiptService } from 'services';

import { TableCollect } from './tableCollect';

const initialValues = {
  serial: '',
};

const options = [
  { value: false, label: 'Não coletado' },
  { value: true, label: 'Coletado' },
];

const CollectSerialFromPdv = () => {
  const { debounce } = UseDebounce();

  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isCollect, setIsCollect] = useState(false);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    debounce(() => {
      setIsLoading(true);
      receiptService
        .listPdvReturnSeriais(isCollect, page, rowsPerPage)
        .then((result) => {
          setRows(result);
        })
        .catch((error) => {
          console.log(error);
          toast.error(error?.response?.data?.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  }, [isCollect, page, rowsPerPage]);

  const onSubmitHandler = async (values, { setSubmitting, setFieldValue }) => {
    setIsLoading(true);
    toast.info('Coletando serial...');
    try {

      const { os } = await receiptService.collectSerialPdv(values.serial);
      toast.success(`OS ${os} aberta com sucesso!`);
      toast.info(`Gerando etiqueta!`);

      const { data } = await receiptService.createEtiquetaServiceOrder(os);
      const blob = new Blob([data], { type: 'application/pdf' });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Etiqueta.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);


      const result = await receiptService.listPdvReturnSeriais(isCollect, page, rowsPerPage);
      setRows(result);
      setFieldValue('serial', '');
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    } finally {
      setSubmitting(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Coletar seriais PDV</PageTitle>
      <Formik initialValues={initialValues} onSubmit={onSubmitHandler}>
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={8} xl={9}>
                <Field
                  name='serial'
                  label='Serial/IMEI'
                  size='small'
                  variant='outlined'
                  value={props.values.serial}
                  component={Text}
                  disabled={isLoading || isCollect}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <Button fullWidth disabled={isLoading || isCollect} type='submit'>
                  Coletar
                </Button>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Field
                  name='isCollect'
                  label='Coletado'
                  size='small'
                  variant='outlined'
                  value={isCollect}
                  component={Select}
                  disabled={isLoading}
                  options={options}
                  onChange={(value) => setIsCollect(value)}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      <Spacer size={10} />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TableCollect
            rows={rows}
            isLoading={isLoading}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </>
  );
};

export { CollectSerialFromPdv };

