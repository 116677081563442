import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, Dialog, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useContextSelector } from 'use-context-selector';
import * as Yup from 'yup';

import { registerService } from '../../../../../services/register';

const validationSchema = Yup.object().shape({
  input_sub_defect: Yup.string().required('O campo Sub Defeito é obrigatório'),
  input_observation: Yup.string().required('O campo Observação é obrigatório'),
});

export const DialogEdit = ({ openDialogEdit, handleCloseDialogEdit, line }) => {
  const user = useContextSelector(AuthContext, (context) => context.user);

  const [isLoading, setIsLoading] = useState(false);

  const handleUpdateDefect = async (values) => {
    setIsLoading(true);
    toast.info('Atualizando defeito...');

    const subDefect = values.input_sub_defect.trim();
    try {
      const { data } = await registerService.updateSubDefect({
        subDefect: subDefect,
        id: line.id,
        obs: values.input_observation,
        user: user.id,
      });
      toast.success('Sub Defeito atualizado com sucesso');
      handleCloseDialogEdit();
      setIsLoading(false);
    } catch (error) {
      console.error(error?.response?.data?.message || error?.message);
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };
  return (
    <>
      <Dialog open={openDialogEdit}>
        <Dialog.Title onClose={handleCloseDialogEdit}>Editar</Dialog.Title>
        <Dialog.Content>
          <Formik
            initialValues={{ input_sub_defect: '' }}
            onSubmit={handleUpdateDefect}
            validationSchema={validationSchema}
          >
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_sub_defect'
                      variant='outlined'
                      fullWidth
                      label='Sub Defeito'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_observation'
                      variant='outlined'
                      fullWidth
                      label='Observação'
                      component={Text}
                      multiline
                      minRows={5}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Button type='submit' fullWidth loading={isLoading}>
                      Atualizar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
