import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  signaturePad: {
    border: '1px solid #ddd',
    position: 'relative',
  },
  canvas: {
    display: 'block',
    backgroundColor: '#fff',
  },
  signaturePadButtons: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '10px',
  },
  lightCanvas: {
    backgroundColor: '#f5f5f5',
  },
  darkCanvas: {
    backgroundColor: '#333',
  },
  button: {
    marginRight: '10px',
    padding: '5px 10px',
    fontSize: '14px',
    cursor: 'pointer',
  },
  clearBtn: {
    backgroundColor: '#f50057',
    color: 'white',
    borderRadius: '5px',
    width: '8rem',
    height: '3rem',
  },
  saveBtn: {
    backgroundColor: '#512da8',
    color: 'white',
    borderRadius: '5px',
    width: '8rem',
    height: '3rem',
  },
}));
