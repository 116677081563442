import { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Dialog } from 'components';
import { inHomeService } from 'services';

import { MapRoutes } from '../../../../../../components/MapRoutes/indes';

export const DialogLocation = ({ open, onClose, selectedEvent }) => {
  if (!open) return null;

  const [info, setInfo] = useState([]);

  const origin = 'Avenida Liberdade, 4565, Sorocaba';
  const destination =
    info?.[0]?.street +
    ' ' +
    info?.[0]?.neighborhood +
    ' ' +
    info?.[0]?.number +
    ' ' +
    info?.[0]?.city +
    ' ' +
    info?.[0]?.state;

  console.log('destination', destination);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await inHomeService.findInfoAdcByIdService({ service_id: selectedEvent.id });
      setInfo(data);
    };
    if (open) {
      fetchData();
    }
  }, [open, selectedEvent.id]);

  return (
    <>
      <Dialog maxWidth='md' open={open}>
        <Dialog.Title onClose={onClose}>Localização</Dialog.Title>
        <Dialog.Content>
          <Box borderRadius={2} p={2} boxShadow={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h4' align='center'>
                  Informações da rota
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                  Local de Partida: {origin}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                  Local de Destino: {destination}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <MapRoutes origin={origin} destination={destination}></MapRoutes>
              </Grid>
            </Grid>
          </Box>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
