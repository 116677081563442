import { useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Mask, Select, Table } from 'components';
import { Field, Form, Formik } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { inHomeService } from 'services';

import { DialogFinishService } from './Dialogs/DialogFinishService/';

const serviceTypes = [
  { label: 'In Home - Padrão', value: 0 },
  { label: 'In Home - Backup', value: 1 },
];

const columns = [
  { label: 'Protocolo' },
  { label: 'Status' },
  { label: 'Cliente' },
  { label: 'Telefone' },
  { label: 'Finalizar' },
];

export const SacManagement = () => {
  const [valueService, setValueService] = useState();
  const [table, setTable] = useState([]);
  const [line, setLine] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [openFinishService, setOpenFinishService] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Número de linhas por página

  const [statusBackup] = useQuery(() => inHomeService.findStatusBackup(), []);
  const optionsBackup = (statusBackup || []).map((a) => ({
    value: a.id,
    label: a.description,
    ativo: a.active,
  }));

  const [statusInHome] = useQuery(() => inHomeService.findStatusInHome(), []);
  const optionsDefault = (statusInHome || []).map((a) => ({
    value: a.id,
    label: a.description,
    ativo: a.active,
  }));

  const handleClean = (props) => {
    props.resetForm();
    setValueService();
    setTable([]);
  };

  const handleFilterServices = async (props) => {
    setIsLoading(true);
    try {
      const data = await inHomeService.findServicesByStatus({
        status: props?.values?.status || '',
        serviceType: props?.values?.service_type,
      });
      setTable(data);
      setIsLoading(false);
    } catch (error) {
      console.error(error?.message);
      toast.error(error?.response?.data?.message || 'Erro ao filtrar os atendimentos');
      setIsLoading(false);
    }
  };

  const optionsStatus = () => {
    switch (valueService) {
      case 0:
        return optionsDefault;
      case 1:
        return optionsBackup;
      default:
        return [];
    }
  };

  const handleChangeType = (e, props) => {
    setValueService(e);
    props.setFieldValue('status', '');
    setTable([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenFinishService = (item) => {
    setLine(item);
    setOpenFinishService(true);
  };
  const handleCloseFinishService = () => {
    setOpenFinishService(false);
    setTable([]);
  };

  return (
    <>
      <DialogFinishService
        open={openFinishService}
        onClose={handleCloseFinishService}
        line={line}
      />
      <PageTitle>Gerenciamento SAC</PageTitle>

      <Formik initialValues={{ service_type: '', status: '' }}>
        {(props) => (
          <Form>
            <Box mt={2} boxShadow={2} p={2}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Field
                    name='service_type'
                    label='Tipo de Atendimento'
                    component={Select}
                    variant='outlined'
                    options={serviceTypes}
                    onChange={(e) => handleChangeType(e, props)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name='status'
                    label='Status'
                    component={Select}
                    variant='outlined'
                    options={optionsStatus()}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    startIcon={<SearchIcon />}
                    fullWidth
                    onClick={() => handleFilterServices(props)}
                  >
                    Filtrar
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    startIcon={<ClearIcon />}
                    fullWidth
                    color='secondary'
                    onClick={() => handleClean(props)}
                  >
                    Limpar
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box mt={2} boxShadow={2}>
              <Grid item xs={12}>
                <Table
                  size='small'
                  headers={columns}
                  disableNumeration
                  emptyMessage='Não há atendimentos'
                  loading={isLoading}
                >
                  {table
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.protocol}</TableCell>
                        <TableCell>{item.description}</TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>
                          <Mask type='phone'>{item.client_cellphone}</Mask>
                        </TableCell>
                        <TableCell>
                          {item.status_id === 11 && valueService === 0 && (
                            <>
                              <IconButton onClick={() => handleOpenFinishService(item)}>
                                <CheckCircleIcon style={{ color: '#512da8' }} />
                              </IconButton>
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </Table>
                <TablePagination
                  component='div'
                  count={table.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage='Linhas por página'
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
                  }
                />
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
