import { useState } from 'react';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import ContactsIcon from '@mui/icons-material/Contacts';
import { Mask } from 'components';
import { formatDateDirect } from 'helpers/formateDate';

import { DialogTecsByProd } from './Dialogs/DialogTecsByProd';

export const ResumeService = ({ protocolData }) => {
  const [openDialogTecByProd, setOpenDialogTecByProd] = useState(false);
  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  const handleOpenDialogTecByProd = () => {
    setOpenDialogTecByProd(true);
  };
  const handleCloseDialogTecByProd = () => {
    setOpenDialogTecByProd(false);
  };

  return (
    <>
      <DialogTecsByProd
        open={openDialogTecByProd}
        onClose={handleCloseDialogTecByProd}
        info={protocolData}
      />
      <Box m={4}>
        <Typography variant='h4' gutterBottom>
          Resumo do Atendimento
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography variant='h6'>
              <strong>Protocolo: </strong> {protocolData.protocol}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h6'>
              <strong>Data do atendimento: </strong>
              {protocolData?.date
                ? formatDate(protocolData.date) + ' as ' + protocolData.time
                : 'A definir' + ' - ' + protocolData.period}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Box display='flex' flexDirection='row' alignItems='center'>
              <Typography variant='h6' style={{ fontSize: '14px', fontWeight: 'bold' }}>
                Técnicos que reparam esse produto
              </Typography>
              <IconButton onClick={() => handleOpenDialogTecByProd()}>
                <ContactsIcon style={{ color: '#512da8', cursor: 'pointer' }} />
              </IconButton>
            </Box>
          </Grid>

          {/* Dados do Cliente */}
          <Grid item xs={4}>
            <Card>
              <CardContent>
                <Typography align='center' variant='h6'>
                  Dados do Cliente
                </Typography>
                <Divider />
                <Box mt={2} p={2} sx={{ backgroundColor: '#f5f5f5', borderRadius: 4 }}>
                  <Typography variant='body1'>
                    <strong>Nome: </strong> {protocolData.dataNf[0].name}
                  </Typography>
                  <Typography variant='body1'>
                    <strong>CPF/CNPJ: </strong>
                    <Mask type='document'>{protocolData.dataNf[0].cgc}</Mask>
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Celular: </strong>
                    <Mask type='phone'>{protocolData.dataNf[0].cellphone}</Mask>
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Email: </strong> {protocolData.dataNf[0].email}
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Estado: </strong> {protocolData.dataNf[0].state}
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Cidade: </strong> {protocolData.dataNf[0].city}
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Rua: </strong> {protocolData.dataNf[0].address}
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Número: </strong> {protocolData.dataNf[0].address_number}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Dados da Nota */}
          <Grid item xs={4}>
            <Card>
              <CardContent>
                <Typography align='center' variant='h6'>
                  Dados da Nota
                </Typography>
                <Divider />
                <Box mt={2} p={2} sx={{ backgroundColor: '#f5f5f5', borderRadius: 4 }}>
                  <Typography align='left' variant='body1'>
                    <strong>Nota: </strong> {protocolData.dataNf[0].numero_nota_fiscal}
                  </Typography>
                  <Typography align='left' variant='body1'>
                    <strong>Série: </strong> {protocolData.dataNf[0].serie}
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Chave:</strong>
                  </Typography>
                  <Typography
                    style={{
                      wordWrap: 'break-word',
                      overflowWrap: 'break-word',
                      whiteSpace: 'normal',
                    }}
                    variant='body1'
                  >
                    {protocolData.dataNf[0].chave_de_acesso}
                  </Typography>
                  <Typography align='left' variant='body1'>
                    <strong>Data de Recebimento: </strong>{' '}
                    {formatDateDirect(protocolData.dataNf[0].created_at)}
                  </Typography>
                  <Typography align='left' variant='body1'>
                    <strong>Produto: </strong>
                  </Typography>
                  <Typography align='left' variant='body1'>
                    {protocolData.dataNf[0].descricao}
                  </Typography>
                  <Typography align='left' variant='body1'>
                    <strong>Serial: </strong>
                  </Typography>
                  <Typography align='left' variant='body1'>
                    {protocolData.dataNf[0].serial}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Observação */}
          <Grid item xs={4}>
            <Card>
              <CardContent>
                <Typography align='center' variant='h6'>
                  Observação
                </Typography>
                <Divider />
                <Box mt={2} p={2} sx={{ backgroundColor: '#f5f5f5', borderRadius: 4 }}>
                  <Typography
                    align='left'
                    variant='body1'
                    style={{
                      whiteSpace: 'pre-wrap', // Garante que as quebras de linha sejam respeitadas
                      wordWrap: 'break-word', // Faz o texto quebrar corretamente
                    }}
                  >
                    {protocolData.obs || 'Sem observações.'}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
