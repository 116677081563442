import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { Table } from 'components';

const columns = [
  { label: 'Serial', align: 'center' },
  { label: 'Defeito', align: 'center' },
  { label: 'User PDV', align: 'center' },
  { label: 'In Home', align: 'center' },
];

const TableCollect = ({
  rows,
  isLoading,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  return (
    <Table
      name='Table'
      headers={columns}
      striped
      loading={isLoading}
      emptyMessage='Nenhum registro encontrado.'
      data={rows}
      page={page}
      rowsPerPage={rowsPerPage}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      disableNumeration
    >
      {rows?.map((item, i) => (
        <TableRow key={i}>
          <TableCell align='center'>{item.serial}</TableCell>
          <TableCell align='center'>{item.defect}</TableCell>
          <TableCell align='center'>{item.pdv_user_name}</TableCell>
          <TableCell align='center'>{item.service_type === 'DOA' ? 'NÃO' : 'SIM'}</TableCell>
        </TableRow>
      ))}
    </Table>
  );
};

export { TableCollect };
