import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Box from '@mui/material/Box';
import { Button, Dialog, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { registerService } from 'services/register';
import { useContextSelector } from 'use-context-selector';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  input_name: Yup.string().required('Nome é obrigatório'),
  input_cpf_cnpj: Yup.string()
    .required('CPF/CNPJ é obrigatório')
    .matches(/^\d{11}|\d{14}$/, 'CPF deve ter 11 dígitos ou CNPJ 14 dígitos'),
  input_email: Yup.string().email('Email inválido').required('Email é obrigatório'),
  input_IE: Yup.string().when('input_cpf_cnpj', {
    is: (value) => value && value.length === 14,
    then: Yup.string()
      .matches(/^\d{0,14}$/, 'IE deve ter no máximo 14 dígitos')
      .required('Inscrição Estadual é obrigatória'),
    otherwise: Yup.string().notRequired(),
  }),
  input_CEP: Yup.string()
    .matches(/^\d{8}$/, 'CEP deve ter 8 dígitos')
    .required('CEP é obrigatório'),
  input_rua: Yup.string().required('Rua é obrigatória'),
  input_numero: Yup.string().required('Número é obrigatório'),
  input_bairro: Yup.string().required('Bairro é obrigatório'),
  input_cidade: Yup.string().required('Cidade é obrigatória'),
  input_estado: Yup.string().required('Estado é obrigatório'),
});

export const DialogEdit = ({ openDialogEdit, handleCloseDialogEdit, line }) => {
  if (!openDialogEdit) return null;

  const user = useContextSelector(AuthContext, (context) => context.user);

  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddress] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await registerService.findCliendAddresById({ id: line.id });
      setAddress(data);
    };
    if (openDialogEdit) {
      fetchData();
    }
  }, [openDialogEdit, line]);

  const handleUpdateClient = async (values) => {
    setIsLoading(true);

    try {
      const { data } = await registerService.updateClients({
        input_name: values.input_name,
        input_cpf_cnpj: values.input_cpf_cnpj,
        input_email: values.input_email,
        input_IE: values.input_IE,
        input_celular: values.input_celular,
        input_telefone: values.input_telefone,
        input_CEP: values.input_CEP,
        input_rua: values.input_rua,
        input_numero: values.input_numero,
        input_bairro: values.input_bairro,
        input_cidade: values.input_cidade,
        input_estado: values.input_estado,
        input_complemento: values.input_complemento ?? '',
        user: user.id,
        id: line.id,
      });
      handleCloseDialogEdit();
      toast.success('Cliente atualizado com sucesso!');
      setIsLoading(false);
    } catch (error) {
      console.log(error?.response?.data?.message || error.message);
      toast.error(error?.response?.data?.message || error.message);
      setIsLoading(false);
    }
  };

  const handleCepSearch = async (props) => {
    setIsLoading(true);
    try {
      const data = await registerService.getCepInfo(props.values.input_CEP);
      if (data) {
        props.setFieldValue('input_rua', data.logradouro || '');
        props.setFieldValue('input_bairro', data.bairro || '');
        props.setFieldValue('input_cidade', data.localidade || '');
        props.setFieldValue('input_estado', data.uf || '');
      }
    } catch (error) {
      toast.error('Erro ao buscar CEP:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Dialog maxWidth='lg' open={openDialogEdit}>
        <Dialog.Title onClose={handleCloseDialogEdit}>Atualizar</Dialog.Title>
        <Dialog.Content>
          <Formik
            enableReinitialize
            initialValues={{
              input_name: line.name,
              input_cpf_cnpj: line.cgc,
              input_email: line.email,
              input_IE: line.ie,
              input_celular: line.phone_number,
              input_telefone: line.residential_phone_number,
              input_CEP: address.length > 0 ? address[0].zipcode : '',
              input_rua: address.length > 0 ? address[0].street : '',
              input_numero: address.length > 0 ? address[0].street_number : '',
              input_bairro: address.length > 0 ? address[0].district : '',
              input_cidade: address.length > 0 ? address[0].city : '',
              input_estado: address.length > 0 ? address[0].state : '',
              input_complemento: address.length > 0 ? address[0].complement : '',
            }}
            onSubmit={handleUpdateClient}
            validationSchema={validationSchema}
          >
            {(props) => (
              <Form>
                <Box component={Paper} boxShadow={3} p={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={8}>
                      <Field
                        size='small'
                        loading={isLoading}
                        name='input_name'
                        variant='outlined'
                        fullWidth
                        label='Nome'
                        component={Text}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Field
                        size='small'
                        loading={isLoading}
                        name='input_cpf_cnpj'
                        variant='outlined'
                        fullWidth
                        label='CPF/CNPJ'
                        component={Text}
                        disabled
                        mask={'document'}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Field
                        size='small'
                        loading={isLoading}
                        name='input_email'
                        variant='outlined'
                        fullWidth
                        label='Email'
                        component={Text}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Field
                        size='small'
                        loading={isLoading}
                        name='input_IE'
                        variant='outlined'
                        fullWidth
                        label='IE'
                        component={Text}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Field
                        size='small'
                        loading={isLoading}
                        name='input_celular'
                        variant='outlined'
                        fullWidth
                        label='Celular'
                        component={Text}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Field
                        size='small'
                        loading={isLoading}
                        name='input_telefone'
                        variant='outlined'
                        fullWidth
                        label='Telefone'
                        component={Text}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Field
                        size='small'
                        loading={isLoading}
                        name='input_CEP'
                        variant='outlined'
                        fullWidth
                        label='CEP'
                        component={Text}
                        onBlur={() => handleCepSearch(props)}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Field
                        size='small'
                        loading={isLoading}
                        name='input_rua'
                        variant='outlined'
                        fullWidth
                        label='Rua'
                        component={Text}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Field
                        size='small'
                        loading={isLoading}
                        name='input_numero'
                        variant='outlined'
                        fullWidth
                        label='Número'
                        component={Text}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Field
                        size='small'
                        loading={isLoading}
                        name='input_bairro'
                        variant='outlined'
                        fullWidth
                        label='Bairro'
                        component={Text}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Field
                        size='small'
                        loading={isLoading}
                        name='input_cidade'
                        variant='outlined'
                        fullWidth
                        label='Cidade'
                        component={Text}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Field
                        size='small'
                        loading={isLoading}
                        name='input_estado'
                        variant='outlined'
                        fullWidth
                        label='Estado'
                        component={Text}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Field
                        size='small'
                        loading={isLoading}
                        name='input_complemento'
                        variant='outlined'
                        fullWidth
                        label='Complemento'
                        component={Text}
                      />
                    </Grid>
                  </Grid>
                  <Box mt={2}>
                    <Grid item xs={3} md={3} lg={3}>
                      <Button type='submit' fullWidth loading={isLoading}>
                        Atualizar
                      </Button>
                    </Grid>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
