import * as yup from 'yup';

const validationSchema = yup.object().shape({
  zip_code: yup.string().required('O Cep é obrigatório.'),
  street: yup.string().required('A Rua é obrigatória.'),
  street_number: yup.string().required('O Número é obrigatóro.'),
  neighboord: yup.string().required('O Bairro é obrigatório.'),
  city: yup.string().required('A Cidade é obrigatória.'),
  state: yup.string().required('O Estado é obrigatóro.'),
});
export default validationSchema;
