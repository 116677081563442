import { useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Rating from '@mui/material/Rating';
import { styled } from '@mui/material/styles';
import { Button, Text } from 'components';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { inHomeService } from 'services';
import * as Yup from 'yup';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75',
    transition: '0.3s',
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47',
    transform: 'scale(1.2)',
  },
});

const validationSchema = Yup.object().shape({
  rating: Yup.number()
    .required('Por favor, avalie o atendimento!')
    .min(1, 'A avaliação mínima é 1.'),
});

export const SatisfactionSearch = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [module, setModule] = useState(0);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await inHomeService.satisfactionSearch({
        rating: values.rating,
        name: values.name,
        obs: values.obs,
      });
      toast.success('Avaliação realizada com sucesso!');
      setModule(1); // Muda para módulo 1
      setIsLoading(false);
    } catch (error) {
      console.error(error?.response?.data?.message || error?.message);
      toast.error(error?.response?.data?.message || 'Erro ao enviar resposta');
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {module === 0 ? (
        <Box display='flex' alignItems='center' flexDirection='column' mt='3rem'>
          <Typography style={{ fontSize: '35px', fontWeight: 'bold' }} color='primary'>
            Pesquisa de Satisfação
          </Typography>

          <Box
            mt='3rem'
            boxShadow={5}
            borderRadius='10px'
            p={6}
            width='50vw'
            bgcolor='#f5f5f5'
            sx={{
              transition: '0.3s',
              '&:hover': { boxShadow: 10, transform: 'scale(1.02)' },
            }}
          >
            <Formik
              initialValues={{ name: '', obs: '', rating: 0 }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <Grid container spacing={4} justifyContent='center' alignItems='center'>
                    <Grid item xs={8}>
                      <Field
                        name='name'
                        label='Nome (Não obrigatório)'
                        component={Text}
                        variant='outlined'
                        size='small'
                        style={{
                          width: '100%',
                          borderRadius: '5px',
                          background: '#fff',
                        }}
                        loading={isLoading}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography
                        color='primary'
                        style={{ fontSize: '20px', fontWeight: 'bold' }}
                        align='center'
                      >
                        Qual o seu nível de satisfação com o atendimento In Home?
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Box sx={{ '& > legend': { mt: 2 } }} display='flex' justifyContent='center'>
                        <StyledRating
                          name='rating'
                          value={values.rating || 0}
                          onChange={(event, newValue) => {
                            setFieldValue('rating', newValue);
                          }}
                          precision={1}
                          icon={<FavoriteIcon fontSize='inherit' />}
                          emptyIcon={<FavoriteBorderIcon fontSize='inherit' />}
                          size='large'
                        />
                      </Box>
                      <ErrorMessage
                        name='rating'
                        component='div'
                        style={{ color: 'red', textAlign: 'center', marginTop: '10px' }}
                      />
                    </Grid>

                    <Grid item xs={8}>
                      <Field
                        name='obs'
                        label='Observação'
                        component={Text}
                        variant='outlined'
                        multiline
                        minRows={5}
                        size='small'
                        style={{
                          width: '100%',
                          borderRadius: '5px',
                          background: '#fff',
                        }}
                        loading={isLoading}
                      />
                    </Grid>

                    <Grid item xs={12} display='flex' justifyContent='center'>
                      <Button
                        variant='contained'
                        color='secondary'
                        style={{ padding: '10px 20px', fontSize: '16px' }}
                        type='submit'
                        loading={isLoading}
                      >
                        Enviar Resposta
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      ) : (
        <Box display='flex' alignItems='center' flexDirection='column' mt='3rem'>
          <Typography style={{ fontSize: '35px', fontWeight: 'bold' }} color='primary'>
            Obrigado pela sua Avaliação!
          </Typography>
          <Typography style={{ fontSize: '18px', textAlign: 'center', marginTop: '1rem' }}>
            Sua opinião é muito importante para nós. Agradecemos por dedicar seu tempo para nos
            ajudar a melhorar nosso serviço.
          </Typography>
        </Box>
      )}
    </>
  );
};
