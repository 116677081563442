import { AccessControlRoutes } from './AccessControl';
import { CashierRoutes } from './CashierRoutes';
import { ConferenceRoutes } from './ConferenceRoutes';
import { InHomeRoutes } from './InHomeRoutes';
import { InvoicesRoutes } from './InvoicesRoutes';
import { ReceiptRoutes } from './ReceiptRoutes';
import { RegisterRoutes } from './RegisterRoutes';
import { ReportRoutes } from './ReportRoutes';
import { ShoppingRoutes } from './ShoppingRoutes';
import { StockRoutes } from './StockRoutes';
import { StockSalesRoutes } from './StockSalesRoutes';

export const FavoritesRoutes = () => {
  return (
    <>
      <AccessControlRoutes />
      <CashierRoutes />
      <InvoicesRoutes />
      <ReceiptRoutes />
      <ReportRoutes />
      <ConferenceRoutes />
      <ShoppingRoutes />
      <StockRoutes />
      <StockSalesRoutes />
      <RegisterRoutes />
      <InHomeRoutes />
    </>
  );
};
