import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Button, Select, Table } from 'components';
import { Field, Form, Formik } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { registerService } from 'services/register';

import { DialogDesatv } from '../DialogDesatv';

const columns = [{ label: 'Tipo Produto' }, { label: 'Desativar' }];

export const VincTypeProducts = ({ line }) => {
  const [typeProds, setVincTypeProd] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialogDesatv, setOpenDialogDesatv] = useState(false);
  const [rowData, setRowData] = useState([]);
  const fetchData = async () => {
    const { data: VincTypeProducts } = await registerService.findVincTypeProductsById({
      id: line.id,
    });

    setVincTypeProd(VincTypeProducts);
  };

  const [prodByModel, , ,] = useQuery(
    () => registerService.findTypesProductsByIdModel({ idModel: line.id_model }),
    [],
  );

  const optionsTypeProd = (prodByModel || [])?.map((a) => ({
    value: a.id,
    label: a.descricao,
    ativo: a.ativo,
  }));

  console.log(line);

  const typeProdIds = typeProds.map((item) => item.id_typeproduct);

  const filteredOptionsProd = optionsTypeProd.filter((item) => !typeProdIds.includes(item.value));

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddNewVincProd = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await registerService.addNewVincProd({
        id_parts: line.id,
        id_model: line.id_model,
        id_type_prod: values.type_product,
      });
      toast.success('Vinculado com sucesso!');
      setIsLoading(false);
      await fetchData();
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const handleOpenDialogDesatv = (item) => {
    setOpenDialogDesatv(true);
    setRowData(item);
  };

  const handleCloseDialogDesatv = () => {
    setOpenDialogDesatv(false);
    fetchData();
  };
  return (
    <>
      <DialogDesatv
        handleCloseDialogDesatv={handleCloseDialogDesatv}
        openDialogDesatv={openDialogDesatv}
        rowData={rowData}
      />
      <Formik initialValues={{ type_product: [] }} onSubmit={handleAddNewVincProd}>
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Field
                  size='small'
                  name='type_product'
                  variant='outlined'
                  fullWidth
                  options={filteredOptionsProd.filter((item) => item.ativo)}
                  label='Tipos de Produto'
                  component={Select}
                  multiple
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Button type='submit' fullWidth>
                  Adicionar
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Table name='Table' size='small' headers={columns} disableNumeration>
                  {typeProds
                    .filter((item) => item.active)
                    .map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.tipo_produto}</TableCell>
                        <TableCell>
                          <IconButton color='secondary'>
                            <RemoveCircleOutlineIcon onClick={() => handleOpenDialogDesatv(item)} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </Table>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
