import React, { useEffect, useRef } from 'react';

import { useStyles } from './styles.js'; // Certifique-se de que o arquivo de estilos está correto

export const SignaturePad = ({
  onSaveImage,
  style,
  width = 400, // Tamanho padrão
  height = 600, // Tamanho padrão
  clrBtnStyle,
  saveBtnStyle,
  theme = 'light', // Tema padrão
  strokeColor = '#000', // Cor padrão do traço
}) => {
  const classes = useStyles(); // Acessa os estilos do makeStyles
  const canvasRef = useRef(null);
  const isDrawing = useRef(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');

    if (!context || !canvas) return;

    const adjustCanvasSize = () => {
      canvas.width = canvas.clientWidth;
      canvas.height = canvas.clientHeight;
    };

    adjustCanvasSize();
    window.addEventListener('resize', adjustCanvasSize);

    const getMousePosition = (event) => {
      const rect = canvas.getBoundingClientRect();
      if (event instanceof MouseEvent) {
        return {
          x: (event.clientX - rect.left) * (canvas.width / rect.width),
          y: (event.clientY - rect.top) * (canvas.height / rect.height),
        };
      } else {
        const touch = event.touches[0];
        return {
          x: (touch.clientX - rect.left) * (canvas.width / rect.width),
          y: (touch.clientY - rect.top) * (canvas.height / rect.height),
        };
      }
    };

    const startDrawing = (event) => {
      isDrawing.current = true;
      const pos = getMousePosition(event);
      context.beginPath();
      context.moveTo(pos.x, pos.y);
    };

    const draw = (event) => {
      if (!isDrawing.current) return;
      const pos = getMousePosition(event);
      context.lineTo(pos.x, pos.y);
      context.strokeStyle = strokeColor;
      context.lineWidth = 2;
      context.stroke();
    };

    const stopDrawing = () => {
      if (isDrawing.current) {
        isDrawing.current = false;
        context.closePath();
      }
    };

    canvas.addEventListener('mousedown', startDrawing);
    canvas.addEventListener('mousemove', draw);
    canvas.addEventListener('mouseup', stopDrawing);
    canvas.addEventListener('mouseleave', stopDrawing);
    canvas.addEventListener('touchstart', startDrawing);
    canvas.addEventListener('touchmove', draw);
    canvas.addEventListener('touchend', stopDrawing);

    return () => {
      canvas.removeEventListener('mousedown', startDrawing);
      canvas.removeEventListener('mousemove', draw);
      canvas.removeEventListener('mouseup', stopDrawing);
      canvas.removeEventListener('mouseleave', stopDrawing);
      canvas.removeEventListener('touchstart', startDrawing);
      canvas.removeEventListener('touchmove', draw);
      canvas.removeEventListener('touchend', stopDrawing);
      window.removeEventListener('resize', adjustCanvasSize);
    };
  }, [strokeColor]);

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');
    if (context) {
      context.clearRect(0, 0, canvas.width, canvas.height);
    }
  };

  const saveImage = () => {
    if (canvasRef.current) {
      canvasRef.current.toBlob((blob) => {
        if (blob) {
          onSaveImage(blob);
        }
      }, 'image/png');
      clearCanvas();
    }
  };

  return (
    <div style={{ ...style, overflow: 'hidden' }} className={`${classes.signaturePad} ${theme}`}>
      <canvas
        ref={canvasRef}
        style={{ width: '100%', height: '100%', touchAction: 'none' }} // Ajuste para evitar rolagem
      />
      <div className={classes.signaturePadButtons}></div>
      <div className={classes.signaturePadButtons}>
        <button style={clrBtnStyle} onClick={clearCanvas} className={classes.clearBtn}>
          Limpar
        </button>
        <button style={saveBtnStyle} onClick={saveImage} className={classes.saveBtn}>
          Confirmar
        </button>
      </div>
    </div>
  );
};
