import React, { memo } from 'react';

import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import TableMUI from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

// Estilos personalizados para garantir a rolagem horizontal e responsividade
const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'auto',
    display: 'block',
    whiteSpace: 'nowrap', // Garantir que as células não quebrem linha
  },
  table: {
    minWidth: 400, // Largura mínima para a tabela
  },
  tableRowRoot: {
    backgroundColor: theme.palette.action.hover, // Adiciona listras na tabela
  },
}));

const MobileTableComponent = ({
  headers = [],
  rowsPerPage,
  page,
  actionsLabel = '*',
  children,
  onChangePage,
  onChangeRowsPerPage,
  onActionsClick,
  data = [],
}) => {
  const classes = useStyles();

  // Extrair as células da tabela a partir dos filhos
  const rows = React.useMemo(() => {
    return React.Children.map(children, (child) => {
      const cells = React.Children.toArray(child.props.children).map((cell) => cell.props);
      return { ...child.props, cells };
    });
  }, [children]);

  const rowCount = rows?.length || 0;
  const columnsCount = headers.length + (onActionsClick ? 1 : 0);

  // Paginando os dados
  const pageRows =
    rowsPerPage > 0 ? rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows;

  return (
    <>
      <TableContainer className={classes.root}>
        <TableMUI className={classes.table} stickyHeader>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header.label} align={header.align || 'left'}>
                  {header.label}
                </TableCell>
              ))}
              {onActionsClick && <TableCell align='center'>{actionsLabel}</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {!pageRows.length ? (
              <TableRow>
                <TableCell colSpan={columnsCount} align='center'>
                  Sem dados disponíveis
                </TableCell>
              </TableRow>
            ) : (
              pageRows.map((row, index) => (
                <TableRow key={index} hover>
                  {row.cells.map(({ children, ...rest }, cellIndex) => (
                    <TableCell key={cellIndex} {...rest}>
                      {children}
                    </TableCell>
                  ))}
                  {onActionsClick && (
                    <TableCell align='center'>
                      <IconButton onClick={() => onActionsClick(data[index])} size='small'>
                        <MoreHorizIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
        </TableMUI>
      </TableContainer>
    </>
  );
};

export const HorizontalTable = memo(MobileTableComponent);
