import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { stockService } from 'services';

export const DialogDivergencia = ({
  openDialogDivergencia,
  handleCloseDialogDivergencia,
  itemNF,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  console.log(itemNF);

  const processDivergencia = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await stockService.processMotvDiverg({
        motivo: values.input_motivo,
        id: itemNF.id,
      });
      toast.success('Divergencia salva com sucesso!');
      setIsLoading(false);
      handleCloseDialogDivergencia();
    } catch (error) {
      console.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Dialog open={openDialogDivergencia}>
        <Dialog.Title onClose={handleCloseDialogDivergencia}>Divergência</Dialog.Title>
        <Dialog.Content>
          <Formik initialValues={{ input_motivo: '' }} onSubmit={processDivergencia}>
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    align='center'
                    variant='h2'
                    style={{ fontSize: '24px', fontWeight: 'bold' }}
                  >
                    Informe o motivo da divergência
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='input_motivo'
                    variant='outlined'
                    fullWidth
                    label='Motivo'
                    component={Text}
                    multiline
                    minRows={5}
                  />
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <Button
                    color='secondary'
                    fullWidth
                    onClick={handleCloseDialogDivergencia}
                    loading={isLoading}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <Button fullWidth type='submit' loading={isLoading}>
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
