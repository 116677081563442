/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import { Button, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { PageTitle } from 'pages/Layout/PageTitle';
import { registerService } from 'services/register';
import { useContextSelector } from 'use-context-selector';
import * as Yup from 'yup';

import { ModalDesactiveActive } from './Modals/ModalDesactiveActive';
import { DialogEdit } from './Modals/ModalEdits';

const validationSchema = Yup.object().shape({
  input_name: Yup.string().required('O campo Defeito é obrigatório'),
});

const columns = [{ label: 'Categoria' }, { label: 'Alterar' }, { label: 'Ativar/Desativar' }];

export const RegisterPartsCategory = () => {
  const user = useContextSelector(AuthContext, (context) => context.user);

  const [isLoading, setIsLoading] = useState(false);
  const [table, setTable] = useState([]);

  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [openDialogDesactiveActive, setOpenDialogDesactiveActive] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState('');

  const [line, setLine] = useState();

  // get data

  const fetchData = async () => {
    const { data } = await registerService.findTableDefects();
    setTable(data);
  };
  useEffect(() => {
    fetchData();
  }, []);

  // register defect
  const handleRegisterDefect = async (values, { resetForm }) => {
    setIsLoading(true);
    toast.info('Registrando defeito...');

    const defect = values.input_name.trim();
    try {
      const { data } = await registerService.createDefect({
        defect: defect,
        user: user.id,
      });
      toast.success('Defeito registrado com sucesso!');
      setIsLoading(false);
      fetchData();
      resetForm();
    } catch (error) {
      console.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  // Open Dialogs

  const handleOpenDialogEdit = (item) => {
    setLine(item);
    setOpenDialogEdit(true);
  };
  const handleCloseDialogEdit = () => {
    fetchData();
    setOpenDialogEdit(false);
  };

  const handleOpenDialogDesactiveActive = (item) => {
    setLine(item);
    setOpenDialogDesactiveActive(true);
  };
  const handleCloseDialogDesactiveActive = () => {
    fetchData();
    setOpenDialogDesactiveActive(false);
  };

  // table pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const filteredData = table.filter((item) =>
    item.Defect.toLowerCase().includes(search.toLowerCase()),
  );

  return (
    <>
      <DialogEdit
        openDialogEdit={openDialogEdit}
        handleCloseDialogEdit={handleCloseDialogEdit}
        line={line}
      />
      <ModalDesactiveActive
        openDialogDesactiveActive={openDialogDesactiveActive}
        handleCloseDialogDesactiveActive={handleCloseDialogDesactiveActive}
        line={line}
      />
      <PageTitle>Cadastro de Defeitos</PageTitle>
      <Formik
        initialValues={{ input_name: '' }}
        onSubmit={handleRegisterDefect}
        validationSchema={validationSchema}
      >
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={5}>
              <Field
                size='small'
                loading={isLoading}
                name='input_name'
                variant='outlined'
                fullWidth
                label='Defeito'
                component={Text}
              />
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
              <Button type='submit' fullWidth loading={isLoading}>
                Cadastrar
              </Button>
            </Grid>
          </Grid>
          <Box mt={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Field
                  size='small'
                  loading={isLoading}
                  name='search'
                  variant='outlined'
                  fullWidth
                  label='Pesquisar'
                  component={Text}
                  value={search}
                  onChange={handleSearchChange}
                />
              </Grid>
              <Table name='Table' headers={columns} maxHeight disableNumeration>
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.Defect}</TableCell>
                      <TableCell onClick={() => handleOpenDialogEdit(item)}>
                        <EditIcon style={{ color: '#512da8', cursor: 'pointer' }} />
                      </TableCell>
                      <TableCell>
                        <Grid container>
                          <Grid item xs={12} md={3}>
                            {(item.active === true && (
                              <Button
                                onClick={() => handleOpenDialogDesactiveActive(item)}
                                fullWidth
                                color='secondary'
                              >
                                Desativar
                              </Button>
                            )) || (
                                <Button
                                  onClick={() => handleOpenDialogDesactiveActive(item)}
                                  fullWidth
                                >
                                  Ativar
                                </Button>
                              )}
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
              </Table>
            </Grid>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component='div'
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Form>
      </Formik>
    </>
  );
};
