import { useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog, Mask, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { inHomeService } from 'services';

export const DialogFinishService = ({ open, onClose, line }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleFinishAll = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await inHomeService.finishServiceSac({
        service_id: line.service_id,
        final_obs: values.final_obs,
      });
      toast.success();
      setIsLoading(false);
      onClose();
      console.log(values);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      toast.error(error?.response?.data?.message || 'Erro ao finalizar atendimento');
    }
  };
  return (
    <>
      <Dialog maxWidth={'md'} open={open}>
        <Dialog.Title onClose={onClose}>Finalizar atendimento</Dialog.Title>
        <Dialog.Content>
          <Box boxShadow={2} p={2}>
            <Typography align='center' variant='h6' gutterBottom>
              Protocolo {line?.protocol}
            </Typography>
            <Formik initialValues={{ final_obs: '' }} onSubmit={handleFinishAll}>
              {(props) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography align='center' variant='body1' gutterBottom>
                        <strong>Cliente:</strong> {line?.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align='center' variant='body1' gutterBottom>
                        <strong>Telefone:</strong>{' '}
                        <Mask type='phone'>{line?.client_cellphone}</Mask>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name='final_obs'
                        label='Considerações Finais'
                        variant='outlined'
                        multiline
                        minRows={5}
                        component={Text}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button loading={isLoading} type='submit' fullWidth>
                        Finzalizar
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
