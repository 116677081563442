import { api } from 'services/api';

class GoogleService {
  async getDirections(data) {
    return api.post(`/api-google/getDirections`, data);
  }

  async getDistances(data) {
    const response = api.post(`/api-google/getDistances`, data);
    return response;
  }

  async getDirectionWithWayPoints(data) {
    const response = api.post(`/api-google/getDirectionWithWayPoints`, data);
    return response;
  }
}

export const googleService = new GoogleService();
