import { useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import SearchIcon from '@mui/icons-material/Search';
import { TextField, Typography } from '@mui/material';
import { Button, CustomAutocomplete, Dialog } from 'components';
import { Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useQuery } from 'hooks/useQuery';
import { inHomeService } from 'services';
import { useContextSelector } from 'use-context-selector';

export const DialogAlterService = ({ open, onClose, selectedEvent, onSave }) => {
  if (!open) return null;

  const user = useContextSelector(AuthContext, (context) => context.user);

  const [infoAdc, setInfoAdc] = useState([]);
  const [tecs, , ,] = useQuery(() => inHomeService.findInHomeTec(), []);

  const optionsTecs = (tecs || [])?.map((a) => ({
    value: a.user_id.id,
    label: a.user_id.name,
    ativo: a.active,
  }));

  const filterActiveTecs = optionsTecs.filter((item) => item.ativo);

  const handleGetInfoAdcTec = (values) => {
    const infoAdc = tecs.filter((item) => item.user_id.id === values.input_tec.value);
    setInfoAdc(infoAdc);
  };

  const getCurrentDateTime = (date) => {
    const d = date ? new Date(date) : new Date();
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    const hours = String(d.getHours()).padStart(2, '0');
    const minutes = String(d.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const initialTec = filterActiveTecs.find((item) => {
    return item.value === selectedEvent.extendedProps.technician;
  });

  const handleUpdateService = async (values) => {
    try {
      const { data } = await inHomeService.updateService({
        newDate: values.input_data,
        nameTec: values?.input_tec?.label || '',
        idTec: values?.input_tec?.value || '',
        user_alter: user.id,
        service_id: selectedEvent.id,
        protocol: selectedEvent.title,
      });
      toast.success('Atendimento atualizado com sucesso');
      onClose();
      onSave();
    } catch (error) {
      console.error(error?.response?.data?.message || error.message);
      toast.error(error?.response?.data?.message || 'Erro ao atualizar atendimento');
    }
  };

  return (
    <>
      <Dialog maxWidth={'md'} open={open}>
        <Dialog.Title onClose={onClose}>Editar Atendimento</Dialog.Title>
        <Dialog.Content>
          <Formik
            initialValues={{
              input_data: getCurrentDateTime(selectedEvent?.start),
              input_tec: {
                value: initialTec?.value,
                label: initialTec?.label,
              },
            }}
            enableReinitialize
            onSubmit={handleUpdateService}
          >
            {({ values, setFieldValue, isValid, dirty }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant='h6' gutterBottom>
                      Selecione a Data e Hora
                    </Typography>
                    <TextField
                      size='small'
                      label='Data e Hora'
                      name='input_data'
                      type='datetime-local'
                      fullWidth
                      value={values.input_data}
                      onChange={(e) => setFieldValue('input_data', e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        inputProps: {
                          min: getCurrentDateTime(),
                        },
                      }}
                      variant='outlined'
                    />
                  </Grid>
                  {selectedEvent.extendedProps.technician && (
                    <>
                      <Grid item xs={11}>
                        <CustomAutocomplete
                          label='Técnico'
                          name='input_tec'
                          filteredValues={filterActiveTecs.filter((item) => item.label) || []}
                          textFieldProps={{
                            fullWidth: true,
                            variant: 'outlined',
                          }}
                          value={values.input_tec ? values.input_tec.label : ''}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton>
                          <SearchIcon
                            onClick={() => handleGetInfoAdcTec(values)}
                            style={{ color: '#512da8' }}
                          />
                        </IconButton>
                      </Grid>
                    </>
                  )}
                  {infoAdc.length > 0 && (
                    <>
                      <Grid item xs={12}>
                        <Card>
                          <CardHeader title='Informações do Técnico' />
                          <Divider />
                          <CardContent>
                            <Box display={'flex'} flexDirection={'row'}>
                              <Grid container spacing={2}>
                                <Grid item xs={2}>
                                  <Typography variant='body1'>
                                    <strong>Jornada: {infoAdc[0].journey}</strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography variant='body1'>
                                    <strong>Pode fazer extra? {infoAdc[0].extra}</strong>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={6}>
                    <Button onClick={onClose} color='secondary' fullWidth>
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button disabled={!dirty} fullWidth type='submit'>
                      Confirmar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
