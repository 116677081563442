import { useState } from 'react';

import Grid from '@material-ui/core/Grid';

import { Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';

import { ConsultNfEntryPart } from './Actions/Consult';
import { RegisterNfEntryPart } from './Actions/Register';

export const RegisterNfEntryParts = () => {
  const typeActions = [
    { label: 'Cadastrar', value: 0 },
    { label: 'Consultar', value: 1 },
  ];

  const [view, setView] = useState(0);

  const handleChangeView = (newView) => {
    setView(newView);
  };
  return (
    <>
      <PageTitle>Nota de Entrada de Peças</PageTitle>

      <Formik initialValues={{ type_action: 0 }}>
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <Field
                  size='small'
                  name='type_action'
                  variant='outlined'
                  fullWidth
                  options={typeActions}
                  label='Tipo de Ação'
                  component={Select}
                  onChange={(e) => handleChangeView(e)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {view === 0 && (
                  <>
                    <RegisterNfEntryPart />
                  </>
                )}
                {view === 1 && (
                  <>
                    <ConsultNfEntryPart />
                  </>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
