import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { inHomeService } from 'services';
import { useContextSelector } from 'use-context-selector';

export const DialogCancelService = ({ open, onClose, selectedEvent, onSave }) => {
  if (!open) return null;
  const user = useContextSelector(AuthContext, (context) => context.user);

  const splitProtocol = selectedEvent.title.split(' ');
  const protocol = splitProtocol[1];

  const handleCancelService = async (values) => {
    try {
      const { data } = await inHomeService.desactiveService({
        id: selectedEvent.id,
        user: user.id,
        previously_id: selectedEvent?.extendedProps?.status_id || selectedEvent.status_id,
        protocol: protocol,
        reason_cancel: values.obs,
      });
      toast.success('Atendimento cancelado com sucesso!');
      onClose();
      onSave();
    } catch (error) {
      console.error(error?.response?.data?.message || error?.message);
      toast.error(error?.response?.data?.message || 'Erro ao cancelar atendimento');
    }
  };
  return (
    <>
      <Dialog open={open}>
        <Dialog.Title onClose={onClose}>Cancelar atendimento</Dialog.Title>
        <Dialog.Content>
          <Formik initialValues={{ obs: '' }} onSubmit={handleCancelService}>
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography style={{ fontSize: '25px', fontWeight: 'bold' }} align='center'>
                      Confirmar cancelamento?
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name='obs'
                      label='Motivo'
                      component={Text}
                      variant='outlined'
                      type='text'
                      fullWidth
                      size='small'
                      multiline
                      minRows={5}
                    />
                  </Grid>
                </Grid>
                <Box mt={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Button onClick={onClose} fullWidth color='secondary'>
                        Cancelar
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button type='submit' disabled={(!props.isValid, !props.dirty)} fullWidth>
                        Confirmar
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
