import { api } from 'services/api';

export class NewConference {
  async findClient() {
    return api.get('/conference/findClient');
  }

  async findOperations() {
    return api.get('/conference/findOperations');
  }

  async findServices() {
    return api.get('/conference/findServices');
  }

  async findCarrier() {
    return api.get('/conference/findCarrier');
  }

  async registerLote(data) {
    return api.post('/conference/registerLote', data);
  }

  async getLots(data) {
    return api.post('/conference/getLots', data);
  }

  async registerNF(data) {
    return api.post('/conference/registerNF', data);
  }

  async getNFs(data) {
    return api.post('/conference/getNFs', data);
  }

  async editNF(data) {
    return api.put('/conference/editNF', data);
  }

  async deleteNF(data) {
    return api.put('/conference/deleteNF', data);
  }

  async insertItensNF(data) {
    return api.post('/conference/insertItensNF', data);
  }

  async listProduct() {
    return api.get('/conference/listProduct');
  }

  async getProdIterno(data) {
    return api.post('/conference/getProdIterno', data);
  }

  async getItensNF(data) {
    return api.post('/conference/getItensNF', data);
  }

  async editItemNF(data) {
    return api.put('/conference/editItemNF', data);
  }

  async deleteItemNF(data) {
    return api.post('/conference/deleteItemNF', data);
  }

  async getNFsLot(data) {
    return api.post('/conference/getNFsLot', data);
  }

  async registerXML(data) {
    return api.post('/conference/registerXML', data);
  }

  async getProductsLot(data) {
    return api.post('/conference/getProductsLot', data);
  }

  async openOS(data) {
    return api.post('/conference/openOS', data);
  }
}

export const newConferenceService = new NewConference();
