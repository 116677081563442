import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import { Button, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { PageTitle } from 'pages/Layout/PageTitle';
import { registerService } from 'services/register';
import { useContextSelector } from 'use-context-selector';
import * as Yup from 'yup';

import { DialogConfirm } from './Dialog/DialogConfirm';
import { DialogEdit } from './Dialog/DialogEdit';

const columns = [{ label: 'Modelo' }, { label: 'Alterar' }, { label: 'Excluir' }];

const validationSchema = Yup.object().shape({
  input_model: Yup.string().required('Preencha o campo modelo'),
});

export const RegisterModel = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [table, setTable] = useState([]);
  const [line, setLine] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const user = useContextSelector(AuthContext, (context) => context.user);
  const userName = user.id;

  const handleOpenDialogConfirm = (item) => {
    setOpenDialogConfirm(true);
    setLine(item);
  };

  const handleCloseDialogConfirm = () => {
    setOpenDialogConfirm(false);
    fetchData();
  };

  const handleOpenDialogEdit = (item) => {
    setOpenDialogEdit(true);
    setLine(item);
  };

  const handleCloseDialogEdit = () => {
    setOpenDialogEdit(false);
    fetchData();
  };

  const processModel = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await registerService.createModel({
        nome: values.input_model,
        user: userName,
      });
      fetchData();
      toast.success('Cadastrado com sucesso!');
    } catch (error) {
      console.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    const { data } = await registerService.findModels();
    setTable(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const filteredData = table.filter((item) =>
    item.descricao.toLowerCase().includes(search.toLowerCase()),
  );

  return (
    <>
      <DialogConfirm
        handleCloseDialogConfirm={handleCloseDialogConfirm}
        openDialogConfirm={openDialogConfirm}
        line={line}
      />
      <DialogEdit
        handleCloseDialogEdit={handleCloseDialogEdit}
        openDialogEdit={openDialogEdit}
        line={line}
      />
      <PageTitle>Cadastro de Modelo</PageTitle>
      <Formik
        initialValues={{ input_model: '', search: '' }}
        onSubmit={processModel}
        validationSchema={validationSchema}
      >
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={5}>
              <Field
                size='small'
                loading={isLoading}
                name='input_model'
                variant='outlined'
                fullWidth
                label='Modelo'
                component={Text}
              />
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
              <Button fullWidth type='submit' loading={isLoading}>
                Cadastrar
              </Button>
            </Grid>
          </Grid>
          <Box mt={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Field
                  size='small'
                  loading={isLoading}
                  name='search'
                  variant='outlined'
                  fullWidth
                  label='Pesquisar'
                  component={Text}
                  value={search}
                  onChange={handleSearchChange}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <Table name='Table' headers={columns} maxHeight disableNumeration>
                  {filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.descricao}</TableCell>
                        <TableCell>
                          <EditIcon
                            style={{ color: '#512da8', cursor: 'pointer' }}
                            fullWidth
                            onClick={() => handleOpenDialogEdit(item)}
                          ></EditIcon>
                        </TableCell>
                        <TableCell>
                          {(item.ativo === true && (
                            <Grid container>
                              <Grid item xs={12} md={3}>
                                <Button
                                  color='secondary'
                                  fullWidth
                                  onClick={() => handleOpenDialogConfirm(item)}
                                >
                                  Desativar
                                </Button>
                              </Grid>
                            </Grid>
                          )) ||
                            (item.ativo === false && (
                              <Grid container>
                                <Grid item xs={12} md={3}>
                                  <Button fullWidth onClick={() => handleOpenDialogConfirm(item)}>
                                    Ativar
                                  </Button>
                                </Grid>
                              </Grid>
                            ))}
                        </TableCell>
                      </TableRow>
                    ))}
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component='div'
                  count={filteredData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          </Box>
        </Form>
      </Formik>
    </>
  );
};
