import Grid from '@material-ui/core/Grid';
import { Button, Dialog, Text } from 'components';
import { Field, Form, Formik } from 'formik';

import { registerService } from 'services/register';
import validationSchema from './validationSchema';

export const DialogEditAddress = ({ open, onClose, newData }) => {
  const handleGetCep = async (props) => {
    const data = await registerService.getCepInfo(props.values.zip_code);
    props.setFieldValue('street', data.logradouro);
    props.setFieldValue('neighborhood', data.bairro);
    props.setFieldValue('state', data.estado);
    props.setFieldValue('city', data.localidade);
    props.setFieldValue('street_number', '');
  };

  const handleEditAddress = async (values) => {
    console.log('iae');
    onClose(values);
  };
  console.log(newData);
  return (
    <>
      <Dialog maxWidth={'md'} open={open}>
        <Dialog.Title>Editar Endereço de Atendimento</Dialog.Title>
        <Dialog.Content>
          <Formik
            initialValues={{
              zip_code: newData?.[0]?.zip_code,
              street: newData?.[0]?.address,
              street_number: newData?.[0]?.address_number,
              neighborhood: newData?.[0]?.neighborhood,
              state: newData?.[0]?.state,
              city: newData?.[0]?.city,
            }}
            validationSchema={validationSchema}
            onSubmit={handleEditAddress}
          >
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Field
                      name='zip_code'
                      label='Cep'
                      component={Text}
                      variant='outlined'
                      size='small'
                      onBlur={() => handleGetCep(props)}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Field
                      name='street'
                      label='Rua'
                      component={Text}
                      variant='outlined'
                      size='small'
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Field
                      name='street_number'
                      label='Número'
                      component={Text}
                      variant='outlined'
                      size='small'
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Field
                      name='neighborhood'
                      label='Bairro'
                      component={Text}
                      variant='outlined'
                      size='small'
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Field
                      name='city'
                      label='Cidade'
                      component={Text}
                      variant='outlined'
                      size='small'
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Field
                      name='state'
                      label='Estado'
                      component={Text}
                      variant='outlined'
                      size='small'
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Button onClick={() => handleEditAddress(props.values)} fullWidth>
                      Confirmar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
