import { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Button, Mask, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { inHomeService } from 'services';

import { DialogConfirmDesactive } from './Dialogs/DialogConfirmDesactive';
import { DialogEditTechnician } from './Dialogs/DialogEdit';
import { DialogNewTechnician } from './Dialogs/DialogNewTechnician';

const columns = [
  { label: 'Técnico' },
  { label: 'Celular' },
  { label: 'Jornada' },
  { label: 'Pode fazer extra?' },
  { label: 'Atualizar' },
  { label: 'Desativar' },
];

export const RegisterTechnician = () => {
  const [openDialogNewTechnician, setOpenDialogNewTechnician] = useState(false);
  const [openDialogEditTechnician, setOpenDialogEditTechnician] = useState(false);
  const [openDialogConfirmDesactive, setOpenDialogConfirmDesactive] = useState(false);
  const [line, setLine] = useState();
  const [technicians, setTechnicians] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const HandleOpenDialogNewTechincian = () => {
    setOpenDialogNewTechnician(true);
  };
  const HandleCloseDialogNewTechincian = () => {
    setOpenDialogNewTechnician(false);
    fetchData();
  };

  const handleOpenDialogEditTechnician = (item) => {
    setLine(item);
    setOpenDialogEditTechnician(true);
  };
  const handleCloseDialogEditTechnician = () => {
    setOpenDialogEditTechnician(false);
    fetchData();
  };

  const handleOpenDialogConfirmDesactive = (item) => {
    setLine(item);
    setOpenDialogConfirmDesactive(true);
  };
  const handleCloseDialogConfirmDesactive = () => {
    setOpenDialogConfirmDesactive(false);
    fetchData();
  };

  const fetchData = async () => {
    const { data } = await inHomeService.findInHomeTec();
    setTechnicians(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const activesTechnicians = technicians.filter((item) => item.active);

  return (
    <>
      <DialogNewTechnician
        openDialogNewTechnician={openDialogNewTechnician}
        HandleCloseDialogNewTechincian={HandleCloseDialogNewTechincian}
      />
      <DialogEditTechnician
        openDialogEditTechnician={openDialogEditTechnician}
        handleCloseDialogEditTechnician={handleCloseDialogEditTechnician}
        line={line}
      />
      <DialogConfirmDesactive
        openDialogConfirmDesactive={openDialogConfirmDesactive}
        handleCloseDialogConfirmDesactive={handleCloseDialogConfirmDesactive}
        line={line}
      />
      <PageTitle>Cadastro de Técnico In Home</PageTitle>

      <Formik initialValues={{ search: '' }}>
        {(props) => {
          const filteredTechnicians = activesTechnicians.filter((item) =>
            item.user_id.name.toLowerCase().includes(props.values.search.toLowerCase()),
          );

          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Button
                    onClick={HandleOpenDialogNewTechincian}
                    startIcon={<AddCircleOutlineIcon />}
                    fullWidth
                  >
                    Novo Técnico
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    size='small'
                    name='search'
                    label='Pesquisar'
                    component={Text}
                    variant='outlined'
                    type='text'
                    onChange={() => setPage(0)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Table name='table' headers={columns} size='small' disableNumeration>
                    {filteredTechnicians
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.user_id.name}</TableCell>
                          <TableCell>
                            <Mask type='phone'>{item.cellphone}</Mask>
                          </TableCell>
                          <TableCell>{item.journey} Horas</TableCell>
                          <TableCell>{item.extra}</TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => handleOpenDialogEditTechnician(item)}
                              style={{ color: '#512da8' }}
                            >
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => handleOpenDialogConfirmDesactive(item)}
                              style={{ color: '#f50057' }}
                            >
                              <RemoveCircleIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </Table>
                  <TablePagination
                    component='div'
                    count={filteredTechnicians.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[5, 10, 25]}
                    labelRowsPerPage='Técnicos por página:'
                  />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
