import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, Dialog, Select, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useQuery } from 'hooks/useQuery';
import { registerService } from 'services/register';
import { useContextSelector } from 'use-context-selector';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  partnumber: yup.string().required('Partnumber é obrigatório'),
  partnumber_description: yup.string().required('Descrição é obrigatória'),
  ncm: yup.string().required('NCM é obrigatório'),
  valor_ref: yup
    .number()
    .typeError('Valor Ref deve ser numérico')
    .required('Valor Ref é obrigatório'),
  model: yup.string().required('Modelo é obrigatório'),
  type_product: yup.array().min(1, 'Selecione pelo menos um Tipo de Produto'),
  defect: yup.array().min(1, 'Selecione pelo menos um Defeito'),
  sub_defect: yup.array().min(1, 'Selecione pelo menos um Sub Defeito'),
  parts_nivel: yup.string().required('Nivel da Peça é obrigatório'),
});

export const DialogRegisterParts = ({ openDialogRegister, handleCloseDialogRegister }) => {
  if (!openDialogRegister) return null;

  const user = useContextSelector(AuthContext, (context) => context.user);

  const [filterTypeProducts, setFilterTypeProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const partsNivel = [
    { label: 1, value: 0 },
    { label: 2, value: 1 },
    { label: 3, value: 2 },
  ];

  // Get Data
  const [defect, , ,] = useQuery(() => registerService.findTableDefects(), []);

  const optionDefect = (defect || [])?.map((a) => ({
    value: a.id,
    label: a.Defect,
    ativo: a.active,
  }));

  const [subDefect, , ,] = useQuery(() => registerService.findSubDefect(), []);

  const optionSubDefect = (subDefect || [])?.map((a) => ({
    value: a.id,
    label: a.subDefect,
    ativo: a.ativo,
  }));

  const [model, , ,] = useQuery(() => registerService.findModels(), []);

  const optionModel = (model || [])?.map((a) => ({
    value: a.id,
    label: a.descricao,
    ativo: a.ativo,
  }));

  const [typeProduct, , ,] = useQuery(() => registerService.findProducts(), []);

  const optiontypeProduct = (typeProduct || [])?.map((a) => ({
    value: a.id,
    label: a.descricao,
    ativo: a.ativo,
    idModel: a.idModel,
  }));

  const handleRegisterParts = async (values) => {
    toast.info('Registrando peça...');
    setIsLoading(true);
    try {
      const { data } = await registerService.createParts({
        partnumber: values.partnumber,
        partnumber_description: values.partnumber_description,
        ncm: values.ncm,
        valor_ref: values.valor_ref,
        type_product: values.type_product,
        model: values.model,
        defect: values.defect,
        sub_defect: values.sub_defect,
        parts_nivel: values.parts_nivel,
        user: user.id,
      });
      toast.success('Peça registrada com sucesso!');
      handleCloseDialogRegister();
      setIsLoading(false);
    } catch (error) {
      console.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const handleChangeModel = async (props) => {
    const filterTypeProducts = optiontypeProduct.filter(
      (item) => item?.idModel?.id === props.values.model,
    );

    props.setFieldValue('type_product', []);
    setFilterTypeProducts(filterTypeProducts);
  };

  return (
    <>
      <Dialog maxWidth={'md'} open={openDialogRegister}>
        <Dialog.Title onClose={handleCloseDialogRegister}>Cadastro de Peças</Dialog.Title>
        <Dialog.Content>
          <Formik
            initialValues={{
              partnumber: '',
              partnumber_description: '',
              ncm: '',
              valor_ref: '',
              type_product: [],
              model: '',
              defect: [],
              sub_defect: [],
              parts_nivel: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleRegisterParts}
          >
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3} md={3} lg={4}>
                    <Field
                      size='small'
                      name='partnumber'
                      variant='outlined'
                      fullWidth
                      label='Partnumber'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={4}>
                    <Field
                      size='small'
                      name='partnumber_description'
                      variant='outlined'
                      fullWidth
                      label='Descrição Partnumber'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={4}>
                    <Field
                      size='small'
                      name='ncm'
                      variant='outlined'
                      fullWidth
                      label='NCM'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Field
                      size='small'
                      name='valor_ref'
                      variant='outlined'
                      fullWidth
                      label='Valor Ref'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Field
                      size='small'
                      name='model'
                      variant='outlined'
                      fullWidth
                      options={optionModel.filter((a) => a.ativo)}
                      label='Modelo'
                      component={Select}
                      onBlur={() => handleChangeModel(props)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Field
                      size='small'
                      name='type_product'
                      variant='outlined'
                      fullWidth
                      label='Tipo de Produto'
                      component={Select}
                      options={filterTypeProducts.filter((a) => a?.ativo)}
                      multiple
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Field
                      size='small'
                      name='defect'
                      variant='outlined'
                      fullWidth
                      options={optionDefect.filter((a) => a?.ativo)}
                      label='Defeito'
                      component={Select}
                      multiple
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Field
                      size='small'
                      name='sub_defect'
                      variant='outlined'
                      fullWidth
                      options={optionSubDefect.filter((a) => a.ativo)}
                      label='Sub Defeito'
                      component={Select}
                      multiple
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Field
                      size='small'
                      name='parts_nivel'
                      variant='outlined'
                      fullWidth
                      options={partsNivel}
                      label='Nivel da Peça'
                      component={Select}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3}>
                    <Button fullWidth type='submit' loading={isLoading}>
                      Cadastrar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
