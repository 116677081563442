import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, CustomAutocomplete, Dialog, Select, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useQuery } from 'hooks/useQuery';
import { useContextSelector } from 'use-context-selector';
import * as Yup from 'yup';

import { newConferenceService } from '../../../../../services/newConference';

const validationSchema = Yup.object({
  input_cliente: Yup.object().nullable().required('Cliente é obrigatório'),
  input_fornecedor: Yup.object().nullable().required('Fornecedor é obrigatório'),
  input_numPedido: Yup.string().required('Número do Pedido é obrigatório'),
  select_operacao: Yup.string().required('Operação é obrigatória'),
  select_atendimento: Yup.string().required('Tipo de Atendimento é obrigatório'),
  select_transportadora: Yup.object().nullable().required('Transportadora é obrigatória'),
});

export const DialogRegisterLot = ({
  openModalRegisreLot,
  setOpenModalRegisreLot,
  handleCloseDialogRegisterLot,
}) => {
  if (!openModalRegisreLot) {
    return null;
  }
  const user = useContextSelector(AuthContext, (context) => context.user);
  const [isLoading, setIsLoading] = useState(false);
  const [dataCarrying, setDataCarrying] = useState([]);

  // const handleCloseModal = () => {
  //   setOpenModalRegisreLot(false);
  // };

  const listClient = useCallback(async () => {
    try {
      const { data } = await newConferenceService.findClient();
      console.log('client', data);
    } catch (error) {
      console.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    }
  }, []);

  const listOperations = useCallback(async () => {
    try {
      const { data } = await newConferenceService.findOperations();
      console.log('operations', data);
    } catch (error) {
      console.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    }
  }, []);

  const listServices = useCallback(async () => {
    try {
      const { data } = await newConferenceService.findServices();
      console.log('atendimento', data);
    } catch (error) {
      console.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    }
  }, []);

  const listCarrier = useCallback(async () => {
    try {
      const { data } = await newConferenceService.findCarrier();
      console.log('transportadora', data);
    } catch (error) {
      console.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    }
  }, []);

  const [dataClient, , ,] = useQuery(() => newConferenceService.findClient(), []);

  const optionClient = (dataClient || [])?.map((a) => ({
    value: a.id,
    label: a.name,
    ativo: a.active,
  }));

  const [dataOperation, , ,] = useQuery(() => newConferenceService.findOperations(), []);

  const optionOperation = (dataOperation || [])?.map((a) => ({
    value: a.id,
    label: a.description,
    ativo: a.active,
  }));

  const [dataService, , ,] = useQuery(() => newConferenceService.findServices(), []);

  const optionService = (dataService || [])?.map((a) => ({
    value: a.id,
    label: a.description,
    ativo: a.active,
  }));

  const [dataCarrier, , ,] = useQuery(() => newConferenceService.findCarrier(), []);

  const optionCarrier = (dataCarrier || [])?.map((a) => ({
    value: a.id,
    label: a.name,
    ativo: a.active,
  }));

  useEffect(() => {
    if (openModalRegisreLot) {
      listClient();
      listOperations();
      listServices();
      listCarrier();
    }
  }, [listClient, listOperations, listServices, listCarrier, openModalRegisreLot]);

  const registerLote = async (props) => {
    setIsLoading(true);
    try {
      const { data } = await newConferenceService.registerLote({
        cliente: props.values.input_cliente.value,
        clienteOrigem: props.values.input_client_orig.value,
        numPedido: props.values.input_numPedido,
        operation: props.values.select_operacao,
        atendimento: props.values.select_atendimento,
        transportadora: props.values.select_transportadora.value,
        numConhecimneto: props.values.input_numero_conhecimento,
        statusLote: 'Lote Criado',
      });
      toast.success('Lote criado com sucesso!');
      handleCloseDialogRegisterLot();
    } catch (error) {
      toast.error('Erro ao criar lote');
      console.error(error?.response?.data?.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Dialog open={openModalRegisreLot} maxWidth={'lg'}>
        <Dialog.Title onClose={handleCloseDialogRegisterLot}>Novo Lote</Dialog.Title>
        <Dialog.Content>
          <Formik
            initialValues={{
              input_cliente: '',
              input_client_orig: '',
              input_numPedido: '',
              select_operacao: '',
              select_atendimento: '',
              select_transportadora: '',
              input_numero_conhecimento: '',
            }}
            validationSchema={validationSchema}
          >
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid>
                      <CustomAutocomplete
                        name='input_cliente'
                        label='Cliente'
                        value={props.values.input_cliente}
                        filteredValues={optionClient || []}
                        disabled={isLoading}
                        textFieldProps={{
                          fullWidth: true,
                          variant: 'outlined',
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid>
                      <CustomAutocomplete
                        name={'input_client_orig'}
                        label={'Cliente Origem'}
                        value={props.values.input_client_orig}
                        filteredValues={optionClient || []}
                        disabled={isLoading}
                        textFieldProps={{
                          fullWidth: true,
                          variant: 'outlined',
                        }}
                        isOptionEqualToValue={(option, value) => option?.value === value?.value}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      size='small'
                      name='input_numPedido'
                      label='Número do Pedido '
                      variant='outlined'
                      fullWidth
                      disabled={isLoading}
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      size='small'
                      name='select_operacao'
                      label='Operação'
                      variant='outlined'
                      fullWidth
                      options={optionOperation}
                      disabled={isLoading}
                      component={Select}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Field
                      size='small'
                      name='select_atendimento'
                      label='Tipo de Atendimento'
                      variant='outlined'
                      fullWidth
                      options={optionService}
                      disabled={isLoading}
                      component={Select}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomAutocomplete
                      name={'select_transportadora'}
                      label={'Transportadora'}
                      value={props.values.select_transportadora}
                      filteredValues={optionCarrier || []}
                      disabled={isLoading}
                      textFieldProps={{
                        fullWidth: true,
                        variant: 'outlined',
                      }}
                      isOptionEqualToValue={(option, value) => option?.value === value?.value}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Field
                      size='small'
                      name='input_numero_conhecimento'
                      label='N° Conhecimento'
                      variant='outlined'
                      disabled={isLoading}
                      fullWidth
                      component={Text}
                    />
                  </Grid>
                  <Grid container style={{ paddingTop: '2rem' }}>
                    <Grid item xs={12} lg={8}></Grid>
                    <Grid item xs={4} lg={4}>
                      <Button
                        fullWidth
                        onClick={() => registerLote(props)}
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        Criar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
