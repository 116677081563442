import { createContext, useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Button, Dialog, Spacer } from 'components';
import { AuthContext } from 'hooks/useAuth';
import { inHomeService } from 'services';
import { useContextSelector } from 'use-context-selector';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { StepperService } from './components/Stepper';
import { GetNfForm } from './steps/GetNfForm';
import { ObsService } from './steps/ObsService';
import { RegisterProtocol } from './steps/RegisterProtocol';
import { ResumeService } from './steps/ResumeService';

export const NewServiceContext = createContext();

export function NewServiceDialog({ isOpen = false, handleClose }) {
  if (!isOpen) {
    return <></>;
  }

  const user = useContextSelector(AuthContext, (context) => context.user);

  const [backup, setBackup] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [protocolData, setProtocolData] = useState({});

  const updateProtocolData = (newData) => {
    setProtocolData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };
  const getStepContent = useCallback(
    (activeStep) => {
      const steps = {
        0: <RegisterProtocol />,
        1: <GetNfForm />,
        2: <ObsService protocolData={protocolData} />,
        3: <ResumeService protocolData={protocolData} />,
      };
      return steps[activeStep];
    },
    [protocolData],
  );

  const removeLastProtocolData = () => {
    const newProtocolData = { ...protocolData };
    const keys = Object.keys(newProtocolData);
    if (keys.length > 0) {
      const lastKey = keys[keys.length - 1];
      delete newProtocolData[lastKey];
      setProtocolData(newProtocolData);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setBackup(false);
      setActiveStep(activeStep - 1);
      removeLastProtocolData();
    } else {
      toast.info('Não há etapas para voltar');
    }
  };

  const handleRegisterService = async () => {
    try {
      const { data } = await inHomeService.registerService({
        protocol: protocolData.protocol,
        client_cgc: protocolData.dataNf[0].cgc,
        attendant_id: user.id,
        obs: protocolData.obs,
        date_Scheduling: protocolData.date ? protocolData.date : '',
        time: protocolData.time ? protocolData.time : '',
        cellphone: protocolData.dataNf[0].cellphone,
        cidade: protocolData.dataNf[0].city,
        cep: protocolData.dataNf[0].zip_code,
        rua: protocolData.dataNf[0].address,
        numero: protocolData.dataNf[0].address_number,
        complemento: protocolData.dataNf[0].complement,
        bairro: protocolData.dataNf[0].neighborhood,
        estado: protocolData.dataNf[0].state,
        nota: protocolData.dataNf[0].numero_nota_fiscal,
        chave_nota: protocolData.dataNf[0].chave_de_acesso,
        dt_receb: protocolData.dataNf[0].created_at,
        serie: protocolData.dataNf[0].serie,
        ie: protocolData.dataNf[0].ie,
        name: protocolData.dataNf[0].name,
        email: protocolData.dataNf[0].email,
        produto: protocolData.dataNf[0].descricao,
        period: protocolData.period,
        serial: protocolData.dataNf[0].serial,
        backup: backup,
        group_prod: protocolData.dataNf[0].group_prod,
      });
      toast.success('Atendimento registrado com sucesso!');
      handleClose();
      window.location.reload();
    } catch (error) {
      console.log(error?.response?.data.message || error?.message);
      toast.error(error?.response?.data.message || 'Erro ao registrar novo atendimento');
    }
  };

  return (
    <Dialog open={isOpen} maxWidth='lg'>
      <Dialog.Title onClose={handleClose}>Novo atendimento</Dialog.Title>
      <Dialog.Content>
        <StepperService activeStep={activeStep} />

        <Spacer size={64} />
        {activeStep === 3 && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={10}></Grid>
              <Grid item xs={2}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox style={{ color: '#512da8' }} onChange={() => setBackup(!backup)} />
                    }
                    label='Iniciar como backup'
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </>
        )}
        <NewServiceContext.Provider
          value={{ activeStep, protocolData, updateProtocolData, setActiveStep }}
        >
          {getStepContent(activeStep)}
        </NewServiceContext.Provider>
        {activeStep !== 0 && (
          <Box mt={2}>
            {activeStep === 3 ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Button
                    fullWidth
                    onClick={() => handleBack()}
                    color='primary'
                    startIcon={<KeyboardBackspaceIcon />}
                  >
                    Voltar
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Button onClick={handleRegisterService} endIcon={<CheckCircleIcon />} fullWidth>
                    Confirmar
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}></Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    onClick={() => handleBack()}
                    color='primary'
                    startIcon={<KeyboardBackspaceIcon />}
                  >
                    Voltar
                  </Button>
                </Grid>
              </Grid>
            )}
          </Box>
        )}
      </Dialog.Content>
    </Dialog>
  );
}
