import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import { Button, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { registerService } from 'services/register';

import { DialogConfirm } from './Dialog/DialogConfirm';
import { DialogEdit } from './Dialog/DialogEdit';
import { DialogRegister } from './Dialog/DialogRegister';

const columns = [
  { label: 'Nome Fornecedor' },
  { label: 'CNPJ' },
  { label: 'Telefone' },
  { label: 'Alterar' },
  { label: 'Ativar/Desativar' },
];

export const RegisterSupplier = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [openDialogRegister, setOpenDialogRegister] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [table, setTable] = useState([]);
  const [line, setLine] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');

  const handleOpenDialogRegister = () => {
    setOpenDialogRegister(true);
  };

  const handleCloseDialogRegister = () => {
    setOpenDialogRegister(false);
    fetchData();
  };

  const handleOpenDialogEdit = (item) => {
    setOpenDialogEdit(true);
    setLine(item);
  };

  const handleCloseDialogEdit = () => {
    setOpenDialogEdit(false);
    fetchData();
  };

  const handleOpenDialogConfirm = (item) => {
    setOpenDialogConfirm(true);
    setLine(item);
  };

  const handleCloseDialogConfirm = () => {
    setOpenDialogConfirm(false);
    fetchData();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const fetchData = async () => {
    try {
      const { data } = await registerService.findSupplier();
      setTable(data);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filteredData = table.filter(
    (item) =>
      item.name.toLowerCase().includes(search.toLowerCase()) ||
      item.cgc.toLowerCase().includes(search.toLowerCase()),
  );
  return (
    <>
      <DialogRegister
        handleCloseDialogRegister={handleCloseDialogRegister}
        openDialogRegister={openDialogRegister}
      />
      <DialogEdit
        handleCloseDialogEdit={handleCloseDialogEdit}
        openDialogEdit={openDialogEdit}
        line={line}
      />
      <DialogConfirm
        handleCloseDialogConfirm={handleCloseDialogConfirm}
        openDialogConfirm={openDialogConfirm}
        line={line}
      />
      <PageTitle>Cadastro Fornecedores</PageTitle>

      <Formik initialValues={{ search: '' }}>
        <Form>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={3} md={3} lg={3}>
              <Button fullWidth loading={isLoading} onClick={handleOpenDialogRegister}>
                Cadastrar
              </Button>
            </Grid>
            <Grid item xs={12} style={{ marginLeft: 'auto' }}>
              <Field
                variant='outlined'
                name='search'
                label='Pesquisar'
                size='small'
                component={Text}
                fullWidth
                value={search}
                onChange={handleSearchChange}
              />
            </Grid>
          </Grid>
          <Box mt={2}>
            <Table name='Table' headers={columns} maxHeight disableNumeration>
              {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.cgc}</TableCell>
                    <TableCell>{item.phone}</TableCell>
                    <TableCell>
                      <EditIcon
                        style={{ color: '#512da8', cursor: 'pointer' }}
                        onClick={() => handleOpenDialogEdit(item)}
                      />
                    </TableCell>
                    <TableCell>
                      {(item.active === true && (
                        <Grid container>
                          <Grid item xs={12} md={3}>
                            <Button
                              color='secondary'
                              fullWidth
                              onClick={() => handleOpenDialogConfirm(item)}
                            >
                              Desativar
                            </Button>
                          </Grid>
                        </Grid>
                      )) ||
                        (item.active === false && (
                          <Grid container>
                            <Grid item xs={12} md={3}>
                              <Button fullWidth onClick={() => handleOpenDialogConfirm(item)}>
                                Ativar
                              </Button>
                            </Grid>
                          </Grid>
                        ))}
                    </TableCell>
                  </TableRow>
                ))}
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component='div'
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        </Form>
      </Formik>
    </>
  );
};
