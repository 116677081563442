import { api } from 'services/api';

export class StockService {
  async getDataAlternate() {
    return api.get('/xpcell-multimarcas-stock/stock/alternarte');
  }

  async addAlternateData(data) {
    return api.post('/xpcell-multimarcas-stock/stock/add-alternarte', data);
  }

  async entryXML(data) {
    return api.post('/stock/entryXML', data);
  }

  async checkPartnumber(data) {
    return api.post('/stock/checkPartnumber', data);
  }

  async processFinishedNote(data) {
    return api.post('/stock/processFinishedNote', data);
  }

  async getNotas() {
    return api.get('/stock/notas');
  }

  async getItensNF(data) {
    return api.post('/stock/getItensNF', data);
  }

  async processMotvDiverg(data) {
    return api.post('/stock/processMotvDiverg', data);
  }

  async desatvNF(data) {
    return api.post('/stock/desatvNF', data);
  }
}

export const stockService = new StockService();
