import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { registerService } from 'services/register';
import { useContextSelector } from 'use-context-selector';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  input_observation: Yup.string().required('Observação é obrigatória'),
});

export const DialogConfirm = ({ handleCloseDialogConfirm, openDialogConfirm, line }) => {
  const user = useContextSelector(AuthContext, (context) => context.user);
  const [isLoading, setIsLoading] = useState(false);

  const activeOrDesactive = () => {
    if (line && line.ativo === true) {
      return 'desativar';
    } else {
      return 'ativar';
    }
  };
  const handleUpdateActivProducts = async (values) => {
    setIsLoading(true);
    toast.info('Atualizando ativo do produto');
    try {
      const { data } = await registerService.updateProductsActive({
        active: line.ativo,
        id: line.id,
        user: user.id,
        obs: values.input_observation,
      });
      toast.success('Ativo do produto atualizado com sucesso');
      handleCloseDialogConfirm();
      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
      toast.error('Erro ao atualizar ativo do produto');
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Dialog open={openDialogConfirm}>
        <Dialog.Title onClose={handleCloseDialogConfirm}>Confirmação</Dialog.Title>
        <Dialog.Content>
          <Formik
            initialValues={{ input_observation: '' }}
            onSubmit={handleUpdateActivProducts}
            validationSchema={validationSchema}
          >
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      align='center'
                      variant='h2'
                      style={{ fontSize: '24px', fontWeight: 'bold' }}
                    >
                      Deseja realmente {activeOrDesactive()} esse produto ?
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_observation'
                      variant='outlined'
                      fullWidth
                      label='Observação'
                      component={Text}
                      multiline
                      minRows={5}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Button
                      color='secondary'
                      fullWidth
                      onClick={handleCloseDialogConfirm}
                      loading={isLoading}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Button fullWidth type='submit' loading={isLoading}>
                      Atualizar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
