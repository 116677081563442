import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Button, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { newConferenceService } from 'services/newConference';

import { DialogConfirmDeleteItem } from './DialogConfirmDeleteItem';

const columnsItem = [
  { label: 'Item' },
  { label: 'Produto Origem' },
  { label: 'Produto Interno' },
  { label: 'Descrição' },
  { label: 'Qtd' },
  { label: 'Valor Unitário' },
  { label: 'Total Item' },
  { label: 'Editar' },
  { label: 'Excluir' },
];

export const ViewItens = ({ handleBackToNf, handleChangeToRegisterItens, line }) => {
  const [openModalConfirmDeleteItem, setOpenModalConfirmDeleteItem] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');
  const [table, setTable] = useState([]);
  const [lineItem, setLineItem] = useState([]);
  console.log(line);

  const handleOpenModalConfirmDeleteItem = (item) => {
    setOpenModalConfirmDeleteItem(true);
    setLineItem(item);
  };
  const handleCloseModalConfirmDeleteItem = async () => {
    setOpenModalConfirmDeleteItem(false);
    handleItensNF();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  // Função para filtrar os dados da tabela com base no valor de pesquisa
  const filteredData = table.filter((item) => {
    return (
      item.id.toString().includes(searchQuery.toLowerCase()) ||
      item.products.produtoOrigem.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.products.produtoInterno.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.products.descricao.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.qtd_itens.toString().includes(searchQuery) ||
      item.value_unit.toString().includes(searchQuery) ||
      item.amount_total.toString().includes(searchQuery)
    );
  });

  const handleItensNF = useCallback(async () => {
    try {
      const { data } = await newConferenceService.getItensNF({ idNota: line.id });
      setTable(data);
      console.log(data);
      if (data.length <= 0) {
        toast.error('Não há itens nessa nota!');
      }
    } catch (error) {
      toast.error('Erro ao carregar os itens!');
      console.error(error?.response?.data?.message);
    }
  }, [line.id]);

  useEffect(() => {
    handleItensNF();
  }, [handleItensNF]);

  return (
    <>
      <DialogConfirmDeleteItem
        openModalConfirmDeleteItem={openModalConfirmDeleteItem}
        handleCloseModalConfirmDeleteItem={handleCloseModalConfirmDeleteItem}
        lineItem={lineItem}
      />
      <Box mt={2} component={Paper} boxShadow={3} p={2}>
        <Formik initialValues={{ search: '' }}>
          {(props) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <Typography variant='h1' style={{ fontSize: '20px', fontWeight: 'bold' }}>
                    Itens da Nota
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Field
                    size='small'
                    name='search'
                    label='Pesquisar'
                    component={Text}
                    variant='outlined'
                    type='text'
                    fullWidth
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Table name='Table' size='small' headers={columnsItem} disableNumeration>
                    {filteredData
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.id}</TableCell>
                          <TableCell>{item.products.produtoOrigem}</TableCell>
                          <TableCell>{item.products.produtoInterno}</TableCell>
                          <TableCell>{item.products.descricao}</TableCell>
                          <TableCell>{item.qtd_itens}</TableCell>
                          <TableCell>{item.value_unit}</TableCell>
                          <TableCell>{item.amount_total}</TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => handleChangeToRegisterItens(item, 2)}
                              color='primary'
                            >
                              <ModeEditIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <IconButton color='secondary'>
                              <HighlightOffIcon
                                onClick={() => handleOpenModalConfirmDeleteItem(item)}
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component='div'
                    count={table.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}></Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Button onClick={() => handleBackToNf()} fullWidth color='secondary'>
                    Voltar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};
