import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Dialog } from 'components';
import formatter from 'date-fns/format';
import { useCallback, useEffect, useState } from 'react';
import { inHomeService } from 'services';

export const DialogTecsByProd = ({ open, onClose, info }) => {
  if (!open) return null;

  const [tecs, setTecs] = useState([]);
  const [closestTech, setClosestTech] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasTechs, setHasTechs] = useState(false); // Estado para controlar a existência de técnicos

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const { data } = await inHomeService.findVincsProdTecByIdProd({
      prod: info.dataNf[0].group_prod,
    });

    const addressNew =
      `${info?.dataNf?.[0]?.address} ${info?.dataNf?.[0]?.address_number}, ` +
      `${info?.dataNf?.[0]?.city}, ${info?.dataNf?.[0]?.state}`;

    // Obtenha as coordenadas do novo endereço
    const newCoords = await getCoordinates(addressNew);
    let closestDistance = Infinity; // Inicializa a menor distância como Infinity
    let closest = null;

    for (const tech of data) {
      const { data: services } = await inHomeService.findServicesByTec({ id_tec: tech.tec.id });

      if (services.length > 0) {
        setHasTechs(true); // Marca que existem técnicos para a especialidade
      }

      for (const service of services) {
        const serviceAddress = `${service.street} ${service.number}, ${service.city}, ${service.state}`;
        const serviceCoords = await getCoordinates(serviceAddress);

        if (serviceCoords) {
          const distance = haversineDistance(newCoords, serviceCoords);

          // Verifica se a distância atual é menor que a menor distância encontrada
          if (distance < closestDistance) {
            closestDistance = distance; // Atualiza a menor distância
            closest = { tech, distance, service }; // Armazena o técnico mais próximo
          }
        }
      }
    }

    setClosestTech(closest); // Atualiza o estado com o técnico mais próximo
    setTecs(data); // Armazena todos os técnicos para uso futuro, se necessário
    setIsLoading(false);
  }, [info.dataNf]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = formatter(date, 'dd/MM/yyyy HH:mm');
    return `${formattedDate}`;
  };

  return (
    <Dialog open={open} maxWidth={'md'}>
      <Dialog.Title onClose={onClose}>
        Técnico com especialidade em {info.dataNf[0].group_prod}
      </Dialog.Title>
      <Dialog.Content>
        {isLoading && (
          <>
            <Typography variant='h6'>Carregando sugestão de técnico</Typography>
          </>
        )}
        {closestTech ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box border={1} borderRadius={4} p={2} textAlign='center' color='#512da8'>
                <Typography variant='h6'>
                  Técnico com atendimento mais próximo: {closestTech.tech.tec.name}
                </Typography>
                <Typography variant='body1'>
                  Endereço:
                  {' ' +
                    closestTech.service.street +
                    ' ' +
                    closestTech.service.number +
                    ', ' +
                    closestTech.service.city +
                    ' ' +
                    closestTech.service.state}
                </Typography>
                <Typography variant='body1'>
                  Distância: {closestTech.distance.toFixed(2)} km
                </Typography>
                <Typography variant='body1'>
                  Data do atendimento:
                  {' ' + formatDate(closestTech.service.date_Scheduling)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        ) : hasTechs ? (
          <Typography variant='body1' color='textSecondary'>
            Nenhum técnico disponível no momento.
          </Typography>
        ) : (
          <Typography variant='body1' color='textSecondary'>
            Nenhum técnico encontrado para o produto {info.dataNf[0].group_prod}.
          </Typography>
        )}
      </Dialog.Content>
    </Dialog>
  );
};

const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
// Funções auxiliares
const getCoordinates = async (address) => {
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address,
    )}&key=${key}`,
  );
  const data = await response.json();
  if (data.results.length > 0) {
    const location = data.results[0].geometry.location;
    return { lat: location.lat, lng: location.lng };
  }
  return null;
};

const haversineDistance = (coords1, coords2) => {
  const R = 6371; // Raio da Terra em km
  const dLat = (coords2.lat - coords1.lat) * (Math.PI / 180);
  const dLng = (coords2.lng - coords1.lng) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(coords1.lat * (Math.PI / 180)) *
      Math.cos(coords2.lat * (Math.PI / 180)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distância em km
};
