import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Dialog } from 'components';
import { MapRoutes } from 'components/MapRoutes/indes';

export const DialogAddres = ({ open, onClose, line }) => {
  if (!open) return null;

  const containerStyle = {
    width: '100%',
    height: '800px',
  };

  const origin = 'Avenida Liberdade, 4565, Sorocaba';
  const destination =
    line?.street +
    ' ' +
    line?.neighborhood +
    ' ' +
    line?.number +
    ' ' +
    line?.city +
    ' ' +
    line?.state;

  return (
    <>
      <Dialog open={open}>
        <Dialog.Title onClose={onClose}>Endereço</Dialog.Title>
        <Dialog.Content>
          <Box borderRadius={2} p={2} boxShadow={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h4' align='center'>
                  Informações da rota
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                  Local de Partida: {origin}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                  Local de Destino: {destination}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <MapRoutes origin={origin} destination={destination}></MapRoutes>
              </Grid>
            </Grid>
          </Box>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
