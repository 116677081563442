import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog } from 'components';

import { registerService } from '../../../../../services/register';

export const DialogDesatv = ({ openDialogDesatv, handleCloseDialogDesatv, rowData }) => {
  const [isLoading, setIsLoading] = useState(false);

  const processDesatvVinc = async () => {
    setIsLoading(true);
    try {
      const { data } = await registerService.desatvVinc({
        id: rowData.id,
        idDefect: rowData.id_defect,
        idTipoProduto: rowData.id_typeproduct,
        idSubDefect: rowData.id_sub_defect,
      });
      toast.success('Desvinculado com sucesso!');
      setIsLoading(false);
      handleCloseDialogDesatv();
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };
  return (
    <>
      <Dialog open={openDialogDesatv}>
        <Dialog.Title onClose={handleCloseDialogDesatv}>Confirmação</Dialog.Title>
        <Typography align='center' variant='h6' style={{ paddingBottom: '2rem' }}>
          Você deseja realmente desativar o vinculo?
        </Typography>
        <Dialog.Content>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Button fullWidth color='secondary' onClick={handleCloseDialogDesatv}>
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Button fullWidth color='primary' onClick={processDesatvVinc}>
                Desativar
              </Button>
            </Grid>
          </Grid>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
