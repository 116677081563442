export async function formatDate(date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export function formatDateDirect(date) {
  // Verifica se o 'date' é uma string, converte para um objeto Date
  const parsedDate = date instanceof Date ? date : new Date(date);

  // Verifica se a data é inválida
  if (isNaN(parsedDate.getTime())) {
    throw new Error('Data inválida');
  }

  const day = String(parsedDate.getDate()).padStart(2, '0');
  const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
  const year = parsedDate.getFullYear();

  return `${day}/${month}/${year}`;
}
