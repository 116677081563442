import { useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import NoteAddIcon from '@material-ui/icons/NoteAdd';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@mui/icons-material/Search';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import { Button, DatePicker, Select, Table } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { newConferenceService } from 'services/newConference';

import { DialogOpenServiceOrder } from './Dialog/DialogOpenServiceOrder';
import { DialogRegisterLot } from './Dialog/DialogRegisterLot';
import { DialogRegisterNf } from './Dialog/DialogRegisterNf';
import { DialogViewNfLote } from './Dialog/DialogViewNfLote';

const columns = [
  { label: 'Notas Lote' },
  { label: 'Lote' },
  { label: 'Data Criação' },
  { label: 'Status' },
  { label: 'Num Pedido' },
  { label: 'Fornecedor' },
  { label: 'Fornecedor Origem' },
  { label: 'Atendimento' },
  { label: 'Transportadora' },
  { label: 'Objeto' },
  { label: 'Operação' },
  { label: 'Ações' },
];

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const LoteManagement = () => {
  const typeStatus = [
    { label: 'Lote Criado', value: 0 },
    { label: 'Lote em Abertura de OS', value: 1 },
    { label: 'Lote encerrado', value: 2 },
  ];

  const [openModalServiceOrder, setOpenModalServiceOrder] = useState(false);
  const [openDialogViewNfs, setOpenDialogViewNfs] = useState(false);
  const [openModalRegisreLot, setOpenModalRegisreLot] = useState(false);
  const [openModalNF, setOpenModaNF] = useState(false);
  const [linhaInfos, setLinhaInfos] = useState();
  const [table, setDataTable] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const hadleTable = async (props) => {
    const values = {
      status: props?.values?.type_status ? props?.values?.type_status : 0,
      data_inicio: props?.values?.input_data ? props?.values?.input_data : new Date(),
      data_fim: props?.values?.output_data ? props?.values?.output_data : new Date(),
    };
    try {
      const { data } = await newConferenceService.getLots(values);
      setDataTable(data);
      console.log(data);
      if (data.length === 0) {
        toast.error('Não há dados');
      }
    } catch (error) {
      console.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleOpenDialogViewNfs = (item) => {
    setOpenDialogViewNfs(true);
    setLinhaInfos(item);
  };
  const handleCloseDialogViewNfs = () => {
    setOpenDialogViewNfs(false);
  };

  const handleOpenModalNF = async (item) => {
    setOpenModaNF(true);
    setLinhaInfos(item);
  };
  const handleCloseModalNF = () => {
    setOpenModaNF(false);
  };

  const handleOpenDialogOrderService = (item) => {
    setOpenModalServiceOrder(true);
    setLinhaInfos(item);
  };

  const handleCloseDialogOrderService = () => {
    setOpenModalServiceOrder(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  const handleCloseDialogRegisterLot = () => {
    hadleTable();
    setOpenModalRegisreLot(false);
  };

  return (
    <>
      <DialogOpenServiceOrder
        openModalServiceOrder={openModalServiceOrder}
        handleCloseDialogOrderService={handleCloseDialogOrderService}
        linhaInfos={linhaInfos}
      />
      <DialogRegisterNf
        openModalNF={openModalNF}
        handleCloseModalNF={handleCloseModalNF}
        linhaInfos={linhaInfos}
      />
      <DialogViewNfLote
        openDialogViewNfs={openDialogViewNfs}
        handleCloseDialogViewNfs={handleCloseDialogViewNfs}
        linhaInfos={linhaInfos}
      />

      <DialogRegisterLot
        openModalRegisreLot={openModalRegisreLot}
        handleCloseDialogRegisterLot={handleCloseDialogRegisterLot}
      />
      <PageTitle>Gerenciamento de Lote</PageTitle>

      <Formik initialValues={{ type_status: 0, input_data: new Date(), output_data: new Date() }}>
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2} md={2} lg={2}>
                <Field
                  size='small'
                  fullWidth
                  name='type_status'
                  variant='outlined'
                  label='Status'
                  options={typeStatus}
                  component={Select}
                  //onChange={(e) => getDados({ type_status: e })}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Field
                  size='small'
                  name='input_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data Inicio'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Field
                  size='small'
                  name='output_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data Fim'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Button fullWidth startIcon={<SearchIcon />} onClick={() => hadleTable(props)}>
                  Filtrar
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Button
                  onClick={() => {
                    setOpenModalRegisreLot(true);
                  }}
                  fullWidth
                >
                  Novo Lote
                </Button>
              </Grid>
            </Grid>
            <Box mt={2}>
              <Grid container spacing={2}>
                {/* <Grid item xs={12}>
                  <Field
                    size='small'
                    name='search'
                    label='Pesquisar'
                    component={Text}
                    variant='outlined'
                    type='text'
                    fullWidth
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </Grid> */}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Table name='Table' headers={columns} size='small' disableNumeration>
                    {table
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Tooltip title='Visualizar Notas'>
                              <IconButton
                                color='primary'
                                onClick={() => handleOpenDialogViewNfs(item)}
                              >
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          <TableCell>{item.lot_id}</TableCell>
                          <TableCell>{formatDate(item.lot_dtRegister)}</TableCell>
                          <TableCell>{item.lot_statusLote}</TableCell>
                          <TableCell>{item.lot_orderNumber}</TableCell>
                          <TableCell>{item.client_name}</TableCell>
                          <TableCell>{item.client_ori_name}</TableCell>
                          <TableCell>{item.service_description}</TableCell>
                          <TableCell>{item.carrier_name}</TableCell>
                          <TableCell>{item.lot_numberKnowledge}</TableCell>
                          <TableCell>{item.operation_description}</TableCell>
                          <TableCell>
                            <Tooltip title='Abertura de OS'>
                              <IconButton
                                color='secondary'
                                onClick={() => {
                                  handleOpenDialogOrderService(item);
                                }}
                              >
                                <AddCircleIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title='Nota Fiscal'>
                              <IconButton
                                color='secondary'
                                onClick={() => {
                                  handleOpenModalNF(item);
                                }}
                              >
                                <NoteAddIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                  </Table>
                  <TablePagination
                    component='div'
                    count={table.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage='Itens por página'
                  />
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
